import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import MainHeader, { MainHeaderLink } from '../types/main-header'
import { NavContext, TranslationContext } from '@superrb/gatsby-addons/context'
import { linkResolver } from '../utils/linkResolver'
import { MenuToggle } from '@superrb/gatsby-addons/components'
import { SiteConfig } from '@superrb/gatsby-addons-prismic/types'
import ButtonRounded from './buttons/button-rounded'
import Twitter from './icons/twitter'
import Instagram from './icons/instagram'
import Linkedin from './icons/linkedin'
import Spotify from './icons/spotify'
import {HeaderVariantContext} from "../context/header-variant-context-provider";
import { LiveNodeList } from 'live-node-list'
import {useIsMobile} from "@superrb/gatsby-addons/hooks";
import ButtonArrow from './icons/button-arrow'
import CloseIcon from './icons/close-icon'
import CollectiveHeaderDropdown from "./collective-dropdown";
import { CollectiveDropdownContext } from '../context/collective-dropdown'
import { useLocation } from '@reach/router'

const Header = () => {
  const data = useStaticQuery(graphql`
    query MainHeaderQuery {
      header: prismicMainHeader {
        ...MainHeader
      }
      config: prismicSiteConfig {
        ...SiteConfig
      }
    }
  `)

  const { navOpen, closeNav, toggleNav } = useContext(NavContext)
  const { toggleCollectiveDropdown, closeCollectiveDropdown, collectiveDropdownOpen } = useContext(CollectiveDropdownContext)
  const location = useLocation()
  const [headerSlim, setHeaderSlim] = useState(false)
  const [headerTop, setHeaderTop] = useState(true)
  const [headerMod, setHeaderMod] = useState('dark')
  const headerElement = useRef(null)
  let lastScrollTop = 0;
  const isMobile = useIsMobile(true)

  const handleScroll = useCallback(() => {
    requestAnimationFrame(() => {
      //If header needs to be sticky, do it here
      if (!document.querySelector('.main-header')) {
        return
      }

      if(window.scrollY < 20) {
        setHeaderTop(true)
      }
      else {
        setHeaderTop(false)
      }

      var st = window.scrollY || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        setHeaderSlim(true)
      } else {
        setHeaderSlim(false)
      }
      lastScrollTop = st <= 0 ? 0 : st;

      //Set the header color on scroll
      const headerEl = document.querySelector('.main-header')
      const modifierElements = new LiveNodeList("[data-header-modifier]");
      let i = modifierElements.items.length - 1;
      let el = modifierElements.items[i];
      while (
          el &&
          el.getBoundingClientRect().top >
          200 + parseInt(el.dataset.headerModifierOffset || 0)
          ) {
        i--;
        el = modifierElements.items[i];
      }

      let modifier = "dark";
      if (el) {
        modifier = el.dataset.headerModifier || modifier;
      }
      setHeaderMod(modifier)
      if(headerEl) {
        headerEl.className.split(" ").forEach((className) => {
          if (
              className.startsWith("main-header__theme--") &&
              className !== `main-header__theme--${modifier}`
          ) {
            headerEl.classList.remove(className);
          }
        });

        if (!headerEl.classList.contains(`main-header__theme--${modifier}`)) {
          headerEl.classList.add(`main-header__theme--${modifier}`);
        }
      }
    })
  }, [])

  const handleClick = useCallback(() => {
    if (
      document &&
      document?.activeElement instanceof Element &&
      'blur' in document?.activeElement
    ) {
      ;(document.activeElement as HTMLElement)?.blur()
    }

    closeNav()
    closeCollectiveDropdown()
  }, [closeNav, closeCollectiveDropdown])

  const mobileMenuToggle = useCallback(() => {
    if (
        document &&
        document?.activeElement instanceof Element &&
        'blur' in document?.activeElement
    ) {
      ;(document.activeElement as HTMLElement)?.blur()
    }

    toggleNav()
    closeCollectiveDropdown()
  }, [toggleNav, closeCollectiveDropdown])

  const collectiveDropdown = useCallback(() => {
    event.preventDefault()
    toggleCollectiveDropdown()
  }, [toggleCollectiveDropdown])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll, { passive: false })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll, location])

  /** @type {MainHeader} header */
  const header: MainHeader = data?.header
  if (!header) {
    return null
  }

  /** @type {SiteConfig} config */
  const config: SiteConfig = data?.config.data
  if (!config) {
    return null
  }
  const { language } = useContext(TranslationContext)
  return (
    <header
      className={`main-header 
      ${headerSlim ? 'main-header--slim' : ''} 
      ${headerTop ? 'main-header--top' : ''} 
      ${navOpen ? 'main-header__nav-open' : ''} 
      ${collectiveDropdownOpen && !isMobile ? 'main-header__dropdown-open' : ''}
      ${headerMod ? 'main-header__theme--' + headerMod : ''}`}
      ref={headerElement}
    >
      <div className="main-header__container">
        <div className="main-header__logo">
          <Link
            to="/"
            className="main-header__logo-image"
            onClick={handleClick}
          >
            <img
              src="/images/logo_black.gif"
              alt="Mayda"
              className="main-header__logo--black"
            />
            <img
              src="/images/logo_white.gif"
              alt="Mayda"
              className="main-header__logo--white"
            />
          </Link>
          <div className="main-header__tagline">
            <span className="main-header__tagline-text">
              The Mayda Creative Co.
            </span>
          </div>
        </div>
        {!isMobile ?
            <div className="main-header__nav--desktop">
              <nav id="nav--desktop" className="main-header__nav--desktop">
                <ul className="nav__list--desktop">
                  {header.data.navigation_items.map(
                      (link: MainHeaderLink, index: number) => {
                        return (
                          <li key={index} className="nav__item">
                            {link.collective_dropdown && !link.mobile_menu_only ?
                                <a
                                  href={'#'}
                                  className="nav__link"
                                  onClick={collectiveDropdown}
                                >
                                  {link.label}
                                  {!collectiveDropdownOpen ?
                                    <ButtonArrow/>
                                  : <CloseIcon/>}
                                </a>
                            :
                                <>
                                  {!link.mobile_menu_only ?
                                      <Link
                                          to={linkResolver(link.link)}
                                          className="nav__link"
                                          onClick={handleClick}
                                      >
                                        {link.label}
                                      </Link>
                                  : ''}
                                </>}
                          </li>
                        )
                      },
                  )}
                </ul>
              </nav>
            </div>
        : ''}
        <div className="main-header__buttons">
          <ButtonRounded
            text="Contact us"
            link={linkResolver({
              type: 'contact_page',
              uid: 'contact-us',
              lang: language,
            })}
            style={`white`}
            arrow={false}
            type={'button'}
            styles={[]}
            className={''}
            onClick={handleClick}
          />
          {isMobile ?
              <div className={'menu-toggle__wrap'}>
                <MenuToggle
                    className="main-header__toggle"
                    label="Open Nav"
                    closeLabel="Close Nav"
                    aria-controls="nav"
                    onClick={mobileMenuToggle}
                />
              </div>
          : ''}
        </div>
      </div>
      <CollectiveHeaderDropdown/>
      <nav id="nav" className={`main-header__nav nav`} aria-hidden={!navOpen}>
        <ul className="nav__list">
          {header.data.navigation_items.map(
              (link: MainHeaderLink, index: number) => (
                  <li key={index} className="nav__item">
                    {link.collective_dropdown ?
                        <a
                            href={'#'}
                            className="nav__link"
                            onClick={collectiveDropdown}
                        >
                          {link.label}
                          <ButtonArrow/>
                        </a>
                        :
                        <Link
                            to={linkResolver(link.link)}
                            className="nav__link"
                            onClick={handleClick}
                        >
                          {link.label}
                        </Link>}
                  </li>
              ),
          )}
        </ul>
        <ul className="main-header__social">
          {config.facebook_username && (
              <li>
                <a
                    href={config.facebook_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  Facebook
                </a>
              </li>
          )}
          {config.instagram_username && (
              <li>
                <a
                    href={config.instagram_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  <Instagram />
                </a>
              </li>
          )}
          {config.twitter_username && (
              <li>
                <a
                    href={config.twitter_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  <Twitter />
                </a>
              </li>
          )}
          {config.linkedin_username && (
              <li>
                <a
                    href={config.linkedin_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  <Linkedin />
                </a>
              </li>
          )}
          {config.tiktok_username && (
              <li>
                <a
                    href={config.tiktok_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  TikTok
                </a>
              </li>
          )}
          {config.dribbble_username && (
              <li>
                <a
                    href={config.dribbble_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  Dribbble
                </a>
              </li>
          )}
          {config.youtube_username && (
              <li>
                <a
                    href={config.youtube_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  Youtube
                </a>
              </li>
          )}
          {config.vimeo_username && (
              <li>
                <a
                    href={config.vimeo_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  Vimeo
                </a>
              </li>
          )}
          {config.discord_username && (
              <li>
                <a
                    href={config.discord_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  Discord
                </a>
              </li>
          )}
          {config.reddit_username && (
              <li>
                <a
                    href={config.reddit_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  Reddit
                </a>
              </li>
          )}
          {config.behance_username && (
              <li>
                <a
                    href={config.behance_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  Behance
                </a>
              </li>
          )}
          {config.spotify_username && (
              <li>
                <a
                    href={config.spotify_username}
                    target="_blank"
                    rel="nofollow noopener"
                    onClick={handleClick}
                >
                  <Spotify />
                </a>
              </li>
          )}
        </ul>
      </nav>
    </header>
  )
}

export default Header
