import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import { Image } from '@superrb/gatsby-addons/components'
import CollectiveArrow from './icons/collective-arrow'
import { useIsMobile, useMotionAllowed } from '@superrb/gatsby-addons/hooks'
import ReactPlayer from 'react-player'
import { CookiesContext } from '../context/cookies-context'
import useImageSources from '@superrb/gatsby-addons/src/hooks/use-image-sources'

interface props {
  name: string
  job_role: string
  link: string
  image: Image
  video: string
}

export const CollectiveItem = ({
  name,
  job_role,
  link,
  image,
  video,
}: Props) => {
  const isMobile = useIsMobile(true)
  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoPlaying, setVideoPlaying] = useState(true)
  const isMotionAllowed = useMotionAllowed()

  useEffect(() => {
    if (!isMotionAllowed) {
      setVideoPlaying(false)
    }
  }, [isMotionAllowed, cookiesAccepted])

  const { src: imageSrc } = useImageSources(image)

  return (
    <div className="collective__item">
      <div className="collective__item-images">
        <Link to={linkResolver(link)} className="collective__nav-item-link">
          <Image
            image={image}
            className={`collective__nav-item-image ${
              video?.url ? 'collective__nav-item-image--hover' : ''
            }`}
          />
        </Link>
        {cookiesAccepted && video?.url ? (
          <div className="collective__nav-item-image--extra">
            <ReactPlayer
              url={video?.url}
              muted={true}
              playsinline={true}
              playing={videoPlaying}
              autoPlay={videoPlaying}
              loop={true}
              className="collective__nav-item-video"
              light={false}
              controls={false}
              config={{
                vimeo: {
                  playerOptions: {
                    responsive: true,
                  },
                },
                file: {
                  attributes: {
                    poster: imageSrc,
                  },
                },
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      <Link to={linkResolver(link)} className="collective__nav-item-link">
        <span className="collective__nav-item-footer">
          <span className="collective__nav-item-footer--name">
            {name}
            <CollectiveArrow />
          </span>
          <span className="collective__nav-item-footer--role">{job_role}</span>
        </span>
      </Link>
    </div>
  )
}
