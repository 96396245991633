import React, { StrictMode } from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { LocationProvider } from '@reach/router'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { Layout } from './src/components/layout'
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import './src/stylesheets/style.sass'
import { StateInspector } from 'reinspect'
import {
  NavContextProvider,
  TranslationContextProvider,
  LazyLoadingContextProvider,
  PreloadContextProvider,
} from '@superrb/gatsby-addons/context'
import { FilterContextProvider } from './src/context/filter-context-provider'
import { CollectiveDropdownContextProvider } from './src/context/collective-dropdown'
import {CookieContext, CookiesContextProvider} from './src/context/cookies-context'
import { ContextWrapper } from '@superrb/gatsby-addons/components'
import HeaderVariantContextProvider from "./src/context/header-variant-context-provider";

export const wrapRootElement = ({ element }) => (
  <StrictMode>
    <StateInspector name="App">
      <PrismicProvider
        internalLinkComponent={({ href, ...props }) => (
          <Link to={href} {...props} />
        )}
      >
        <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
          <ContextWrapper>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
            >
              <CookiesContextProvider>
                <HeaderVariantContextProvider>
                  <CollectiveDropdownContextProvider>
                    <LocationProvider>
                      <FilterContextProvider>
                        <Layout>{element}</Layout>
                      </FilterContextProvider>
                    </LocationProvider>
                  </CollectiveDropdownContextProvider>
                </HeaderVariantContextProvider>
              </CookiesContextProvider>
            </GoogleReCaptchaProvider>
          </ContextWrapper>
        </PrismicPreviewProvider>
      </PrismicProvider>
    </StateInspector>
  </StrictMode>
)

export const onRouteUpdate = ({ location: { pathname }, prevLocation }) => {
  if (pathname === '/') {
    pathname = 'home'
  }

  document.body.setAttribute(
      'class',
      `page ${[
        ...pathname
            .split('/')
            .filter((x) => !!x)
            .map((slug, index) => `page--${slug} ${(index === 1 ? 'page--child' : '')}`),
      ].join(' ')}`,
  )
}