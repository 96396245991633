import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { FeaturedCaseStudySection } from '../components/featured-case-studies'

export const FeaturedCaseStudies = ({
  slice,
  context
}: SliceComponentProps<any, any>) => {
  const data = {
    title_l: slice?.primary?.title_left,
    title_r: slice?.primary?.title_right,
    intro_text: slice?.primary?.intro_text,
    link: slice?.primary?.link,
  }

  return (
    <FeaturedCaseStudySection
      title1={data?.title_l}
      title2={data?.title_r}
      text={data?.intro_text}
      link={data?.link}
      items={slice?.items}
      theme={`${context.workPage ? 'black' : 'white'}`}
    />
  )
}

export const query = graphql`
  fragment HomepageDataBodyFeaturedCaseStudies on PrismicHomepageDataBodyFeaturedCaseStudies {
    primary {
      title_left
      title_right
      intro_text
      link {
        ...Link
      }
    }
    items {
      work_page {
        document {
          ... on PrismicWorkDetailPage {
            ...WorkDetailPage
          }
        }
      }
    }
  }

  fragment WorkDetailPageDataBodyFeaturedCaseStudies on PrismicWorkDetailPageDataBodyFeaturedCaseStudies {
    primary {
      title_left
      title_right
      intro_text
      link {
        ...Link
      }
    }
    items {
      work_page {
        document {
          ...WorkDetailPreview
        }
      }
    }
  }
`
