import {Image, Page, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, PageProps } from 'gatsby'
import React, {useContext, useEffect, useState} from 'react'
import ContactPageType from '../types/pages/contact-page'
import ContactForm from '../components/contact-form'
import { RichText } from '@superrb/gatsby-addons-prismic/components'
import { SiteConfig } from '@superrb/gatsby-addons-prismic/types'
import moment from 'moment-timezone'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const ContactPage = ({
  data,
}: PageProps<{ prismicContactPage: ContactPageType }, PageStub>) => {
  const page = data.prismicContactPage
  if (!page) {
    return null
  }

  const {
    data: { page_title, contact_text, image, form_title },
  } = page

  const siteConfig: SiteConfig = data?.config?.data

  const [formattedNumber, setFormattedNumber] = useState('')

  const [nyTime, setNyTime] = useState('--:--')
  const [ldnTime, setLdnTime] = useState('--:--')
  const [laTime, setLaTime] = useState('--:--')

  let once = false
  useEffect(() => {
    if (once) {
      return
    }

    if (siteConfig.phone_number) {
      const formattedTel = siteConfig.phone_number
        .replace('(0)', '')
        .replace(' ', '')
      setFormattedNumber(formattedTel)
    }

    const updateTimezones = () => {
      const d = new Date()
      const m = moment(d)

      const ny = m.tz('America/New_York')
      const nyTimeString = ny.hours() + ':' + ny.minutes()
      setNyTime(nyTimeString)

      const ldn = m.tz('Europe/London')
      const ldnTimeString = ldn.hours() + ':' + ldn.minutes()
      setLdnTime(ldnTimeString)

      const la = m.tz('America/Los_Angeles')
      const laTimeString = la.hours() + ':' + la.minutes()
      setLaTime(laTimeString)
    }
    updateTimezones()

    const clockInterval = setInterval(() => {
      updateTimezones()
    }, 3000)

    once = true

    return () => {
      clearInterval(clockInterval)
    }
  }, [])

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <section className="contact-page light-theme" data-header-modifier={'light'}>
          <div className="contact-page__container container">
            <div className="contact-page__inner">
              <div className="contact-page__col contact-page__col--text">
                {page_title && (
                  <h1 className={'contact-page__title'}>{page_title}</h1>
                )}
                {contact_text ? <RichText field={contact_text.richText} /> : ''}
                <div className="contact-page__details">
                  <h5 className="contact-page__details-title">New business</h5>
                  <h6 className="contact-page__details-link">
                    {siteConfig.phone_number ? (
                      <a href={`tel: ${formattedNumber}`}>
                        {siteConfig.phone_number}
                      </a>
                    ) : (
                      ''
                    )}
                    {siteConfig.email_address ? (
                      <a
                        href={`mailto: ${siteConfig.email_address}`}
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        {siteConfig.email_address}
                      </a>
                    ) : (
                      ''
                    )}
                  </h6>
                </div>
                {image ? (
                  <Image image={image} className="contact-page__image" />
                ) : (
                  ''
                )}
              </div>
              <div className="contact-page__col contact-page__col--form">
                <div className="form__border">
                  {form_title ? (
                      <h4 className="contact-page__form-title">{form_title}</h4>
                  ) : (
                      ''
                  )}
                  <ContactForm />
                </div>
                <div className="contact-page__times">
                  <div className="contact-page__time">
                    <h6>LDN</h6>
                    <p>{ldnTime}</p>
                  </div>
                  <div className="contact-page__time">
                    <h6>NY</h6>
                    <p>{nyTime}</p>
                  </div>
                  <div className="contact-page__time">
                    <h6>LA</h6>
                    <p>{laTime}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </>
  )
}

export const query = graphql`
  query ContactPageQuery($id: String) {
    prismicContactPage(id: { eq: $id }) {
      ...ContactPage
    }
    config: prismicSiteConfig {
      ...SiteConfig
    }
  }
`

export default withPrismicPreview(ContactPage)
