import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import Flickity from 'react-flickity-component'
import { CollectiveItem } from '../components/collective-item'
import ButtonRounded from '../components/buttons/button-rounded'
import { linkResolver } from '../utils/linkResolver'
import { CenterCta } from '../components/center-cta'
import { TranslationContext } from '@superrb/gatsby-addons/context'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

export const CollectiveSwiper = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    text: slice.primary.sub_text,
  }

  const { language } = useContext(TranslationContext)
  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <>
      <section
        className={`collective-swiper light-theme`}
        aria-label="Collective Swiper"
        data-header-modifier={'light'}
      >
        <div className="collective-swiper__container container">
          <div className="collective-swiper__inner">
            <div
              className={`collective-swiper__header animate-on-scroll ${
                isInViewport ? 'animated' : ''
              }`}
              ref={setRef}
            >
              {data.title ? (
                <h2 className="collective-swiper__title">{data.title}</h2>
              ) : (
                ''
              )}
              {data.text ? (
                <p className="collective-swiper__text">{data.text}</p>
              ) : (
                ''
              )}
              <div className="collective-swiper__cta">
                <ButtonRounded
                  text={'MEET THE TEAM'}
                  text_a11y={'Go to the collective page'}
                  link={linkResolver({
                    type: 'collective_page',
                    uid: 'collective',
                    lang: language,
                  })}
                  style={'black'}
                  arrow={true}
                  type={'button'}
                  styles={[]}
                  className={''}
                  onClick={() => {}}
                />
              </div>
            </div>
            {slice.items ? (
              <Flickity
                className={'carousel'} // default ''
                elementType={'div'} // default 'div'
                options={{
                  draggable: false,
                  wrapAround: true,
                  autoPlay: 3000,
                  pauseAutoPlayOnHover: false,
                }} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
              >
                {slice.items.map((slide, index) => {
                  const team_video =
                    slide.team_member_link.document.data.listing_video
                  return (
                    <div
                      className="collective-swiper__slide"
                      key={`collective-swiper-slide-${index}`}
                    >
                      <CollectiveItem
                        name={slide.label}
                        job_role={slide.job_role}
                        link={slide.team_member_link}
                        image={slide.image}
                        video={team_video}
                      />
                    </div>
                  )
                })}
              </Flickity>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
      <CenterCta title="LETS WORK TOGETHER" text="" />
    </>
  )
}

export const query = graphql`
  fragment StudioPageDataBodyCollectiveSwiper on PrismicStudioPageDataBodyCollectiveSwiper {
    primary {
      title
      sub_text
    }
    items {
      label
      job_role
      team_member_link {
        document {
          ... on PrismicCollectiveDetailPage {
            id
            data {
              listing_video {
                ...Link
              }
              extra_profile_images {
                image {
                  ...Image
                }
              }
            }
          }
        }
        id
        url
        lang
        link_type
        target
        type
        uid
      }
      image {
        ...Image
      }
    }
  }
`
