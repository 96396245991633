import {Page, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, PageProps } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import WorkPageType from '../types/pages/work-page'
import WorkDetailPageType from '../types/pages/work-detail-page'
import useArticleFilter from '../hooks/use-article-filter'
import ArticleFilters from '../components/article-filters'
import { WorkDetailIndexBlockType } from '../types/pages/work-detail-page'
import { linkResolver } from '../utils/linkResolver'
import CaseStudyItem from '../components/case-study-item'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { PageTitle } from '../components/page-title'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { LiveNodeList } from 'live-node-list'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

const WorkPage = ({
  data,
}: PageProps<{ prismicWorkPage: WorkPageType }, PageStub>) => {
  const page = data.prismicWorkPage
  if (!page) {
    return null
  }

  const {
    data: { page_title, intro_text },
  } = page

  const [availableTags, setAvailableTags] = useState([])
  const workDetailPages = data.workDetailPages.nodes
  const { isInViewport, setRef } = useIsInViewport(false)

  let once = true

  const filteredArticles = useArticleFilter(workDetailPages, availableTags)
  let counter = 1

  useEffect(() => {
    const uniqueTags = Array.from(
      new Set(data.workDetailPagesTags.nodes.flatMap((tag) => tag.tags)),
    )
    setAvailableTags(uniqueTags)

    gsap.registerPlugin(ScrollTrigger)

    if (!once) {
      return
    }

    gsap.from('.work-listing', {
      duration: 0.8,
      delay: 2,
      opacity: 0,
      y: 150,
      ease: 'power3',
    })
    once = false
  }, [data, setAvailableTags])

  useEffect(() => {
    gsap.to('.parallax-item-0', {
      scrollTrigger: {
        trigger: '.work-listing',
        scrub: true,
        start: 'top top',
        end: 'max',
      },
      duration: 4,
      y: -50,
    })

    gsap.to('.parallax-item-1', {
      scrollTrigger: {
        trigger: '.work-listing',
        scrub: true,
        start: 'top top',
        end: 'max',
      },
      duration: 4,
      y: -200,
    })
  }, [filteredArticles])
  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <div className="news-header__container container">
          <div
            className={`news-header__inner animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
            ref={setRef}
          >
            {page_title && <h1 className="news-header__title">{page_title}</h1>}
            {intro_text ? (
              <p className="news-header__text">{intro_text}</p>
            ) : (
              ''
            )}
          </div>
        </div>

        <section className="work-listing" data-header-modifier={'dark'}>
          <div className="work-listing__container container">
            <div className="work-listing__inner">
              <ArticleFilters tags={availableTags} />

              <nav className="work__nav">
                <ul className="work__nav-list">
                  {filteredArticles &&
                    filteredArticles.map(
                      (workDetailPage: WorkDetailPageType, index) => {
                        const WorkDetailPageIndexBlock: WorkDetailIndexBlockType =
                          {
                            link: linkResolver(workDetailPage),
                            title: workDetailPage.data?.listing_title,
                            client: workDetailPage.data?.listing_client,
                            excerpt: workDetailPage.data?.listing_excerpt,
                            image: workDetailPage.data?.listing_image,
                            video: workDetailPage.data?.listing_video?.url,
                          }

                        if (counter === 0) {
                          counter = 1
                        } else {
                          counter = 0
                        }

                        return (
                          <li
                            className={`work__nav-list-item parallax-item ${
                              counter === 0
                                ? 'parallax-item-0'
                                : 'parallax-item-1'
                            }`}
                            key={`work__nav-list-item--` + index}
                          >
                            <CaseStudyItem
                              indexBlock={WorkDetailPageIndexBlock}
                            />
                          </li>
                        )
                      },
                    )}
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </Page>
    </>
  )
}

export const query = graphql`
  query WorkPageQuery($id: String) {
    prismicWorkPage(id: { eq: $id }) {
      ...WorkPage
    }
    workDetailPages: allPrismicWorkDetailPage(sort: { data: { date: DESC } }) {
      nodes {
        ...WorkDetailPage
      }
    }
    workDetailPagesTags: allPrismicWorkDetailPage {
      nodes {
        tags
      }
    }
  }
`

export default withPrismicPreview(WorkPage)
