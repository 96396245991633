import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { RichText } from '@superrb/gatsby-addons/components'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
export const ProjectOverview = ({ slice, context }: SliceComponentProps<any, any>) => {
  const data = {
    client: slice.primary.client,
    brand: slice.primary.brand,
    type: slice.primary.type,
    service: slice.primary.service,
    rich_text: slice.primary.rich_text,
  }
  const { isInViewport, setRef } = useIsInViewport(false)
  return (
    <section
      className={`project-overview light-theme`}
      aria-label="Project Overview"
      data-header-modifier={context.darkTheme ? 'dark' : 'light'}
    >
      <div className="project-overview__container container">
        <div className="project-overview__inner" ref={setRef}>
          <div
            className={`project-overview__table animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            <dl className="project-overview__list">
              {data.client ? (
                <>
                  <dt>Client:</dt>
                  <dd>{data.client}</dd>
                </>
              ) : (
                ''
              )}
              {data.brand ? (
                <>
                  <dt>Brand:</dt>
                  <dd>{data.brand}</dd>
                </>
              ) : (
                ''
              )}
              {data.type ? (
                <>
                  <dt>Type:</dt>
                  <dd>{data.type}</dd>
                </>
              ) : (
                ''
              )}
              {data.service ? (
                <>
                  <dt>Service:</dt>
                  <dd>{data.service}</dd>
                </>
              ) : (
                ''
              )}
            </dl>
          </div>
          <div
            className={`project-overview__overview animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            {data.rich_text ? <RichText field={data.rich_text.richText} /> : ''}
          </div>
        </div>
      </div>
    </section>
  )
}
export const query = graphql`
  fragment WorkDetailPageDataBodyProjectOverview on PrismicWorkDetailPageDataBodyProjectOverview {
    primary {
      client
      brand
      type
      service
      rich_text {
        ...RichText
      }
    }
  }
`
