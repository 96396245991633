import { Link } from '@superrb/gatsby-addons/components'

import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import * as Yup from 'yup'
import { linkResolver } from '../utils/linkResolver'
import ButtonRounded from './buttons/button-rounded'
import { TranslationContext } from '@superrb/gatsby-addons/context'

interface Data {
    "emailAddress": string,
    "blackListed": boolean,
    "customFields": object,
}

const NewsletterForm = ({
  onSubmitted,
  disabled,
}: {
  onSubmitted: (data: Data) => void
  disabled: boolean
}) => {
  const form = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>
  const emailInput =
    useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>
  const [error, setError] = useState<string>()
  const { language } = useContext(TranslationContext)

  useEffect(() => {
    if (error) {
      form.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, [error])

  const submit = () => {
    if (
      !(
        emailInput.current?.value &&
        emailInput.current?.value?.indexOf('@') > -1
      )
    ) {
      setError('Please enter a valid email address')
      return
    }

    onSubmitted({
        emailAddress: emailInput.current.value,
        blackListed: false,
        customFields: {}
    })
  }

  return (
    <div className="newsletter__form-el" ref={form}>
      <div
        className={`newsletter__form-row ${
          error ? 'newsletter__form-row--has-error' : ''
        }`}
      >
        <input
          ref={emailInput}
          type="email"
          name={'MERGE0'}
          id={'MERGE0'}
          placeholder="Your email"
          disabled={disabled}
        />
        <ButtonRounded
          className="newsletter__form-submit"
          text_a11y="Submit"
          onClick={submit}
          disabled={disabled}
          arrow={true}
        />
      </div>

      {error && <p className="newsletter__form-error">{error}</p>}

      <p className="newsletter-form__footer">
        By signing up to receive emails from Mayda, you agree to our{' '}
        <Link
          to={linkResolver({
            type: 'legal_page',
            uid: 'privacy-policy',
            lang: language,
          })}
        >
          Privacy Policy
        </Link>
        . We treat your info responsibly. Unsubscribe anytime.
      </p>
    </div>
  )
}

const Newsletter = () => {
    const [status, setStatus] = useState('')
    return (
        <div className="footer__newsletter">
            <form action={'#'}>
                {status === 'success' ? (
                    <div className="newsletter__form">
                        <h4 className={'newsletter__form-thank-you'}>
                            Thank you for signing up!
                        </h4>
                    </div>
                ) : (
                    <div className="newsletter__form">
                        <NewsletterForm
                            onSubmitted={(formData: Data) => {
                                event.preventDefault()
                                const newXHR = new XMLHttpRequest();
                                newXHR.addEventListener('load', (e) => {
                                    //const results = this.responseText
                                    if(e.target?.status === 200) {
                                        setStatus('success')
                                    }
                                    else {
                                        setStatus('error')
                                    }
                                })
                                const jsonData = JSON.stringify(formData)
                                newXHR.open('POST', process.env.GATSBY_TRANSPOND_SUBSCRIBE_URL);
                                newXHR.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                                newXHR.setRequestHeader('x-access-token', process.env.GATSBY_TRANSPOND_API_KEY);
                                newXHR.send(jsonData)
                            }}
                            disabled={status === 'sending'}
                        />
                        {status === 'error' && (
                            <p className="newsletter__error">Something went wrong, please try again.</p>
                        )}
                    </div>
                )}
            </form>
        </div>
    )
}

export default Newsletter
