import React from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import {PrismicRichText as RichText} from "@prismicio/react";
import { FacebookEmbed, InstagramEmbed, LinkedInEmbed, PinterestEmbed, TikTokEmbed, TwitterEmbed, YouTubeEmbed } from 'react-social-media-embed';

export const SocialEmbed = ({ slice }: SliceComponentProps<any, any>) => {
    return (
        <section className="social-embed light-theme" data-header-modifier={'light'}>
            <div className="social-embed__container container">
                <div className="social-embed__inner">
                    <div className="social-embed__col">
                        {slice.primary?.code ?
                            <RichText
                                field={slice.primary.code.richText}
                                components={{
                                    embed: ({ node, key }) => {
                                        const url = node.oembed.embed_url
                                        if (url.includes('facebook.com')) {
                                            return <FacebookEmbed
                                                url={url}
                                                width={'100%'}
                                                 />
                                        }

                                        if (url.includes('instagram.com')) {
                                            return <InstagramEmbed url={url} width={'100%'} />
                                        }

                                        if (url.includes('linkedin.com')) {
                                            return <LinkedInEmbed
                                                url={url}
                                                width={'100%'}
                                                height={570}
                                            />
                                        }

                                        if (url.includes('pinterest.co')) {
                                            return <PinterestEmbed
                                                url={url}
                                                width={345}
                                                height={467}
                                            />
                                        }

                                        if (url.includes('tiktok.com')) {
                                            return <TikTokEmbed url={url} width={325} />
                                        }

                                        if (url.includes('twitter.com')) {
                                            return <TwitterEmbed url={url} width={325} />
                                        }

                                        if (url.includes('youtube.com') || url.includes('youtu.be')) {
                                            return <YouTubeEmbed url={url} width={'100%'} height={220} />
                                        }

                                        return (
                                            <div
                                                key={key}
                                                data-oembed={node.oembed.embed_url}
                                                data-oembed-type={node.oembed.type}
                                                data-oembed-provider={node.oembed.provider_name}
                                                dangerouslySetInnerHTML={{
                                                    __html: node.oembed.html ?? '',
                                                }}
                                            />
                                        )
                                    },
                                }}
                            />
                        : ''}
                    </div>
                </div>
            </div>
        </section>
    )
}

export const query = graphql`
  fragment BlogDetailPageDataBodySocialEmbed on PrismicBlogDetailPageDataBodySocialEmbed {
    primary {
      code {
        ...RichText
      }
    }
  }
`
