import { Link } from 'gatsby'
import Cookies from 'js-cookie'
import React, { useContext, useEffect, useState } from 'react'
import { CookiesContext } from '../context/cookies-context'

export const CookieBanner = () => {
  // Have we already accepted cookies?
  const accepted = Cookies.get('gatsby-accepted-cookies') || false

  const { cookiesAccepted, setCookiesAccepted } = useContext(CookiesContext)
  const [animate, setAnimate] = useState(false)
  const [loadBanner, setLoadBanner] = useState(false)

  const accept = () => {
    setAnimate(true)
    window.setTimeout(() => {
      setCookiesAccepted(true)
    }, 800)
  }

  const reject = () => {
    setAnimate(true)
    window.setTimeout(() => {
      setCookiesAccepted(false)
    }, 800)
  }

  useEffect(() => {
    setTimeout(() => {
      setLoadBanner(true)
    }, 500)
  }, [setLoadBanner])

  return (
    <>
      {!cookiesAccepted || !accepted ? (
        <div
          className={`cookie-banner ${loadBanner ? 'cookie-banner__fade-in' : ''} ${animate ? ' cookie-banner--hide' : ''} `}
        >
          <div className="cookie-banner__container container">
            <div className="cookie-banner__inner">
              <div className="cookie-banner__message">
                This website uses cookies to ensure you get the best experience. Curious about{' '}
                <Link to="/legal/cookies-policy">cookies?</Link>
              </div>
              <div className="cookie-banner__buttons">
                <button
                  onClick={accept}
                  className="cookie-banner__agree button button__rounded button__rounded--black"
                >
                  <span className={'button__wrap'}>
                    <span className={`button__inner`}>
                      <span className="button__bg" data-text={'Agree'}></span>
                      <span className="button__text">Agree</span>
                    </span>
                  </span>
                </button>
                <button
                  onClick={reject}
                  className="cookie-banner__reject button button__rounded button__rounded--black-fill"
                >
                  <span className={'button__wrap'}>
                    <span className={`button__inner`}>
                      <span className="button__bg" data-text={'Reject'}></span>
                      <span className="button__text">Reject</span>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
