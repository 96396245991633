module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"mayda","accessToken":"MC5aRUVXNmhBQUFDSUFweWtC.77-977-977-9Vu-_ve-_ve-_ve-_ve-_ve-_ve-_vRoyAO-_ve-_vU7vv73vv71nATkAZu-_ve-_vWbvv73vv73vv701bQ"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mayda","short_name":"mayda","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"/opt/build/repo/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bffc08a0ae22871c88375a1e1b819e23"},
    },{
      plugin: require('../node_modules/@superrb/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-VNYEXRP8G6","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"cookieName":"gatsby-gdpr-hotjar"},"linkedin":{"cookieName":"gatsby-gdpr-linked-in"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
