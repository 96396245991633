import React from 'react'

const PlayCursor = () => {
  return (
    <svg
      className="play-cursor"
      width="138"
      height="138"
      viewBox="0 0 138 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M119.124 21.7633C120.705 23.4569 121.658 25.1385 119.747 26.9342C117.919 28.6518 116.308 27.721 114.661 25.9613L113.54 24.7601L110.719 27.4147L109.646 26.2676L116.931 19.415L119.13 21.7633H119.124ZM117.057 21.451L114.505 23.8533L115.691 25.1205C116.643 26.1354 117.5 26.8081 118.62 25.7511C119.806 24.634 119.136 23.6791 118.213 22.6881L117.051 21.451H117.057Z"
        fill="#F8F8F8"
      />
      <path
        d="M117.296 33.042L120.286 37.2521L119.195 38.0268L115.296 32.5376L123.431 26.73L124.341 28.0152L117.29 33.0481L117.296 33.042Z"
        fill="#F8F8F8"
      />
      <path
        d="M129.637 36.3993L130.44 37.9427L123.317 45.9124L122.526 44.3929L124.108 42.6813L122.101 38.8196L119.8 39.1499L119.064 37.7265L129.637 36.3993ZM123.491 38.6274L125.054 41.6423L128.493 37.9187L123.491 38.6334V38.6274Z"
        fill="#F8F8F8"
      />
      <path
        d="M132.71 42.8134L129.212 46.8913L134.531 47.7922L135.13 49.4257L128.121 48.0804L124.485 49.4137L123.946 47.9363L127.606 46.591L132.111 41.1558L132.71 42.8014V42.8134Z"
        fill="#F8F8F8"
      />
      <path
        d="M129.583 64.1938L137.802 64.8725L138.006 66.5181L127.798 65.3469L127.564 63.4672L135.232 60.6925L127.121 59.8997L126.887 58.0199L136.502 54.3504L136.712 56.0621L128.918 58.8127L137.143 59.5334L137.371 61.3591L129.583 64.1878V64.1938Z"
        fill="#F8F8F8"
      />
      <path
        d="M127.78 70.2897L127.804 68.7162L137.79 68.8783L137.766 70.4518L127.78 70.2897Z"
        fill="#F8F8F8"
      />
      <path
        d="M136.161 75.3165L136.49 72.1154L137.82 72.2536L136.994 80.2052L135.664 80.067L135.993 76.878L127.391 75.9771L127.552 74.4096L136.155 75.3105L136.161 75.3165Z"
        fill="#F8F8F8"
      />
      <path
        d="M134.543 90.3849L124.875 87.8625L125.27 86.337L129.607 87.4661L130.871 82.6014L126.534 81.4724L126.929 79.9469L136.598 82.4693L136.203 83.9948L132.147 82.9378L130.883 87.8024L134.939 88.8594L134.543 90.3849Z"
        fill="#F8F8F8"
      />
      <path
        d="M126.109 96.721C127.007 95.5799 128.325 94.7331 129.829 95.4898C131.518 96.3426 131.416 98.5047 130.661 100.006C129.841 101.646 128.373 102.691 126.402 102.126L127.085 100.769C128.283 101.021 129.098 100.204 129.523 99.3515C129.853 98.6968 130.104 97.4176 129.17 96.9492C128.433 96.5768 127.75 96.9792 127.211 97.6639L126.456 98.6128C125.444 99.898 124.114 101.189 122.317 100.282C120.543 99.3875 120.471 97.2194 121.346 95.4778C122.388 93.4058 124.228 92.589 126.204 93.3217L125.51 94.703C124.162 94.3487 123.119 94.8592 122.484 96.1204C121.903 97.2795 122.047 98.3725 122.91 98.811C123.832 99.2734 124.647 98.5707 125.33 97.7119L126.103 96.727L126.109 96.721Z"
        fill="#F8F8F8"
      />
      <path
        d="M116.494 103.471C118.201 101.111 120.993 100.889 123.461 102.679C125.929 104.468 126.582 107.195 124.875 109.555C123.179 111.903 120.394 112.132 117.925 110.342C115.457 108.552 114.792 105.82 116.494 103.471ZM117.554 104.24C116.536 105.652 116.841 107.561 118.872 109.033C120.903 110.51 122.796 110.192 123.814 108.78C124.839 107.357 124.545 105.459 122.514 103.982C120.483 102.504 118.578 102.811 117.554 104.234V104.24Z"
        fill="#F8F8F8"
      />
      <path
        d="M118.902 116.54L114.708 112.252C113.385 110.901 112.288 110.624 110.952 111.94C109.616 113.255 109.922 114.438 111.258 115.801L115.373 120.011L114.253 121.116L110.192 116.966C108.071 114.798 107.951 113.039 110.024 110.997C112.043 109.015 113.696 108.973 115.907 111.231L120.022 115.441L118.902 116.54Z"
        fill="#F8F8F8"
      />
      <path
        d="M101.098 118.456L109.449 122.714L104.71 115.921L105.974 115.032L111.701 123.236L109.904 124.498L101.559 120.251L106.292 127.032L105.028 127.921L99.3006 119.717L101.098 118.456Z"
        fill="#F8F8F8"
      />
      <path
        d="M99.5642 130.84C96.7006 132.203 94.0947 132.179 92.4413 128.702C90.7819 125.212 92.4054 123.164 95.2689 121.801L97.731 120.624L102.026 129.668L99.5642 130.846V130.84ZM99.0669 129.638L100.049 129.17L96.8684 122.474L95.8859 122.942C94.0827 123.801 92.4952 125.02 93.909 127.999C95.3168 130.966 97.2638 130.497 99.0669 129.638Z"
        fill="#F8F8F8"
      />
      <path
        d="M85.2346 136.065C82.9881 136.611 81.0591 136.605 80.4421 134.059C79.855 131.614 81.4545 130.671 83.7968 130.107L85.3903 129.723L84.4798 125.951L86.0074 125.579L88.3557 135.314L85.2346 136.071V136.065ZM86.5226 134.419L85.7019 131.008L84.0125 131.416C82.6586 131.746 81.6522 132.155 82.0176 133.662C82.401 135.248 83.5632 135.134 84.8752 134.815L86.5226 134.419Z"
        fill="#F8F8F8"
      />
      <path
        d="M76.3804 128.894L71.2585 129.416L71.1207 128.083L77.8062 127.398L78.8186 137.356L77.2551 137.518L76.3745 128.894H76.3804Z"
        fill="#F8F8F8"
      />
      <path
        d="M67.3705 137.998L65.6393 137.938L62.2306 127.801L63.9379 127.861L64.6448 130.083L68.982 130.233L69.8327 128.065L71.4322 128.119L67.3765 137.992L67.3705 137.998ZM68.4668 131.542L65.0821 131.422L66.6217 136.257L68.4668 131.542Z"
        fill="#F8F8F8"
      />
      <path
        d="M60.2896 137.524L58.4685 132.467L55.0718 136.665L53.3585 136.383L57.9713 130.93L58.6003 127.098L60.1519 127.356L59.5168 131.212L62.0209 137.812L60.2956 137.53L60.2896 137.524Z"
        fill="#F8F8F8"
      />
      <path
        d="M43.2463 124.293L38.6156 131.14L37.0879 130.509L43.1205 122.167L44.8638 122.888L43.5039 130.948L48.1766 124.257L49.9199 124.978L48.3863 135.176L46.7928 134.515L48.2365 126.353L43.5698 133.182L41.8745 132.479L43.2403 124.287L43.2463 124.293Z"
        fill="#F8F8F8"
      />
      <path
        d="M38.8372 119.711L40.1971 120.498L35.1949 129.17L33.835 128.383L38.8372 119.711Z"
        fill="#F8F8F8"
      />
      <path
        d="M30.3545 124.534L32.9664 126.407L32.1876 127.494L25.6998 122.84L26.4786 121.753L29.0785 123.615L34.1106 116.558L35.3866 117.477L30.3545 124.534Z"
        fill="#F8F8F8"
      />
      <path
        d="M18.0857 115.687L25.0468 108.498L26.173 109.597L23.0519 112.822L26.6523 116.324L29.7734 113.099L30.8996 114.198L23.9385 121.387L22.8123 120.287L25.7297 117.273L22.1294 113.771L19.2119 116.786L18.0857 115.687Z"
        fill="#F8F8F8"
      />
      <path
        d="M16.7498 105.237C17.3009 106.582 17.3908 108.15 15.995 109.093C14.4254 110.15 12.5983 109.003 11.6578 107.603C10.6334 106.078 10.4476 104.288 11.9034 102.847L12.748 104.108C11.9393 105.033 12.2508 106.138 12.784 106.931C13.1914 107.537 14.1798 108.39 15.0484 107.802C15.7374 107.339 15.7194 106.552 15.3899 105.742L14.9346 104.619C14.3176 103.105 13.8443 101.309 15.5157 100.186C17.1631 99.0752 19.0801 100.078 20.1644 101.7C21.4584 103.628 21.2607 105.639 19.6552 107.003L18.7926 105.718C19.7631 104.715 19.829 103.555 19.0442 102.384C18.3193 101.309 17.3009 100.895 16.4982 101.435C15.6415 102.012 15.8512 103.069 16.2705 104.09L16.7498 105.249V105.237Z"
        fill="#F8F8F8"
      />
      <path
        d="M15.5996 93.5139C16.8157 96.1624 15.6295 98.7149 12.8619 99.9881C10.0942 101.261 7.40441 100.493 6.1943 97.844C4.9842 95.2075 6.15836 92.6611 8.92603 91.3879C11.6937 90.1146 14.3955 90.8774 15.5996 93.5139ZM14.4074 94.0604C13.6826 92.4809 11.8734 91.8083 9.59698 92.8533C7.32054 93.9043 6.66157 95.712 7.38644 97.2915C8.11729 98.883 9.91448 99.5617 12.1909 98.5167C14.4674 97.4657 15.1383 95.6519 14.4134 94.0604H14.4074Z"
        fill="#F8F8F8"
      />
      <path
        d="M3.04923 89.1717L8.84216 87.6283C10.6693 87.1418 11.4481 86.319 10.9628 84.5053C10.4836 82.6915 9.30344 82.3732 7.46432 82.8657L1.77922 84.3792L1.37784 82.8597L6.98507 81.3643C9.91448 80.5835 11.502 81.3463 12.2448 84.1629C12.9697 86.9016 12.1909 88.367 9.14169 89.1777L3.45659 90.6912L3.05522 89.1717H3.04923Z"
        fill="#F8F8F8"
      />
      <path
        d="M10.1841 72.716L2.3663 77.8929L10.5974 77.1182L10.7412 78.6557L0.790762 79.5926L0.587081 77.4065L8.39286 72.2295L0.173728 73.0043L0.0299531 71.4608L9.98038 70.5239L10.1841 72.71V72.716Z"
        fill="#F8F8F8"
      />
      <path
        d="M0.197691 65.2629C0.419344 62.0918 1.7253 59.8277 5.5593 60.1039C9.41127 60.3742 10.3877 62.8005 10.1601 65.9715L9.9684 68.6982L0 67.9955L0.1917 65.2689L0.197691 65.2629ZM1.48567 65.425L1.4078 66.5121L8.78824 67.0346L8.86612 65.9475C9.00391 63.9476 8.73433 61.9597 5.44548 61.7315C2.1746 61.4973 1.62346 63.4311 1.47968 65.425H1.48567Z"
        fill="#F8F8F8"
      />
      <path
        d="M2.70177 50.1945C3.33079 47.9603 4.2833 46.2787 6.79936 46.9934C9.21358 47.678 9.24353 49.5398 8.58456 51.8641L8.13527 53.4436L11.8614 54.5006L11.4361 56.014L1.82714 53.2814L2.70177 50.1824V50.1945ZM3.49852 52.1283L6.86525 53.0832L7.33851 51.4076C7.71592 50.0683 7.85371 48.9813 6.36803 48.5609C4.80448 48.1165 4.33122 49.1855 3.9598 50.4887L3.49852 52.1223V52.1283Z"
        fill="#F8F8F8"
      />
      <path
        d="M13.2872 45.9844L15.354 41.2519L16.5761 41.7864L13.8803 47.9603L4.7266 43.9485L5.35562 42.5071L13.2812 45.9844H13.2872Z"
        fill="#F8F8F8"
      />
      <path
        d="M9.81264 33.6426L10.7232 32.1592L21.2068 34.1952L20.3142 35.6545L18.0378 35.1741L15.7613 38.8796L17.223 40.6874L16.3844 42.0507L9.81863 33.6366L9.81264 33.6426ZM14.8747 37.7866L16.6479 34.8918L11.6937 33.8468L14.8747 37.7866Z"
        fill="#F8F8F8"
      />
      <path
        d="M13.7365 27.6969L19.0262 28.6098L17.0673 23.571L18.1576 22.2197L20.6077 28.9341L23.621 31.3725L22.6326 32.5976L19.6013 30.1413L12.6402 29.0602L13.7425 27.6969H13.7365Z"
        fill="#F8F8F8"
      />
      <path
        d="M33.6074 19.367L29.9411 11.9619L31.2411 10.9409L35.5184 20.3099L34.0327 21.481L27.7006 16.3341L31.2111 23.7031L29.7255 24.8742L21.6262 18.5202L22.98 17.4511L29.3541 22.7242L25.7237 15.2951L27.1615 14.16L33.6014 19.379L33.6074 19.367Z"
        fill="#F8F8F8"
      />
      <path
        d="M39.7538 17.7574L38.3999 18.5562L33.3258 9.93194L34.6797 9.13318L39.7538 17.7574Z"
        fill="#F8F8F8"
      />
      <path
        d="M39.7358 7.98008L36.8244 9.33738L36.2612 8.12421L43.4919 4.74298L44.055 5.95614L41.1616 7.30743L44.8159 15.169L43.3901 15.8356L39.7358 7.97407V7.98008Z"
        fill="#F8F8F8"
      />
      <path
        d="M53.4603 1.626L56.2699 11.2352L54.7663 11.6796L53.5082 7.3735L48.6978 8.78485L49.9558 13.091L48.4522 13.5354L45.6426 3.9262L47.1462 3.48177L48.3264 7.51163L53.1368 6.10028L51.9567 2.07042L53.4663 1.626H53.4603Z"
        fill="#F8F8F8"
      />
      <path
        d="M63.1951 5.61982C61.7573 5.43965 60.3495 4.73697 60.2237 3.06137C60.08 1.17557 61.979 0.14258 63.6564 0.0164592C65.4835 -0.121673 67.1249 0.599017 67.6641 2.58091L66.1544 2.69502C65.7471 1.53591 64.6388 1.25965 63.6863 1.33172C62.9555 1.38577 61.7334 1.82419 61.8112 2.86919C61.8712 3.69798 62.5661 4.07034 63.4287 4.19045L64.6268 4.3466C66.2443 4.5568 68.0355 5.03126 68.1853 7.04318C68.335 9.02508 66.5139 10.2022 64.5789 10.3463C62.2725 10.5205 60.6251 9.35539 60.2297 7.28341L61.7693 7.1693C62.1587 8.50858 63.1352 9.14519 64.537 9.03709C65.831 8.941 66.6876 8.25034 66.6157 7.28942C66.5379 6.25643 65.5194 5.92011 64.4292 5.77597L63.1891 5.61982H63.1951Z"
        fill="#F8F8F8"
      />
      <path
        d="M73.9303 10.3944C71.0368 10.1481 69.4014 7.86597 69.653 4.82105C69.9046 1.77614 71.8995 -0.187736 74.7929 0.0524937C77.6744 0.292723 79.3099 2.5689 79.0583 5.60781C78.8067 8.64671 76.8118 10.6286 73.9303 10.3884V10.3944ZM74.0381 9.08513C75.7694 9.22927 77.2431 7.98008 77.4528 5.47568C77.6624 2.97129 76.4164 1.50588 74.6851 1.36175C72.9418 1.21761 71.4681 2.44879 71.2585 4.95318C71.0488 7.45758 72.2948 8.93499 74.0381 9.08513Z"
        fill="#F8F8F8"
      />
      <path
        d="M83.8687 1.57795L82.3651 7.39151C81.8918 9.22327 82.2213 10.3103 84.0365 10.7788C85.8516 11.2472 86.7023 10.3704 87.1815 8.5266L88.6552 2.81514L90.1768 3.21152L88.7211 8.8389C87.9603 11.7817 86.5166 12.7907 83.707 12.058C80.9753 11.3493 80.0827 9.94396 80.8734 6.88703L82.3471 1.17557L83.8687 1.57195V1.57795Z"
        fill="#F8F8F8"
      />
      <path
        d="M94.6698 15.8896L94.0168 6.52068L90.6441 14.0879L89.2363 13.4573L93.3099 4.31657L95.3108 5.21143L95.9698 14.5684L99.3365 7.01315L100.744 7.64375L96.6707 16.7845L94.6698 15.8896Z"
        fill="#F8F8F8"
      />
      <path
        d="M106.064 10.8628C108.7 12.6225 110.018 14.8807 107.891 18.0877C105.752 21.3068 103.171 20.9645 100.529 19.1988L98.2582 17.6854L103.794 9.34939L106.064 10.8628ZM105.291 11.9078L104.387 11.3013L100.289 17.4752L101.194 18.0817C102.859 19.1928 104.716 19.9315 106.537 17.1869C108.352 14.4483 106.957 13.0189 105.291 11.9078Z"
        fill="#F8F8F8"
      />
    </svg>
  )
}

export default PlayCursor
