import {Page, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, PageProps } from 'gatsby'
import React, {useContext, useEffect} from 'react'
import WorkDetailPageType from '../types/pages/work-detail-page'
import { components } from '../slices'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const WorkDetailPage = ({
  data,
  pageContext,
}: PageProps<{ prismicWorkDetailPage: WorkDetailPageType }, PageStub>) => {
  const page = data?.page || data?.prismicWorkDetailPage
  if (!page) {
    return null
  }

  const {
    data: { page_title, client_name, dark_theme, workPage },
  } = page

  pageContext.title = page_title
  pageContext.client = client_name
  pageContext.darkTheme = dark_theme
  pageContext.workPage = true

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <div className={`work-detail__theme ${dark_theme ? 'work-detail__theme--dark' : 'work-detail__theme--light'}`}>
          <SliceZone
              slices={page.data.body}
              components={components}
              context={pageContext}
          />
        </div>
      </Page>
    </>
  )
}

export const query = graphql`
  query WorkDetailPageQuery($id: String) {
    prismicWorkDetailPage(id: { eq: $id }) {
      ...WorkDetailPage
    }
  }
`

export default withPrismicPreview(WorkDetailPage)
