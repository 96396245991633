import React from 'react'

const ButtonArrow = ({ ...props }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={'button-arrow'}
      {...props}
    >
      <path
        d="M10.1916 21.1916L20.3832 11L10.1917 0.808411"
        stroke="#030303"
        strokeWidth="2"
      />
      <path
        d="M19.829 11L0.944218 11"
        stroke="#030303"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ButtonArrow
