import React from 'react'
import { graphql } from 'gatsby'

export const Spacer = () => {
  return (
    <section
      className="spacer light-theme"
      data-header-modifier={'light'}
    ></section>
  )
}

export const query = graphql`
  fragment WorkDetailPageDataBodySpacer on PrismicWorkDetailPageDataBodySpacer {
    slice_type
  }
`
