import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import { RichText as RichTextType } from '@superrb/gatsby-addons/types'
import { useIsInViewport, useIsMobile } from '@superrb/gatsby-addons/hooks'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

interface Props extends Record<string, any> {
  description: RichTextType
}

export const ScrollingTextFixedImage = ({
  slice,
}: SliceComponentProps<any, any>) => {
  const data = {
    title_l: slice.primary.title_left,
    title_r: slice.primary.title_right,
  }

  const isMobile = useIsMobile(true)
  const { isInViewport, setRef } = useIsInViewport(false)

  let once = true
  useEffect(() => {
    if (!once) {
      return
    }
    once = false

    setTimeout(function () {
      gsap.registerPlugin(ScrollTrigger)
      const images = document.querySelectorAll(
        '.scrolling-text-fixed-image__image-wrap',
      )
      const markers = document.querySelectorAll(
        '.scrolling-text-fixed-image__text-wrap',
      )

      for (var i = 0; i < images.length; i++) {
        gsap.to(images[i], {
          scrollTrigger: {
            trigger: markers[i],
            scrub: true,
            start: 'top top',
            end: 'bottom top',
          },
          duration: 1,
          clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
        })
      }
    }, 2000)
  }, [isMobile, once])

  return (
    <section className="scrolling-text-fixed-image light-theme">
      <div className="scrolling-text-fixed-image__container container">
        <div className="scrolling-text-fixed-image__inner" ref={setRef}>
          <div
            className={`scrolling-text-fixed-image__header animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            <h2 className="scrolling-text-fixed-image__title">
              {data.title_l ? <span>{data.title_l}</span> : ''}
              {data.title_r ? <span>{data.title_r}</span> : ''}
            </h2>
          </div>
          <div
            className={`scrolling-text-fixed-image__col scrolling-text-fixed-image__col--text animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            {slice.items &&
              slice.items.map((item, index) => (
                <div
                  className={`scrolling-text-fixed-image__text-wrap scrolling-text-fixed-image__text-wrap--${index}`}
                  key={`scrolling-text-fixed-image-item-${index}`}
                >
                  {isMobile && item.image ? <Image image={item.image} /> : ''}
                  <p className="scrolling-text-fixed-image__counter">
                    ({index + 1 < 10 ? '0' + (index + 1) : index + 1})
                  </p>
                  {item.title ? (
                    <h2 className="scrolling-text-fixed-image__title">
                      {item.title}
                    </h2>
                  ) : (
                    ''
                  )}
                  {item.text ? (
                    <p className="scrolling-text-fixed-image__text">
                      {item.text}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              ))}
          </div>
          {!isMobile ? (
            <div
              className={`scrolling-text-fixed-image__col scrolling-text-fixed-image__col--image animate-on-scroll ${
                isInViewport ? 'animated' : ''
              }`}
            >
              <div className="scrolling-text-fixed-image__sticky">
                {slice.items &&
                  slice.items.map((item, index) => (
                    <div
                      className={`scrolling-text-fixed-image__image-wrap scrolling-text-fixed-image__image-wrap--${index}`}
                      key={`scrolling-text-fixed-image-picture-${index}`}
                      style={{ zIndex: slice.items.length - index }}
                    >
                      {item.image ? <Image image={item.image} /> : ''}
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CareersPageDataBodyScrollingTextFixedImage on PrismicCareersPageDataBodyScrollingTextFixedImage {
    primary {
      title_left
      title_right
    }
    items {
      image {
        ...Image
      }
      title
      text
    }
  }

  fragment StudioPageDataBodyScrollingTextFixedImage on PrismicStudioPageDataBodyScrollingTextFixedImage {
    primary {
      title_left
      title_right
    }
    items {
      image {
        ...Image
      }
      title
      text
    }
  }
`
