import { useLocation } from '@reach/router'
import qs from 'query-string'
import React, { createContext, useCallback, useEffect, useState } from 'react'

export const FilterContext = createContext({
  selectedTags: [],
  setSelectedTags: (tags) => {},
  setSelectedTagsAndUpdateUrl: (tags) => {},
  availableTags: [],
  expanded: true,
  addTag: (tag) => {},
  removeTag: (tag) => {},
  clearTags: () => {},
  setAvailableTags: (tags) => tags,
  setExpanded: (expanded) => {},
  toggleExpanded: (expanded) => {},
  listingOpacity: 1,
  setListingOpacity: (opacity) => {},
  isFiltered: false,
  setIsFiltered: (isFiltered) => {},
})

const QS_OPTIONS = { arrayFormat: 'bracket' }

const pushState = (state, title, url) =>
  window.history.pushState(state, title, url)

export const FilterContextProvider = ({ children }) => {
  const [isFiltered, setIsFiltered] = useState(false)
  const [queryString, setQueryString] = useState('')
  const [selectedTags, setSelectedTagsState] = useState([])
  const [availableTags, setAvailableTagsState] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [listingOpacity, setListingOpacity] = useState(1)
  const location = useLocation()

  const setSelectedTags = useCallback(
    (callback) => {
      setSelectedTagsState((tags) => {
        if (Array.isArray(callback)) {
          tags = callback
        } else {
          tags = callback(tags)
        }

        const set = new Set(tags)
        return [...set]
      })
    },
    [setSelectedTagsState],
  )

  const setAvailableTags = useCallback(
    (callback) => {
      setAvailableTagsState((tags) => {
        if (Array.isArray(callback)) {
          tags = callback
        } else {
          tags = callback(tags)
        }

        const set = new Set(tags)
        return [...set]
      })
    },
    [setAvailableTagsState],
  )

  const updateTags = useCallback(() => {
    const newQueryString = qs.parse(location.search, QS_OPTIONS)
    setQueryString(newQueryString)
    setSelectedTags(newQueryString.tags || [])
  }, [location, setQueryString, setSelectedTags])

  const setSelectedTagsAndUpdateUrl = useCallback(
    (callback) => {
      setSelectedTags((tags) => {
        const newTags = callback(tags)
        const newQueryString = queryString || {}
        newQueryString.tags = [...newTags]

        pushState(
          newQueryString,
          null,
          `?${qs.stringify(newQueryString, QS_OPTIONS)}`,
        )

        return newTags
      })
    },
    [queryString, setSelectedTags],
  )

  const addTag = useCallback(
    (tag) => {
      setSelectedTagsAndUpdateUrl((tags) => {
        tags.push(tag)
        return tags
      })
    },
    [setSelectedTagsAndUpdateUrl],
  )

  const removeTag = useCallback(
    (tag) => {
      setSelectedTagsAndUpdateUrl((tags) => {
        tags.splice(tags.indexOf(tag), 1)
        return tags
      })
    },
    [setSelectedTagsAndUpdateUrl],
  )

  const clearTags = useCallback(() => {
    setSelectedTagsAndUpdateUrl(() => [])
  }, [setSelectedTagsAndUpdateUrl])

  const toggleExpanded = useCallback(() => {
    setExpanded((expanded) => !expanded)
  }, [setExpanded])

  useEffect(() => {
    const newQueryString = qs.parse(location.search, QS_OPTIONS)
    setQueryString(newQueryString)
    setSelectedTags(newQueryString.tags || [])
  }, [location, setQueryString, setSelectedTags])

  useEffect(() => {
    window.addEventListener('popstate', updateTags)

    return () => {
      window.removeEventListener('popstate', updateTags)
    }
  }, [updateTags])

  return (
    <FilterContext.Provider
      value={{
        selectedTags,
        setSelectedTags,
        setSelectedTagsAndUpdateUrl,
        availableTags,
        expanded,
        addTag,
        removeTag,
        clearTags,
        setAvailableTags,
        setExpanded,
        toggleExpanded,
        listingOpacity,
        setListingOpacity,
        isFiltered,
        setIsFiltered,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}
