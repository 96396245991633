import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import {
  useIsInViewport,
  useIsMobile,
  useMotionAllowed,
} from '@superrb/gatsby-addons/hooks'
import { CookiesContext } from '../context/cookies-context'
import ReactPlayer from 'react-player'
import useImageSources from '@superrb/gatsby-addons/src/hooks/use-image-sources'

interface GalleryItemProps {
  galleryItem: SliceComponentProps<any, any>['slice']['items'][0]
  style: Partial<CSSProperties>
  cookiesAccepted: boolean
  videoPlaying: boolean
  isInViewport: boolean
}

const GalleryItem = ({
  galleryItem,
  style,
  cookiesAccepted = false,
  videoPlaying = false,
  isInViewport = false,
}: GalleryItemProps) => {
  const { src: imageSrc } = useImageSources(galleryItem.image)

  return (
    <div
      className={`media-gallery__item animate-on-scroll ${
        isInViewport ? 'animated' : ''
      } ${
        galleryItem?.gallery_text.text && !galleryItem?.image.url
          ? 'media-gallery__item--text'
          : ''
      }`}
      style={style}
      data-align={galleryItem?.text_alignment}
    >
      {galleryItem?.gallery_text.text && !galleryItem?.image.url ? (
        <div
          className="media-gallery__text"
          style={{ fontSize: `${galleryItem?.text_font_size || '14'}px` }}
        >
          <RichText field={galleryItem?.gallery_text.richText} />
        </div>
      ) : (
        <>
          {cookiesAccepted && galleryItem?.video?.url ? (
            <ReactPlayer
              url={galleryItem.video.url}
              muted={true}
              playsinline={true}
              playing={videoPlaying}
              autoPlay={videoPlaying}
              loop={true}
              className="media-gallery__video"
              light={false}
              controls={false}
              config={{
                vimeo: {
                  playerOptions: {
                    responsive: true,
                  },
                },
                file: {
                  attributes: {
                    poster: imageSrc,
                  },
                },
              }}
            />
          ) : (
            <Image
              image={galleryItem.image}
              className={'media-gallery__image'}
            />
          )}
        </>
      )}
    </div>
  )
}

export const MediaGallery = ({
  slice,
  context,
}: SliceComponentProps<any, any>) => {
  const isMobile = useIsMobile(true, '45em')
  const data = {
    image_row_size: slice.primary.image_size,
    cols: slice.primary.number_of_columns,
  }

  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoPlaying, setVideoPlaying] = useState(true)
  const isMotionAllowed = useMotionAllowed()

  const { isInViewport, setRef } = useIsInViewport(false)

  useEffect(() => {
    if (!isMotionAllowed) {
      setVideoPlaying(false)
    }
  }, [isMotionAllowed, cookiesAccepted])

  return (
    <section
      className={`media-gallery light-theme`}
      aria-label="Media Gallery"
      data-header-modifier={context.darkTheme ? 'dark' : 'light'}
    >
      <div className="media-gallery__container container">
        <div
          className="media-gallery__inner"
          ref={setRef}
          data-height={data.image_row_size}
          data-cols={data.cols}
          style={{ gridTemplateColumns: `repeat(${data.cols}, 1fr)` }}
        >
          {slice.items &&
            slice.items.map((galleryItem, index) => (
              <GalleryItem
                galleryItem={galleryItem}
                videoPlaying={videoPlaying}
                isInViewport={isInViewport}
                cookiesAccepted={cookiesAccepted}
                style={{
                  gridColumnEnd: `span ${
                    isMobile ? 1 : galleryItem.column_span
                  }`,
                  gridRowEnd: `span ${isMobile ? 1 : galleryItem.row_span}`,
                }}
                key={'gallery-item_' + index}
              />
            ))}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment WorkDetailPageDataBodyMediaGallery on PrismicWorkDetailPageDataBodyMediaGallery {
    primary {
      image_size
      number_of_columns
    }
    items {
      image {
        ...Image
      }
      gallery_text {
        ...RichText
      }
      text_font_size
      text_alignment
      column_span
      row_span
      video {
        ...Link
      }
    }
  }
`
