import React, { useCallback, useContext, useEffect, useState } from 'react'
import { linkResolver } from '../utils/linkResolver'
import NewsArticleItem from './news-article-item'
import BlogDetailPageType, {
  BlogDetailIndexBlockType,
} from '../types/pages/blog-detail-page'

interface props {
  text: string
  link: string
  items: object
  tag: string
  article_tags: Array
  exclude: string
}

export const FeaturedArticlesSection = ({
  text,
  items,
  article_tags,
  exclude,
}: Props) => {
  let count = 0
  let matchingPostCount = 0
  let matchingPosts = []

  return (
    <>
      <section className={'light-theme__cap'}></section>
      <section
        className={`featured-case-studies featured-articles`}
        aria-label="Featured Articles"
        data-header-modifier={'dark'}
      >
        <div className="featured-case-studies__container container">
          <div className="featured-case-studies__header">
            <h2 className="featured-case-studies__title">
              Related <span>Articles</span>
            </h2>
          </div>
          <div className="featured-case-studies__inner">
            {items.map((blogDetailPage: BlogDetailPageType, index) => {
              if (matchingPostCount === 3 || blogDetailPage.id === exclude) {
                return
              }
              article_tags.forEach((tag) => {
                if (blogDetailPage.tags.includes(tag)) {
                  matchingPosts.push(blogDetailPage)
                  matchingPostCount++
                }
              })
            })}
            {matchingPosts.length === 3
              ? matchingPosts.map(
                  (blogDetailPage: BlogDetailPageType, index) => {
                    if(!blogDetailPage) {
                      return <></>
                    }
                    const BlogDetailPageIndexBlock: BlogDetailIndexBlockType = {
                      link: linkResolver(blogDetailPage),
                      title: blogDetailPage.data.listing_title,
                      excerpt: blogDetailPage.data.listing_excerpt,
                      image: blogDetailPage.data.listing_image,
                      date: blogDetailPage.data.date,
                      type: blogDetailPage.data.listing_article_type,
                    }
                    return (
                      <NewsArticleItem
                        featured={false}
                        indexBlock={BlogDetailPageIndexBlock}
                        key={`featured-article-item-${index}`}
                      />
                    )
                  },
                )
              : ''}
            {matchingPosts.length < 3
              ? items.map((blogDetailPage: BlogDetailPageType, index) => {
                  if(!blogDetailPage) {
                    return <></>
                  }
                  if (count === 3 || blogDetailPage.id === exclude) {
                    return
                  }

                  count++

                  const BlogDetailPageIndexBlock: BlogDetailIndexBlockType = {
                    link: linkResolver(blogDetailPage),
                    title: blogDetailPage.data.listing_title,
                    excerpt: blogDetailPage.data.listing_excerpt,
                    image: blogDetailPage.data.listing_image,
                    date: blogDetailPage.data.date,
                    type: blogDetailPage.data.listing_article_type,
                  }
                  return (
                    <NewsArticleItem
                      featured={false}
                      indexBlock={BlogDetailPageIndexBlock}
                      key={`featured-all-article-item-${index}`}
                    />
                  )
                })
              : ''}
          </div>
        </div>
      </section>
    </>
  )
}
