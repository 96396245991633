import {Page, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import CollectiveDetailPageType from '../types/pages/collective-detail-page'
import { CollectiveDetailTitle } from '../components/collective-detail-title'
import { components } from '../slices'
import { SliceZone } from '@prismicio/react'
import { CenterCta } from '../components/center-cta'
import {
  withPrismicPreview,
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews'

const CollectiveDetailPage = ({
  data,
  pageContext,
}: PageProps<
  { prismicCollectiveDetailPage: CollectiveDetailPageType },
  PageStub
>) => {
  const page = data?.page || data?.prismicCollectiveDetailPage
  if (!page) {
    return null
  }

  const {
    data: {
      page_title,
      job_role,
      bio,
      teaser_video,
      header_video,
      image,
      instagram,
      swap_image_and_title,
      linkedin,
      twitter,
    },
  } = page

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <CollectiveDetailTitle
          title={page_title}
          job_role={job_role}
          teaser_video={teaser_video?.url}
          video={header_video?.url}
          text={bio}
          image={image}
          swap_title={swap_image_and_title}
          instagram={instagram}
          linkedin={linkedin}
          twitter={twitter}
        />
        <SliceZone
          slices={page.data.body}
          components={components}
          context={pageContext}
        />
        <CenterCta title="LETS WORK TOGETHER" />
      </Page>
    </>
  )
}

export const query = graphql`
  query CollectiveDetailPageQuery($id: String) {
    prismicCollectiveDetailPage(id: { eq: $id }) {
      ...CollectiveDetailPage
    }
  }
`

export default withPrismicPreview(CollectiveDetailPage)
