import {Page, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, PageProps } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import BlogPageType from '../types/pages/blog-page'
import useArticleFilter from '../hooks/use-article-filter'
import ArticleFilters from '../components/article-filters'
import { linkResolver } from '../utils/linkResolver'
import BlogDetailPageType, {
  BlogDetailIndexBlockType,
} from '../types/pages/blog-detail-page'
import NewsArticleItem from '../components/news-article-item'
import { FilterContext } from '../context/filter-context-provider'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const BlogPage = ({
  data,
}: PageProps<{ prismicBlogPage: BlogPageType }, PageStub>) => {
  const page = data.prismicBlogPage
  if (!page) {
    return null
  }

  const {
    data: { page_title, sub_text, featured_article },
  } = page

  const [availableTags, setAvailableTags] = useState([])
  const [showFeaturedArticles, setShowFeaturedArticles] = useState(true)
  // const [featuredArticleBlock, setFeaturedArticleBlock] = useState(null)
  const blogDetailPages = data.blogDetailPages.nodes
  const { isFiltered } = useContext(FilterContext)
  const { isInViewport, setRef } = useIsInViewport(false)

  useEffect(() => {
    const uniqueTags = Array.from(
      new Set(data.blogDetailPagesTags.nodes.flatMap((tag) => tag.tags)),
    )
    setAvailableTags(uniqueTags)
  }, [data, setAvailableTags])

  const filteredArticles = useArticleFilter(blogDetailPages, availableTags)

  const featuredArticleBlock: BlogDetailIndexBlockType | undefined = featured_article?.document ? {
    link: linkResolver(featured_article?.document),
    title: featured_article?.document.data.listing_title,
    excerpt: featured_article?.document.data.listing_excerpt,
    image: featured_article?.document.data.listing_image,
    date: featured_article?.document.data.date,
    type: featured_article?.document.data.listing_article_type,
  } : undefined

  useEffect(() => {
    setShowFeaturedArticles(!isFiltered)
  }, [isFiltered, featuredArticleBlock])

  let count2 = 0
  let count3 = 0

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <section className="news-header light-theme" data-header-modifier={'light'}>
          <div className="news-header__container container">
            <div
              className={`news-header__inner animate-on-scroll ${
                isInViewport ? 'animated' : ''
              }`}
              ref={setRef}
            >
              {page_title && (
                <h1 className="news-header__title">{page_title}</h1>
              )}
              {sub_text ? <p className="news-header__text">{sub_text}</p> : ''}
            </div>
          </div>
        </section>
        <section className="news-filters light-theme" data-header-modifier={'light'}>
          <div className="news-filters__container container">
            <div className="news-filters__inner">
              <ArticleFilters tags={availableTags} />
            </div>
          </div>
        </section>
        <section className="blog-featured light-theme" data-header-modifier={'light'}>
          <div className="blog-featured__container container">
            <div className="blog-featured__inner">
              {showFeaturedArticles && featuredArticleBlock ? (
                <div className="featured-news-article">
                  <NewsArticleItem
                    featured={true}
                    indexBlock={featuredArticleBlock}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
        <section className="news-list light-theme" data-header-modifier={'light'}>
          <div className="news-list__container container">
            <div className="news-list__inner">
              <nav className="blog__nav">
                <ul className="blog__nav-list">
                  {filteredArticles &&
                    filteredArticles.map(
                      (blogDetailPage: BlogDetailPageType, index) => {
                        const BlogDetailPageIndexBlock: BlogDetailIndexBlockType =
                          {
                            link: linkResolver(blogDetailPage),
                            title: blogDetailPage.data.listing_title,
                            excerpt: blogDetailPage.data.listing_excerpt,
                            image: blogDetailPage.data.listing_image,
                            date: blogDetailPage.data.date,
                            type: blogDetailPage.data.listing_article_type,
                          }

                        if (count2 === 2) {
                          count2 = 0
                        }

                        count2++

                        if (count3 === 3) {
                          count3 = 0
                        }

                        count3++

                        return (
                          <>
                            <li className="blog__nav-list-item" key={index}>
                              <NewsArticleItem
                                featured={false}
                                indexBlock={BlogDetailPageIndexBlock}
                              />
                            </li>
                            {count2 === 2 ? (
                              <li
                                className={`blog__nav-list-item blog__nav-list-item--line blog__nav-list-item--line--2`}
                              ></li>
                            ) : (
                              ''
                            )}
                            {count3 === 3 ? (
                              <li
                                className={`blog__nav-list-item blog__nav-list-item--line blog__nav-list-item--line--3`}
                              ></li>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      },
                    )}
                </ul>
              </nav>
            </div>
          </div>
        </section>
      </Page>
    </>
  )
}

export const query = graphql`
  query BlogPageQuery($id: String) {
    prismicBlogPage(id: { eq: $id }) {
      ...BlogPage
    }
    blogDetailPages: allPrismicBlogDetailPage(sort: { data: { date: DESC } }) {
      nodes {
        ...BlogDetailPage
      }
    }
    blogDetailPagesTags: allPrismicBlogDetailPage {
      nodes {
        tags
      }
    }
  }
`

export default withPrismicPreview(BlogPage)
