import React from 'react'

const CollectiveArrow = () => {
  return (
    <span className="collective-arrow__wrap">
      <svg
        width="11"
        height="11"
        className="collective-arrow collective-arrow--hover"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.792969 10L9.79297 10L9.79297 1"
          stroke="#030303"
          strokeWidth="1.5"
        />
        <path
          d="M9.54785 9.75538L1.20947 1.41699"
          stroke="#030303"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
      <svg
        width="11"
        height="11"
        className="collective-arrow"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.792969 10L9.79297 10L9.79297 1"
          stroke="#030303"
          strokeWidth="1.5"
        />
        <path
          d="M9.54785 9.75538L1.20947 1.41699"
          stroke="#030303"
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}

export default CollectiveArrow
