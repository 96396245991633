import React from 'react'

const Spotify = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.7499 0.1875C4.46887 0.1875 0.1875 4.46875 0.1875 9.7498C0.1875 15.0312 4.46887 19.3121 9.7499 19.3121C15.0316 19.3121 19.3125 15.0312 19.3125 9.7498C19.3125 4.46909 15.0317 0.1875 9.7499 0.1875ZM15.4061 8.66401C12.3242 6.83345 7.23937 6.66514 4.29691 7.55822C3.8244 7.70153 3.32471 7.4348 3.18152 6.96226C3.03833 6.48952 3.30484 5.99018 3.7777 5.84653C7.15549 4.82113 12.7707 5.01924 16.319 7.12566C16.7449 7.37789 16.8842 7.92685 16.6318 8.35127C16.3806 8.77621 15.8297 8.91635 15.4061 8.66401ZM14.2802 11.621C14.6308 11.8368 15.0898 11.726 15.3056 11.3753C15.5215 11.0252 15.4107 10.5663 15.0601 10.3505C12.1084 8.53661 7.79109 8.0262 4.31827 9.07986C3.92466 9.19981 3.70233 9.61546 3.82109 10.0097C3.94075 10.4033 4.3572 10.6256 4.75149 10.5064C7.7917 9.58354 11.7098 10.0411 14.2802 11.621ZM13.3154 14.1765C13.5962 14.349 13.9639 14.2599 14.1352 13.979C14.3077 13.6981 14.2186 13.3315 13.9377 13.1591C11.4171 11.6187 8.29262 11.2604 4.64998 12.0926C4.32842 12.1657 4.12825 12.4854 4.20168 12.8063C4.27475 13.1271 4.59448 13.3282 4.91524 13.255C8.24377 12.4945 11.0702 12.8051 13.3154 14.1765Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Spotify
