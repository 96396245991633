import React, {useContext, useEffect} from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql, Link } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import {
  CareersListingType,
  CareersListingJobType,
} from '../types/pages/careers-page'
import CollectiveArrow from '../components/icons/collective-arrow'
import { CenterCta } from '../components/center-cta'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

export const CareersListing = ({ slice }: SliceComponentProps<any, any>) => {
  const data: CareersListingType = {
    heading_left: slice.primary.heading_left,
    heading_right: slice.primary.heading_right,
    jobs: slice.items,
  }

  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <>
      <section className={`careers-listing`} aria-label="Careers Listing" data-header-modifier={'dark'}>
        <div className="careers-listing__container container">
          <div className="careers-listing__inner" ref={setRef}>
            <h2
              className={`careers-listing__title animate-on-scroll ${
                isInViewport ? 'animated' : ''
              }`}
            >
              {data.heading_left && <span>{data.heading_left} </span>}
              {data.heading_right && <span>{data.heading_right}</span>}
            </h2>

            <p className="careers-listing__count">
              All open positions ({data.jobs.length})
            </p>
            <nav>
              <ul className="careers-listing__list">
                {data.jobs &&
                  data.jobs.map((job: CareersListingJobType, index) => {
                    return (
                      <li className="careers-listing__item" key={index}>
                        <Link
                          to={linkResolver(job.job_link)}
                          className="careers-listing__link"
                        >
                          <span className="careers-listing__item-title">
                            {job.job_title}
                          </span>
                          <span className="careers-listing__item-location">
                            {job.job_location}
                          </span>
                          <CollectiveArrow />
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <CenterCta
        title="Don‘t see any job opening that might fit you?"
        text="We are always looking for exceptional talent. If you're a creative director, director, technologist, artist, designer or producer, or simply have a passion for creativity and technology, we'd love to work with you."
      />
    </>
  )
}
export const query = graphql`
  fragment CareersPageDataBodyCareersListing on PrismicCareersPageDataBodyCareersListing {
    primary {
      heading_left
      heading_right
    }
    items {
      job_title
      job_location
      job_link {
        ...Link
      }
    }
  }
`
