import React, { useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image } from '@superrb/gatsby-addons/components'
import PlayIcon from '../components/icons/play-icon'
import { VideoPopup } from '../components/video-popup'
import { CookiesContext } from '../context/cookies-context'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import checkVideoUrl from '../utils/checkVideoUrl'

export const FeaturedVideo = ({
  slice,
  context,
}: SliceComponentProps<any, any>) => {
  const data = {
    video: slice.primary.video_link,
    image: slice.primary.video_thumbnail,
  }

  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const { isInViewport, setRef } = useIsInViewport(false)
  const [popupOpen, setPopupOpen] = useState<boolean>(false)

  useEffect(() => {
    setVideoIsEmbedCode(checkVideoUrl(data.video.url))
  }, [])

  return (
    <section
      className="featured-video light-theme"
      data-header-modifier={context.darkTheme ? 'dark' : 'light'}
    >
      <div className="featured-video__container container">
        <div className="featured-video__inner" ref={setRef}>
          <div
            className={`featured-video__col video-popup__container animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            {data.image ? (
              <div
                className="featured-video__trigger"
                onClick={(e) => {
                  setPopupOpen(true)
                }}
              >
                {(cookiesAccepted || !videoIsEmbedCode) && data.video?.url && (
                  <PlayIcon />
                )}
                <Image image={data.image} className={'featured-video__image'} />
              </div>
            ) : (
              ''
            )}
            {(cookiesAccepted || !videoIsEmbedCode) && data.video?.url ? (
              <VideoPopup
                video={data.video.url}
                isOpen={popupOpen}
                setIsOpen={setPopupOpen}
                popupid={0}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment WorkDetailPageDataBodyFeaturedVideo on PrismicWorkDetailPageDataBodyFeaturedVideo {
    primary {
      video_link {
        ...Link
      }
      video_thumbnail {
        ...Image
      }
    }
  }
`
