import React from 'react'

const TickerIcon = () => {
  return (
    <svg className="ticker-icon" xmlns="http://www.w3.org/2000/svg" width="80" height="77" viewBox="0 0 80 77" fill="none">
      <path d="M80 39.5C80 60.2107 63.2107 77 42.5 77C21.7893 77 5 60.2107 5 39.5C5 18.7893 21.7893 2 42.5 2C63.2107 2 80 18.7893 80 39.5Z" fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M42.5 76C62.6584 76 79 59.6584 79 39.5C79 19.3416 62.6584 3 42.5 3C22.3416 3 6 19.3416 6 39.5C6 59.6584 22.3416 76 42.5 76ZM42.5 77C63.2107 77 80 60.2107 80 39.5C80 18.7893 63.2107 2 42.5 2C21.7893 2 5 18.7893 5 39.5C5 60.2107 21.7893 77 42.5 77Z" fill="#F8F8F8"/>
      <path d="M75 37.5C75 58.2107 58.2107 75 37.5 75C16.7893 75 0 58.2107 0 37.5C0 16.7893 16.7893 0 37.5 0C58.2107 0 75 16.7893 75 37.5Z" fill="#F8F8F8"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M37.5 74C57.6584 74 74 57.6584 74 37.5C74 17.3416 57.6584 1 37.5 1C17.3416 1 1 17.3416 1 37.5C1 57.6584 17.3416 74 37.5 74ZM37.5 75C58.2107 75 75 58.2107 75 37.5C75 16.7893 58.2107 0 37.5 0C16.7893 0 0 16.7893 0 37.5C0 58.2107 16.7893 75 37.5 75Z" fill="#F8F8F8"/>
      <path d="M21.1958 59.9124C24.2922 56.8446 24.9803 50.6542 22.4457 47.1407C19.599 43.1991 19.4433 39.3875 20.195 34.7184C20.858 30.5994 19.6866 26.186 19.3093 21.7491C17.9539 21.6704 17.0566 21.619 16.1593 21.5676C16.1486 21.4049 16.1348 21.2427 16.124 21.08L26.156 19.4763C30.481 28.5892 34.901 37.8969 39.3215 47.2077C39.5732 47.1827 39.828 47.1572 40.0797 47.1321C40.3458 44.7969 40.5241 42.4452 40.9052 40.125C41.3658 37.3111 42.1499 34.5398 42.5082 31.7148C42.7893 29.4929 42.6571 27.2182 42.6746 24.9653C42.6936 22.2463 42.679 19.5266 42.6763 16.7289L52.9072 15.0934C52.9672 15.2209 53.0272 15.3483 53.0871 15.4757C52.2938 15.8461 51.5004 16.2165 50.7839 16.5533L56.6015 52.9464C57.0708 53.0632 57.9492 53.2821 59.6809 53.7147L46.4751 55.8257C46.4379 55.6886 46.4013 55.5544 46.3641 55.4172C47.2007 55.0125 48.0407 54.6103 49.211 54.0457C48.3671 48.7669 47.3777 43.5297 46.7682 38.25C46.5555 36.4054 46.8384 34.2136 47.6924 32.6034C49.131 29.8889 49.1261 27.8771 46.6425 25.9022C45.5475 25.0329 44.7211 23.8252 43.449 22.8198C45.7953 26.9449 44.1936 30.5442 43.0107 34.5727C41.5416 39.5727 41.5332 45.0059 40.9448 50.2579C40.7009 52.4465 40.584 54.6483 40.3841 57.1313C39.356 57.3474 38.325 57.564 37.0083 57.8414C31.8014 47.1158 26.6637 36.5373 21.5289 25.9585C21.2874 26.0092 21.0428 26.0605 20.8008 26.1083C21.6611 32.5567 22.4739 39.0127 23.4089 45.4521C23.6764 47.2971 24.2165 49.1138 24.7587 50.9058C25.7667 54.24 26.3109 58.0443 31.1294 58.3214L21.1954 59.9094L21.1958 59.9124Z" fill="black"/>
    </svg>
  )
}

export default TickerIcon
