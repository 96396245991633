import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { Page, Seo } from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import LegalPageType from '../types/pages/legal-page'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const LegalPage = ({
  data,
  pageContext,
}: PageProps<{ prismicLegalPage: LegalPageType }, PageStub>) => {
  /** @type {Homepage} page */
  const page = data.prismicLegalPage
  if (!page) {
    return null
  }

  const {
    data: { page_title },
  } = page

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <section className="article-copy">
          {page_title && (
            <div className="article-copy-header light-theme" data-header-modifier={'light'}>
              <div className="article-copy-header__container container">
                <h1 className="article-copy-header__title">{page_title}</h1>
              </div>
            </div>
          )}
          <SliceZone
            slices={page.data.body}
            components={components}
            context={pageContext}
          />
        </section>
      </Page>
    </>
  )
}

export const query = graphql`
  query LegalPageQuery($id: String) {
    prismicLegalPage(id: { eq: $id }) {
      ...LegalPage
    }
  }
`

export default withPrismicPreview(LegalPage)
