import {Page, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import StudioPageType from '../types/pages/studio-page'
import { components } from '../slices'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const StudioPage = ({
  data,
  pageContext,
}: PageProps<{ prismicStudioPage: StudioPageType }, PageStub>) => {
  const page = data.prismicStudioPage
  if (!page) {
    return null
  }

  const {
    data: { page_title },
  } = page

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <SliceZone
          slices={page.data.body}
          components={components}
          context={pageContext}
        />
      </Page>
    </>
  )
}

export const query = graphql`
  query StudioPageQuery($id: String) {
    prismicStudioPage(id: { eq: $id }) {
      ...StudioPage
    }
  }
`

export default withPrismicPreview(StudioPage)
