import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

export const CenterTitle = ({ slice, context }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    text: slice.primary.text,
  }

  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section className="center-title light-theme" data-header-modifier={context.darkTheme ? 'dark' : 'light'}>
      <div className="center-title__inner" ref={setRef}>
        <div
          className={`center-title__col animate-on-scroll ${
            isInViewport ? 'animated' : ''
          }`}
        >
          {data.title ? (
            <h2 className={`center-title__title ${!data.text ? 'center-title__title--nomargin' : ''}`}>{data.title}</h2>
          ) : (
            ''
          )}
          {data.text ? <p>{data.text}</p> : ''}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment WorkDetailPageDataBodyCenterTitle on PrismicWorkDetailPageDataBodyCenterTitle {
    primary {
      title
      text
    }
  }
`
