import React, {useContext, useEffect} from 'react'
import { graphql } from 'gatsby'
import { Image } from '@superrb/gatsby-addons/components'
import { SliceComponentProps } from '@prismicio/react'
import { Slice } from '@prismicio/types'
import { Image as ImageType } from '@superrb/gatsby-addons/types'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

interface Props extends Record<string, any> {
  image: ImageType
  caption: string
}

export const FullWidthImage = ({
  slice,
}: SliceComponentProps<Slice<'full_width_image', Props>>) => {
  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section className="full-width-image content-section light-theme" data-header-modifier={'light'}>
      <div className="full-width-image__container container" ref={setRef}>
        <div
          className={`full-width-image__inner animate-on-scroll ${
            isInViewport ? 'animated' : ''
          }`}
        >
          {slice.primary.image ? <Image image={slice.primary.image} /> : ''}
          {slice.primary.caption ? (
            <p className="full-width-image__caption">{slice.primary.caption}</p>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyFullWidthImage on PrismicHomepageDataBodyFullWidthImage {
    primary {
      image {
        ...Image
      }
      caption
    }
  }

  fragment StudioPageDataBodyFullWidthImage on PrismicStudioPageDataBodyFullWidthImage {
    primary {
      image {
        ...Image
      }
      caption
    }
  }
`
