/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from './linkResolver'

import BlogDetail from '../templates/blog-detail'
import Blog from '../templates/blog'
import CareersDetail from '../templates/careers-detail'
import Careers from '../templates/careers'
import CollectiveDetail from '../templates/collective-detail'
import Collective from '../templates/collective'
import Contact from '../templates/contact'
import Directors from '../templates/directors'
import Homepage from '../templates/homepage'
import LegalPage from '../templates/legal'
import Studio from '../templates/studio'
import WorkDetail from '../templates/work-detail'
import Work from '../templates/work'

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      blog_detail_page: BlogDetail,
      blog_page: Blog,
      careers_detail_page: CareersDetail,
      careers_page: Careers,
      collective_detail_page: CollectiveDetail,
      collective_page: Collective,
      contact_page: Contact,
      directors_page: Directors,
      homepage: Homepage,
      legal_page: LegalPage,
      studio_page: Studio,
      work_detail_page: WorkDetail,
      work_page: Work,
    },
  },
]
