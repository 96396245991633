import { Link } from '@superrb/gatsby-addons/components'
import React from 'react'
import ButtonArrow from '../icons/button-arrow'

type Style = 'black-fill' | 'black' | 'white-fill' | 'white'

interface Props {
  text: string
  text_a11y?: string
  link: string
  style: Style
  arrow: boolean
  type: 'button' | 'submit' | 'reset'
  styles: Style[]
  className: string
  onClick: (event: MouseEvent) => void
}

const ButtonRounded = ({
  text,
  text_a11y,
  link,
  style,
  arrow,
  type = 'button',
  styles = [],
  className = '',
  onClick = () => {},
}: Props) => {
  if (style && styles.length === 0) {
    styles = [style]
  }

  const inner = (
    <>
      <span className={'button__wrap'}>
        <span
          className={`button__inner ${
            !!text_a11y && !text ? 'screenreader-text' : ''
          }`}
        >
          {text_a11y ? (
            <span className="screenreader-text">{text_a11y}</span>
          ) : (
            ''
          )}
          <span className="button__bg" data-text={text}></span>
          <span className="button__text">{text}</span>
        </span>
      </span>
      {arrow ? (
        <span className="button__arrow">
          <ButtonArrow className="button__arrow--hover" />
          <ButtonArrow />
        </span>
      ) : (
        ''
      )}
    </>
  )

  if (link) {
    return (
      <Link
        to={link}
        className={`button button__rounded ${styles
          .map((style) => `button__rounded--${style}`)
          .join(' ')} ${className}`}
        onClick={onClick}
        aria-label={text_a11y ? text_a11y : text}
      >
        {inner}
      </Link>
    )
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={`button button__rounded ${styles
        .map((style) => `button__rounded--${style} ${styles}`)
        .join(' ')} ${className}`}
      aria-label={text_a11y ? text_a11y : text}
    >
      {inner}
    </button>
  )
}

export default ButtonRounded
