import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import {useMotionAllowed} from '@superrb/gatsby-addons/hooks'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { CookiesContext } from '../context/cookies-context'
import { Image } from '@superrb/gatsby-addons/components'
import useImageSources from '@superrb/gatsby-addons/src/hooks/use-image-sources'
import checkVideoUrl from "../utils/checkVideoUrl";

export const PlayReel = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    video: slice.primary.vimeo_video,
    image: slice.primary.fallback_image,
  }
  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoMuted, setVideoMuted] = useState(true)
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [videoTime, setVideoTime] = useState('00:00')
  const [videoType, setVideoType] = useState('short')

  const isMotionAllowed = useMotionAllowed()
  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const [videoMobileIsEmbedCode, setVideoMobileIsEmbedCode] = useState(true)

  useEffect(() => {
    if (!isMotionAllowed) {
      setVideoPlaying(false)
    }

    if(data.video) {
      setVideoIsEmbedCode(checkVideoUrl(data.video.url))
    }

    if(slice.primary.autoplay_reel.url) {
      setVideoMobileIsEmbedCode(checkVideoUrl(slice.primary.autoplay_reel.url))
    }

    if(document.querySelector('.play-reel')) {
      gsap.registerPlugin(ScrollTrigger)

      gsap.to(
          '.play-reel__title--right, .play-reel__title--left, .play-reel__video-scale',
          {
            scrollTrigger: {
              trigger: '.play-reel__title',
              scrub: true,
              start: 'top center',
              end: 'bottom center',
            },
            duration: 4,
            x: 0,
            scale: 1,
          },
      )
    }
  }, [gsap, isMotionAllowed, cookiesAccepted])

  const toHHMMSS = (time) => {
    var sec_num = parseInt(time, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor((sec_num - hours * 3600) / 60)
    var seconds = sec_num - hours * 3600 - minutes * 60

    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return minutes + ':' + seconds
  }

  const { src: imageSrc } = useImageSources(data.image)

  return (
    <>
      {(cookiesAccepted || !videoIsEmbedCode || !videoMobileIsEmbedCode) && data.video && (
        <section className={`play-reel`} aria-label="Play Reel" data-header-modifier={'dark'}>
          <div className="play-reel__container container">
            <div
              className="play-reel__inner"
              onClick={(e) => {
                if (!videoMuted) {
                  setVideoType('short')
                  setVideoMuted(true)

                  if (!isMotionAllowed) {
                    setVideoPlaying(false)
                  }
                } else {
                  setVideoType('long')
                  setVideoMuted(false)
                  let video = e.target
                    .closest('.play-reel')
                    .querySelector('video')
                  video.currentTime = 0

                  if (!isMotionAllowed) {
                    setVideoPlaying(true)
                  }
                }
              }}
            >
              <div className="play-reel__video-wrap">
                <div className="play-reel__video-scale">
                  <Image
                    image={data.image}
                    className={'play-reel__placeholder'}
                  />
                  <ReactPlayer
                    url={
                      videoType === 'short' && slice.primary.autoplay_reel
                        ? slice.primary.autoplay_reel.url
                        : data.video.url
                    }
                    muted={videoMuted}
                    playsinline={true}
                    playing={videoPlaying}
                    autoPlay={videoPlaying}
                    loop={true}
                    className="play-reel__video"
                    light={false}
                    controls={!videoMuted}
                    config={{
                      vimeo: {
                        playerOptions: {
                          responsive: true,
                        },
                      },
                      file: {
                        attributes: {
                          poster: imageSrc,
                        },
                      },
                    }}
                    onProgress={(e) => {
                      let time = Math.floor(e.playedSeconds)
                      setVideoTime(toHHMMSS(time))
                    }}
                  />
                </div>
                <div
                  className={`play-reel__title ${
                    !videoMuted ? 'play-reel__title--hide' : ''
                  }`}
                >
                  <h2>
                    <span className="play-reel__title--left">Play</span>
                    <span className="play-reel__title--right">Reel</span>
                  </h2>
                </div>
              </div>
              {/*}
              <div className="play-reel__footer">
                <div className="play-reel__footer-logo">
                  <svg
                    className="play-reel__footer--icon"
                    width="47"
                    height="47"
                    viewBox="0 0 47 47"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.5017 1.85705C35.4365 1.85705 45.143 11.5668 45.143 23.4983C45.143 35.4299 35.4332 45.1396 23.5017 45.1396C11.5701 45.1396 1.86037 35.4332 1.86037 23.5017C1.86037 11.5701 11.5668 1.85705 23.5017 1.85705ZM23.5017 0C10.5222 0 0 10.5222 0 23.5017C0 36.4811 10.5222 47.0033 23.5017 47.0033C36.4811 47.0033 47.0033 36.4811 47.0033 23.5017C47.0033 10.5222 36.4778 0 23.5017 0Z"
                      fill="#F8F8F8"
                    />
                    <path
                      d="M18.5703 14.4153L23.0472 27.5606H23.1002L27.3283 14.4153H33.2444V29.3181C33.2444 31.9644 34.2956 32.7537 35.496 33.5462C32.412 34.0072 29.3313 33.4965 29.3313 30.5352C29.3313 27.5738 29.2351 19.9599 29.2351 19.9599H29.1821C29.1821 19.9599 27.5041 24.8148 26.1577 28.7013C26.045 29.0263 25.9521 29.348 25.8427 29.6497C25.2126 31.4073 26.2307 32.7703 27.0929 33.5462C23.8066 34.2625 21.1337 32.9792 20.0062 29.6862C19.8968 29.3712 19.7608 29.0694 19.6481 28.7477C18.305 24.901 16.6436 20.1191 16.6436 20.1191H16.5906C16.5906 20.1191 16.5906 28.1177 16.5906 29.3181C16.5906 31.4604 17.8938 32.6078 19.28 33.5462C16.3319 34.2824 13.5298 32.5149 12.7273 29.5403C12.6609 29.2087 12.6543 28.8671 12.6543 28.5156V14.4186H18.5703V14.4153Z"
                      fill="#F8F8F8"
                    />
                  </svg>
                  <p className="play-reel__footer--text">Mayda.showreel</p>
                </div>
                <div className="play-reel__footer-subtext">
                  <p>Headphones on, volume up, let's do this!</p>
                </div>
                <div className="play-reel__footer-timestamp">
                  <p>{videoTime}</p>
                </div>
              </div>
              {*/}
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export const query = graphql`
  fragment HomepageDataBodyPlayReel on PrismicHomepageDataBodyPlayReel {
    primary {
      vimeo_video {
        ...Link
      }
      autoplay_reel {
        ...Link
      }
      fallback_image {
        ...Image
      }
    }
  }

  fragment CollectiveDetailPageDataBodyPlayReel on PrismicCollectiveDetailPageDataBodyPlayReel {
    primary {
      vimeo_video {
        ...Link
      }
      autoplay_reel {
        ...Link
      }
      fallback_image {
        ...Image
      }
    }
  }
`
