'use client'

import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import ReactPlayer from 'react-player'
import ButtonRounded from './buttons/button-rounded'

interface Props {
  video: string
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  popupid: number
}

export const VideoPopup = ({ video, isOpen, setIsOpen, popupid }: Props) => {
  const videoRef = useRef<ReactPlayer>(null) as MutableRefObject<ReactPlayer>

  const closePopup = () => {
    setIsOpen(false)
  }

  return (
    <div
      className={`video-popup__popup ${
        isOpen ? 'video-popup__popup--active' : ''
      }`}
      data-popup={popupid >= 0 ? popupid : ''}
    >
      <ReactPlayer
        onReady={(player) => (videoRef.current = player)}
        url={video}
        muted={false}
        playsinline={true}
        playing={isOpen}
        autoPlay={true}
        loop={true}
        className="video-popup__video"
        light={false}
        controls={true}
        config={{
          vimeo: {
            playerOptions: {
              responsive: true,
            },
          },
        }}
      />
      <div className="video-popup__footer">
        <ButtonRounded
          text="Close"
          text_a11y={'Close the video popup'}
          link=""
          style="white"
          arrow={false}
          type={'button'}
          styles={[]}
          className={''}
          onClick={closePopup}
        />
      </div>
    </div>
  )
}
