import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import { Image } from '@superrb/gatsby-addons/components'

interface props {
    title: string
    text: string
    image: Image
}

export const HeroHeader = ({
    title,
     text,
     image,
 }: Props) => {
    return (
        <>
            <section className={'hero-header'} data-header-modifier={'transparent'}>
                <div className="hero-header__container">
                    {image ?
                        <Image image={image} className={'hero-header__bg'}/>
                    : ''}
                    <div className="hero-header__inner">
                        <div className="hero-header__col container">
                            <div>
                                {title ?
                                    <h1 className="hero-header__title">{title}</h1>
                                    : ''}
                                {text ?
                                    <p className="hero-header__text">{text}</p>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
