import {Image, Page, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, Link, PageProps } from 'gatsby'
import React, {useContext, useEffect} from 'react'
import DirectorsPageType from '../types/pages/directors-page'
import { TeamMember, TeamMembers } from '../types/pages/collective-page'
import { linkResolver } from '../utils/linkResolver'
import ReactPlayer from 'react-player'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import {useIsInViewport} from "@superrb/gatsby-addons/hooks";
import {HeaderVariantContext} from "../context/header-variant-context-provider";

const DirectorsPage = ({
  data,
}: PageProps<{ prismicDirectorsPage: DirectorsPageType }, PageStub>) => {
  const page = data.prismicDirectorsPage
  if (!page) {
    return null
  }

  const {
    data: { page_title },
  } = page

  const team_members: TeamMembers = page.data.team_members
  const { variant, setHeaderVariant } = useContext(HeaderVariantContext)

  useEffect(() => {
    setHeaderVariant('transparent')
  }, [setHeaderVariant])

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <section className="directors-header">
          <div className="directors-header__container container">
            <div className="directors-header__inner">
              {page_title && <h1>{page_title}</h1>}
            </div>
          </div>
        </section>
        <section className="directors">
          <div className="directors__container container">
            <div className="directors__inner">
              <nav className="directors__nav">
                {team_members.map((item: TeamMember, index) => {
                  return (
                    <div
                      className="directors__item"
                      key={`directors-item-${index}`}
                    >
                      <Link
                        to={linkResolver(item.team_member_link)}
                        className="directors__item-link"
                      >
                        <h4>
                          {item.team_member_link?.document?.data.page_title}
                        </h4>
                      </Link>
                      <ReactPlayer
                        url={item.team_member_video?.url}
                        muted={true}
                        playsinline={true}
                        playing={true}
                        autoPlay={true}
                        loop={true}
                        className="directors__video"
                        light={false}
                        config={{
                          vimeo: {
                            playerOptions: {
                              responsive: true,
                            },
                          },
                        }}
                      />
                    </div>
                  )
                })}
              </nav>
            </div>
          </div>
        </section>
      </Page>
    </>
  )
}

export const query = graphql`
  query DirectorsPageQuery($id: String) {
    prismicDirectorsPage(id: { eq: $id }) {
      ...DirectorsPage
    }
  }
`

export default withPrismicPreview(DirectorsPage)
