import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import * as yup from 'yup'
import { InferType } from 'yup'
import { Form } from '@superrb/gatsby-addons/components'
import { FieldError } from 'react-hook-form'
import ErrorMessage, {
  ErrorMessageProps,
} from '@superrb/gatsby-addons/src/components/form/error-message'
import { OptionalObjectSchema } from 'yup/lib/object'

const NATURE_OF_ENQUIRY_OPTIONS = [
  'General Enquiry',
  'Sales Enquiry',
  'Support Enquiry',
]

let schema = yup.object({
  name: yup.string().label('Name').meta({ placeholder: 'Name*' }).required(),
  email: yup.string().email().label('Email address').meta({ placeholder: 'Email*' }).required(),
  phone: yup.string().label('Phone number').meta({ placeholder: 'Phone number' }),
  natureOfEnquiry: yup
    .string()
    .oneOf(NATURE_OF_ENQUIRY_OPTIONS)
    .required()
    .default('Nature of inquiry'),
  message: yup.string().meta({ textarea: true, placeholder: 'Message*' }).label('Message').required(),
})

const SuccessMessage = ({ data }) => {
  const element = useRef<HTMLElement>() as MutableRefObject<HTMLElement>

  useEffect(() => {
    if (element?.current) {
      element.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [element?.current])

  return (
    <div className="success-message" ref={element}>
      <h2 className="success-message__title success-message__title--custom">
        Thank you!
      </h2>
      <p className="success-message__text">We will be in contact soon.</p>
    </div>
  )
}

const SubmitErrorMessage = ({ error, fieldSchema }: ErrorMessageProps) => {
  const element = useRef<HTMLElement>() as MutableRefObject<HTMLElement>

  useEffect(() => {
    if (element?.current) {
      element.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [element?.current])

  if (fieldSchema) {
    return <ErrorMessage error={error} fieldSchema={fieldSchema} />
  }

  return (
    <div className="error-message" ref={element}>
      <h2 className="error-message__title">Uh Oh! Something went wrong</h2>
      <ErrorMessage error={error} />
    </div>
  )
}

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  return (
    <>
      {executeRecaptcha && (
        <div className={'contact-page__form-wrap'}>
          <Form
            action="/.netlify/functions/contact"
            name="contact-form"
            schema={schema}
            executeRecaptcha={executeRecaptcha}
            renderSuccessMessage={(data) => <SuccessMessage data={data} />}
            renderErrorMessage={(error, fieldSchema) => (
              <SubmitErrorMessage error={error} fieldSchema={fieldSchema} />
            )}
            renderers={{
              email: (
                props,
                error?: FieldError,
                fieldSchema?: OptionalObjectSchema<any>,
              ) => {
                return (
                  <>
                    <input
                      type="email"
                      className="form__control"
                      id="contact-form__email"
                      placeholder={'Email*'}
                      required
                      {...props}
                    />
                    {error && (
                      <ErrorMessage error={error} fieldSchema={fieldSchema} />
                    )}
                  </>
                )
              },
            }}
          />
          <p className="contact-page__recaptcha">
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy" target="_blank">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="https://policies.google.com/terms" target="_blank">
              Terms of Service
            </a>{' '}
            apply.
          </p>
        </div>
      )}
    </>
  )
}

export default ContactForm
