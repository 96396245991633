import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { useStaticQuery, graphql } from 'gatsby'
import ButtonRounded from '../components/buttons/button-rounded'
import { BlogDetailIndexBlockType } from '../types/pages/blog-detail-page'
import { linkResolver } from '../utils/linkResolver'
import NewsArticleItem from '../components/news-article-item'
import { useIsMobile, useIsInViewport } from '@superrb/gatsby-addons/hooks'

export const LatestNews = ({
  slice,
  context,
}: SliceComponentProps<any, any>) => {
  const isMobile = useIsMobile(true, '45em')
  const { isInViewport, setRef } = useIsInViewport(false)

  const data = {
    intro_text: slice.primary.sub_text,
    link: slice.primary.link,
  }

  const newsArticles = useStaticQuery(graphql`
    query NewsTagsQuery {
      newsTags: allPrismicBlogDetailPage(
        sort: { data: { date: DESC } }
        limit: 1000
      ) {
        nodes {
          tags
          ...BlogDetailPage
        }
      }
    }
  `)

  const [availableNewsArticles, setAvailableNewsArticles] = useState([])

  useEffect(() => {
    const articles = newsArticles.newsTags.nodes
    const sortedArticles = []

    let taggedItems = { tag: 'All', items: [] }
    let count = 0

    articles.forEach((article) => {
      taggedItems.items.push(article)
      count++
      if (count >= 4) {
        return
      }
    })
    sortedArticles.push(taggedItems)

    context.newsTags.forEach((tag) => {
      let taggedItems = { tag: tag, items: [] }
      let count = 0

      articles.forEach((article) => {
        if (article.tags.includes(tag)) {
          taggedItems.items.push(article)
          count++
        }

        if (count >= 4) {
          return
        }
      })
      sortedArticles.push(taggedItems)
    })
    setAvailableNewsArticles(sortedArticles)
  }, [newsArticles, context, setAvailableNewsArticles])

  const filterOption = (e) => {
    if (e.target.classList.contains('latest-news__filter--active')) {
      return
    }

    document
      .querySelector('.latest-news__filter--active')
      .classList.remove('latest-news__filter--active')
    e.target.classList.add('latest-news__filter--active')

    let category = e.target.getAttribute('data-category')
    document
      .querySelector('.latest-news__listing--active')
      .classList.remove('latest-news__listing--active')
    document
      .querySelector('.latest-news__listing[data-category="' + category + '"]')
      .classList.add('latest-news__listing--active')
  }

  return (
    <section className={`latest-news`} aria-label="Latest News" ref={setRef} data-header-modifier={'dark'}>
      <div className="latest-news__container container">
        <div
          className={`latest-news__header animate-on-scroll ${
            isInViewport ? 'animated' : ''
          }`}
        >
          <h2 className="latest-news__title">News & Insights</h2>
          {data.intro_text ? (
            <p className="latest-news__text">{data.intro_text}</p>
          ) : (
            ''
          )}
          {data.link && !isMobile ? (
            <div className="latest-news__cta">
              <ButtonRounded
                text="View all news"
                link={linkResolver(data.link)}
                style={'white'}
                arrow={true}
                type={'button'}
                styles={[]}
                className={''}
                onClick={() => {}}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div
          className={`latest-news__inner animate-on-scroll ${
            isInViewport ? 'animated' : ''
          }`}
        >
          <ul className="latest-news__filters">
            {availableNewsArticles.map((group, index) => {
              return (
                <li
                  data-category={group.tag}
                  onClick={filterOption}
                  className={index === 0 ? 'latest-news__filter--active' : ''}
                  key={`news-filter-item-${index}`}
                >
                  {group.tag}
                </li>
              )
            })}
          </ul>
          <div className="latest-news__listings">
            {availableNewsArticles.map((group, index) => {
              return (
                <div
                  className={`latest-news__listing ${
                    index === 0 ? 'latest-news__listing--active' : ''
                  }`}
                  data-category={group.tag}
                  key={`available-news-item-${index}`}
                >
                  {group.items.map((caseStudyItem, index) => {
                    if (!caseStudyItem || index >= 4) {
                      return <></>
                    }
                    const indexBlock: BlogDetailIndexBlockType = {
                      image: caseStudyItem.data.listing_image,
                      title: caseStudyItem.data.listing_title,
                      excerpt: caseStudyItem.data.listing_excerpt,
                      date: caseStudyItem.data.date,
                      type: caseStudyItem.data.listing_article_type,
                      link: linkResolver(caseStudyItem),
                    }
                    return (
                      <NewsArticleItem
                        featured={false}
                        indexBlock={indexBlock}
                        key={index}
                      />
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyLatestNews on PrismicHomepageDataBodyLatestNews {
    primary {
      sub_text
      link {
        ...Link
      }
    }
  }
`
