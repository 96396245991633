import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image } from '@superrb/gatsby-addons/components'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

export const ClientLogos = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    slow_down: slice.primary.slow_down_carousel,
    remove_padding: slice.primary.remove_bottom_spacing
  }

  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <>
      <section className="light-theme__cap light-theme__cap--no-height"></section>
      <section className={`client-logos ${data.remove_padding ? 'client-logos--no-padding' : ''}`} aria-label="Image Swiper" data-header-modifier={'dark'}>
        <div className="client-logos__container container">
          <div className="client-logos__inner" ref={setRef}>
            {data.title ?
              <div
                className={`client-logos__text animate-on-scroll ${
                  isInViewport ? 'animated' : ''
                }`}
              >
                <h2>{data.title}</h2>
              </div>
            : ''}
            <div
              className={`client-logos__swiper animate-on-scroll ${
                isInViewport ? 'animated' : ''
              }`}
            >
              <div className={`client-logos__swiper-inner ${data.slow_down ? 'client-logos__swiper-inner--slow' : ''}`}>
                {slice.items
                  ? slice.items.map((slide, index) => (
                      <div
                        className="client-logos__slide"
                        key={`client-logos-slide-${index}`}
                      >
                        <Image image={slide.client_logo} />
                      </div>
                    ))
                  : ''}
              </div>
              <div className={`client-logos__swiper-inner ${data.slow_down ? 'client-logos__swiper-inner--slow' : ''}`}>
                {slice.items
                  ? slice.items.map((slide, index) => (
                      <div
                        className="client-logos__slide"
                        key={`client-logos-slide2-${index}`}
                      >
                        <Image image={slide.client_logo} />
                      </div>
                    ))
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export const query = graphql`
  fragment StudioPageDataBodyClientLogos on PrismicStudioPageDataBodyClientLogos {
    primary {
      title
      slow_down_carousel
      remove_bottom_spacing
    }
    items {
      client_logo {
        ...Image
      }
    }
  }
`
