import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { useStaticQuery, graphql } from 'gatsby'
import { RichText } from '@superrb/gatsby-addons/components'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

export const CreativeCapabilities = ({
  slice,
  context,
}: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    description: slice.primary.sub_text,
  }

  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section
      className={`creative-capabilities`}
      aria-label="Creative Capabilities"
      data-header-modifier={'dark'}
    >
      <div className="creative-capabilities__container container" ref={setRef}>
        <div
          className={`creative-capabilities__header animate-on-scroll ${
            isInViewport ? 'animated' : ''
          }`}
        >
          {data.title ? (
            <h2 className="creative-capabilities__title">{data.title}</h2>
          ) : (
            ''
          )}
          {data.description ? (
            <p className="creative-capabilities__text">{data.description}</p>
          ) : (
            ''
          )}
        </div>
        <div className="creative-capabilities__inner">
          <div className="creative-capabilities__listings">
            <div className={`creative-capabilities__listing`}>
              {slice.items &&
                slice.items.map((item, index) => {
                  return (
                    <div
                      className={`creative-capabilities__item animate-on-scroll ${
                        isInViewport ? 'animated' : ''
                      }`}
                      key={`creative-capabilities-item-${index}`}
                    >
                      {item.number ? (
                        <p className="creative-capabilities__item-number">
                          ({item.number})
                        </p>
                      ) : (
                        ''
                      )}
                      {item.name ? (
                        <h5 className="creative-capabilities__item-title">
                          {item.name}
                        </h5>
                      ) : (
                        ''
                      )}
                      {item.list ? <RichText field={item.list.richText} /> : ''}
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment StudioPageDataBodyCreativeCapabilities on PrismicStudioPageDataBodyCreativeCapabilities {
    primary {
      title
      sub_text
    }
    items {
      number
      name
      list {
        ...RichText
      }
    }
  }
`
