import React, { useCallback, useContext, useEffect, useState } from 'react'
import { gsap } from 'gsap'

interface props {
  title: string
  text: string
  light_theme: boolean
}

const splitTitle = (title) => {
  const letters = title.split('')
  let titleEl = document.querySelector('.page-title__title')

  for (var i = 0; i < letters.length; i++) {
    if (letters[i] === ' ') {
      titleEl.innerHTML += ' '
    } else {
      titleEl.innerHTML += '<span>' + letters[i] + '</span>'
    }
  }
}

export const PageTitle = ({ title, text, light_theme }: Props) => {
  let once = true

  useEffect(() => {
    if (!once) {
      return
    }

    splitTitle(title)

    gsap.from('.page-title__title span', {
      duration: 0.25,
      delay: 0.1,
      y: -150,
      stagger: 0.05,
      ease: 'expo',
    })

    gsap.from('.page-title__title', {
      duration: 1,
      delay: 1,
      scale: 2,
      left: '50%',
      x: '-50%',
      y: '100%',
      ease: 'expo',
    })

    gsap.from('.page-title__body', {
      duration: 0.8,
      delay: 1.7,
      opacity: 0,
      y: 150,
      ease: 'power3',
    })
    once = false
  }, [once])
  return (
    <section
      className={`page-title ${light_theme === true ? 'light-theme' : ''}`}
      aria-label="page-title"
      data-header-modifier={light_theme ? 'light' : 'dark'}
    >
      <div className="page-title__container container">
        <div className="page-title__inner">
          <div className="page-title__text">
            {title && <h1 className="page-title__title"></h1>}
            {text && <p className="page-title__body">{text}</p>}
          </div>
        </div>
      </div>
    </section>
  )
}
