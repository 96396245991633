import React from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { RichText } from '@superrb/gatsby-addons/components'

export const ArticleBody = ({ slice }: SliceComponentProps<any, any>) => {
  return (
    <section
      className="article-body light-theme"
      data-header-modifier={'light'}
    >
      <div className="article-body__container container">
        <div className="article-body__inner">
          <div className="article-body__col">
            {slice.primary.subheading ? (
              <p className="article-body__subtitle">
                {slice.primary.subheading}
              </p>
            ) : (
              ''
            )}
            {slice.primary.body1 ? (
              <div
                className="article-body__text"
                style={{
                  fontSize: `${slice.primary.body_font_size || '14'}px`,
                }}
              >
                <RichText field={slice.primary.body1.richText} />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment BlogDetailPageDataBodyArticleBody on PrismicBlogDetailPageDataBodyArticleBody {
    primary {
      subheading
      body1 {
        ...RichText
      }
    }
  }

  fragment LegalPageDataBodyArticleBody on PrismicLegalPageDataBodyArticleBody {
    primary {
      subheading
      body1 {
        ...RichText
      }
    }
  }

  fragment WorkDetailPageDataBodyArticleBody on PrismicWorkDetailPageDataBodyArticleBody {
    primary {
      subheading
      body1 {
        ...RichText
      }
      body_font_size
    }
  }
`
