import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import { RichText as RichTextType } from '@superrb/gatsby-addons/types'
import {useIsInViewport, useMotionAllowed} from '@superrb/gatsby-addons/hooks'
import ReactPlayer from "react-player";
import {CookiesContext} from "../context/cookies-context";
import checkVideoUrl from "../utils/checkVideoUrl";

interface Props extends Record<string, any> {
  description: RichTextType
  make_text_wider_than_image: boolean
}

export const TwoColumnContentLargeImage = ({
  slice,
  context
}: SliceComponentProps<any, any>) => {
  const data = {
    layout: slice?.primary?.layout,
    wide_text: slice?.primary?.make_text_wider_than_image,
    title: slice?.primary?.heading,
    sub_title: slice?.primary?.sub_heading,
    description: slice?.primary?.description,
    image: slice?.primary?.image,
    video: slice?.primary?.video_link?.url
  }

  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const { isInViewport, setRef } = useIsInViewport(false)
  const isMotionAllowed = useMotionAllowed()

    useEffect(() => {
        if(data.video) {
            setVideoIsEmbedCode(checkVideoUrl(data.video))
        }
    }, [data.video])

  return (
    <section className="two-column-content two-column-content-large-image light-theme" data-header-modifier={context.darkTheme ? 'dark' : 'light'}>
      <div className="two-column-content__container container">
        <div className="two-column-content__inner" ref={setRef}>
          <div
            className={`two-column-content__col two-column-content__col--image 
                    ${
                      data.layout === 'right'
                        ? 'two-column-content__col--first'
                        : ''
                    }
                    ${
                      data.wide_text === true
                        ? 'two-column-content__col--image-small'
                        : ''
                    }
                    animate-on-scroll ${isInViewport ? 'animated' : ''}
                    `}
          >
            {data.image && !data.video ? <Image image={data.image} /> : ''}
              {(cookiesAccepted || !videoIsEmbedCode) && data?.video ? (
                  <ReactPlayer
                      url={data.video}
                      muted={true}
                      playsinline={true}
                      playing={isMotionAllowed}
                      autoPlay={isMotionAllowed}
                      loop={true}
                      className="two-column-content__video"
                      light={false}
                      config={{
                          vimeo: {
                              playerOptions: {
                                  responsive: true,
                              },
                          },
                      }}
                  />
              ) : ''}
          </div>
          <div
            className={`two-column-content__col two-column-content__col--text 
                    ${
                      data.layout === 'left'
                        ? 'two-column-content__col--first'
                        : ''
                    }
                    ${
                      data.wide_text === true
                        ? 'two-column-content__col--text-large'
                        : ''
                    }
                    animate-on-scroll ${isInViewport ? 'animated' : ''}
                    `}
          >
            <div className="two-column-content__text-wrap">
              {data.title ? (
                <h2 className="two-column-content__title">{data.title}</h2>
              ) : (
                ''
              )}
              {data.sub_title ? (
                <h3 className="two-column-content__sub-title">
                  {data.sub_title}
                </h3>
              ) : (
                ''
              )}
              {data.description ? (
                <div className="two-column-content__text">
                  <RichText field={data.description.richText} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CareersPageDataBodyTwoColumnContentLargeImage on PrismicCareersPageDataBodyTwoColumnContentLargeImage {
    primary {
      layout
      make_text_wider_than_image
      heading
      sub_heading
      description {
        ...RichText
      }
      image {
        ...Image
      }
      video_link {
        ...Link
      }
    }
  }

  fragment StudioPageDataBodyTwoColumnContentLargeImage on PrismicStudioPageDataBodyTwoColumnContentLargeImage {
    primary {
      layout
      make_text_wider_than_image
      heading
      sub_heading
      description {
        ...RichText
      }
      image {
        ...Image
      }
      video_link {
        ...Link
      }
    }
  }
`
