import React, { useCallback, useContext, useEffect, useState } from 'react'
import { WorkDetailIndexBlockType } from '../types/pages/work-detail-page'
import { linkResolver } from '../utils/linkResolver'
import CaseStudyItem from './case-study-item'
import ButtonRounded from './buttons/button-rounded'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

interface props {
  title1: string
  title2: string
  text: string
  link: string
  items: object
  theme: string
}

export const FeaturedCaseStudySection = ({
  title1,
  title2,
  text,
  link,
  items,
  theme,
}: Props) => {
  const { isInViewport, setRef } = useIsInViewport(false)

  if (items[0]?.work_page?.document === null) {
    return <></>
  }
  return (
    <>
      <section
        className={
          'light-theme__cap light-theme__cap--no-height featured-case-studies__light-theme-cap'
        }
      ></section>
      <section
        className={`featured-case-studies ${
          theme === 'white' ? 'light-theme' : ''
        }`}
        aria-label="Featured Case Studies"
        ref={setRef}
        data-header-modifier={`${theme === 'white' ? 'light' : 'dark'}`}
      >
        <div className="featured-case-studies__container container">
          <div
            className={`featured-case-studies__header animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            <h2 className="featured-case-studies__title">
              {title1 ? title1 : 'Related'} <span>{title2 ? title2 : 'Work'}</span>
            </h2>
            {text ? <p className="featured-case-studies__text">{text}</p> : ''}
          </div>
          {items ? (
            <div
              className={`featured-case-studies__inner animate-on-scroll ${
                isInViewport ? 'animated' : ''
              }`}
            >
              {items.map((caseStudyItem, index) => {
                if (!caseStudyItem.work_page.document) {
                  return <></>
                }
                const indexBlock: WorkDetailIndexBlockType = {
                  image: caseStudyItem.work_page?.document?.data?.listing_image,
                  video:
                    caseStudyItem.work_page?.document?.data?.listing_video?.url,
                  title: caseStudyItem.work_page?.document?.data?.listing_title,
                  client:
                    caseStudyItem.work_page?.document?.data?.listing_client,
                  excerpt:
                    caseStudyItem.work_page?.document?.data?.listing_excerpt,
                  link: linkResolver(caseStudyItem.work_page?.document),
                }
                return <CaseStudyItem indexBlock={indexBlock} key={index} />
              })}
              {link ? (
                <div className="featured-case-studies__cta">
                  <ButtonRounded
                    text="View all work"
                    link={linkResolver(link)}
                    style={`${theme === 'white' ? 'black' : 'white'}`}
                    arrow={true}
                    type={'button'}
                    styles={[]}
                    className={''}
                    onClick={() => {}}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </section>
    </>
  )
}
