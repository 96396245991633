import React, { useState } from 'react'
import { Image } from '@superrb/gatsby-addons/components'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { Link } from 'gatsby'
import { BlogDetailIndexBlockType } from '../types/pages/blog-detail-page'

interface Props {
  indexBlock: BlogDetailIndexBlockType
  featured: boolean
}

const NewsArticleItem = ({ indexBlock, featured }: Props) => {
  const isMobile = useIsMobile(true, '45em')

  const dateFormat = (date) => {
    let newDate = date.split('-')
    return newDate[2] + '.' + newDate[1] + '.' + newDate[0]
  }
  return (
    <>
      {indexBlock.link && (
        <div
          className={`news-article__item ${
            featured ? 'news-article__item--featured' : ''
          }`}
        >
          <div className="news-article__item--image">
            {indexBlock.image && (
              <Link to={indexBlock.link} aria-label={indexBlock.title}>
                <Image
                  image={indexBlock.image}
                  className={'news-article__image'}
                />
              </Link>
            )}
          </div>
          {featured ? (
            <div className="news-article__item--featured-text">
              <div className="news-article__item--header">
                <div className="news-article__item--title">
                  <p>Featured post</p>
                  {indexBlock.title && (
                    <Link to={indexBlock.link} aria-label={indexBlock.title}>
                      <h6>{indexBlock.title}</h6>
                    </Link>
                  )}
                  {indexBlock.excerpt && <p>{indexBlock.excerpt}</p>}
                </div>
              </div>
              <div className="news-article__item--footer">
                {indexBlock.title && (
                  <Link to={indexBlock.link} aria-label={indexBlock.title}>
                    {indexBlock.date ? (
                      <p className="news-article__item--date">
                        {dateFormat(indexBlock.date)}
                      </p>
                    ) : (
                      ''
                    )}
                    {indexBlock.date && indexBlock.type ? (
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.48528 0C3.48528 1.88259 5.04447 3.4181 7 3.50058C5.05798 3.59708 3.51472 5.12687 3.51472 7C3.51472 5.11741 1.95554 3.5819 0 3.49942C1.94201 3.40321 3.48528 1.87314 3.48528 0Z"
                          fill="#F8F8F8"
                        />
                      </svg>
                    ) : (
                      ''
                    )}
                    {indexBlock.type ? (
                      <p className="news-article__item--tag">
                        {indexBlock.type}
                      </p>
                    ) : (
                      ''
                    )}
                  </Link>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="news-article__item--header">
                <div className="news-article__item--title">
                  {indexBlock.title && (
                    <Link to={indexBlock.link} aria-label={indexBlock.title}>
                      <h6>{indexBlock.title}</h6>
                    </Link>
                  )}
                  {indexBlock.excerpt && (
                    <Link to={indexBlock.link} aria-label={indexBlock.title}>
                      <p className="news-article__item--excerpt">
                        {indexBlock.excerpt}
                      </p>
                    </Link>
                  )}
                </div>
              </div>
              <div className="news-article__item--footer">
                {indexBlock.title && (
                  <Link to={indexBlock.link} aria-label={indexBlock.title}>
                    {indexBlock.date ? (
                      <p className="news-article__item--date">
                        {dateFormat(indexBlock.date)}
                      </p>
                    ) : (
                      ''
                    )}
                    {indexBlock.date && indexBlock.type ? (
                      <svg
                        width="7"
                        height="7"
                        viewBox="0 0 7 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.48528 0C3.48528 1.88259 5.04447 3.4181 7 3.50058C5.05798 3.59708 3.51472 5.12687 3.51472 7C3.51472 5.11741 1.95554 3.5819 0 3.49942C1.94201 3.40321 3.48528 1.87314 3.48528 0Z"
                          fill="#F8F8F8"
                        />
                      </svg>
                    ) : (
                      ''
                    )}
                    {indexBlock.type ? (
                      <p className="news-article__item--tag">
                        {indexBlock.type}
                      </p>
                    ) : (
                      ''
                    )}
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default NewsArticleItem
