import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import * as yup from 'yup'
import { Form } from '@superrb/gatsby-addons/components'
import FormField from '@superrb/gatsby-addons/src/components/form/field'
import { FieldError } from 'react-hook-form'
import ErrorMessage from '@superrb/gatsby-addons/src/components/form/error-message'
import { InferType } from 'yup'

const maxFileSize = process.env.CONTACT_MESSAGE_MAX_FILE_SIZE || 5242880
const fileTypes = '.doc,.docx,.pdf'

const validFileExtensions = { document: ['doc', 'docx', 'pdf'] }

function isValidFileType(fileName: string, fileType: string) {
  return (
    fileName &&
    validFileExtensions[fileType].indexOf(fileName.split('.').pop()) > -1
  )
}

let Schema = yup.object({
  name: yup.string().label('Name').meta({ placeholder: 'Name*' }).required(),
  email: yup.string().email().label('Email address').meta({ placeholder: 'Email*' }).required(),
  pronouns: yup.string().label('Pronouns').meta({ placeholder: 'Pronouns' }),
  phone: yup.string().label('Phone number').meta({ placeholder: 'Phone number' }),
  locationName: yup.string().label('Location').meta({ placeholder: 'Location' }).required(),
  portfolio: yup.string().label('Portfolio Website/URL').meta({ placeholder: 'Portfolio Website/URL' }),
  file: yup.mixed().test({
    message: `File too big or wrong type, please choose another file.`,
    test: (file) => {
      if (file[0] === undefined) return true
      if (!isValidFileType(file[0]?.name.toLowerCase(), 'document'))
        return false
      return file[0]?.size < Number(maxFileSize)
    },
  }),
  message: yup
    .string()
    .meta({ textarea: true, placeholder: 'Tell us why you are interested' })
    .label('Tell us why you are interested')
    .required(),
})

const CareersContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const url = typeof window !== 'undefined' ? window.location.href : ''

  Schema = Schema.concat(
    yup.object({
      url: yup
        .string()
        .label('URL')
        .required()
        .meta({ hidden: true })
        .default(url),
    }),
  )

  const SuccessMessage = (data: InferType<typeof schema>) => {
    const element = useRef<HTMLElement>() as MutableRefObject<HTMLElement>

    useEffect(() => {
      if (element?.current) {
        element.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, [element?.current])
    return (
      <div className="success-message" ref={element}>
        <h2 className="success-message__title success-message__title--custom">
          Thank you!
        </h2>
        <p className="success-message__text">We will be in contact soon.</p>
      </div>
    )
  }

  const SubmitErrorMessage = (error: FieldError) => {
    const element = useRef<HTMLElement>() as MutableRefObject<HTMLElement>

    useEffect(() => {
      if (element?.current) {
        element.current.scrollIntoView({ behavior: 'smooth' })
      }
    }, [element?.current])
    return (
      <div className="error-message" ref={element}>
        <h2 className="error-message__title">Uh Oh! Something went wrong</h2>
        <ErrorMessage error={error} />
      </div>
    )
  }

  return (
    <>
      {executeRecaptcha && (
        <>
          <Form
            action="/.netlify/functions/contact-careers"
            name="contact-form"
            schema={Schema}
            executeRecaptcha={executeRecaptcha}
            encType="multipart/form-data"
            renderSuccessMessage={(data) => <SuccessMessage data={data} />}
            renderErrorMessage={(error) => <SubmitErrorMessage error={error} />}
            renderers={{
              file: (props, errors: FieldError) => {
                return (
                  <>
                    <label className="file__placeholder-wrap" htmlFor="file">
                      <div className="file__placeholder">
                        <span className="file__placeholder--text">
                          Resume Upload
                        </span>
                      </div>
                      <p>Accepted format .doc .docx .pdf 5mb or less</p>
                    </label>
                    <input
                      type="file"
                      id="file"
                      accept={fileTypes}
                      {...props}
                      required
                      onChange={(e) => {
                        e.target
                          .closest('.form__group')
                          .querySelector('.file__placeholder--text').innerHTML =
                          e.target.files[0].name
                      }}
                    />
                    {errors && <ErrorMessage error={errors} />}
                  </>
                )
              },
            }}
          />
          <p className="careers-detail__recaptcha">
            This site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy" target="_blank">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a href="https://policies.google.com/terms" target="_blank">
              Terms of Service
            </a>{' '}
            apply.
          </p>
        </>
      )}
    </>
  )
}

export default CareersContactForm
