import React, { useContext, useEffect, useState } from 'react'
import { Image } from '@superrb/gatsby-addons/components'
import ReactPlayer from 'react-player/lazy'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import { WorkDetailIndexBlockType } from '../types/pages/work-detail-page'
import { Link } from 'gatsby'
import { CookiesContext } from '../context/cookies-context'
import checkVideoUrl from '../utils/checkVideoUrl'

interface Props {
  indexBlock: WorkDetailIndexBlockType
}

const CaseStudyItem = ({ indexBlock }: Props) => {
  const { cookiesAccepted } = useContext(CookiesContext)
  const isMobile = useIsMobile(true, '45em')
  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const [videoPlaying, setVideoPlaying] = useState(false)

  useEffect(() => {
    setVideoIsEmbedCode(checkVideoUrl(indexBlock.video))
  }, [indexBlock.video])

  return (
    <>
      {indexBlock.link && (
        <div
          className="featured-case-studies__item"
          onMouseEnter={() => {
            if (!isMobile) {
              setVideoPlaying(true)
            }
          }}
          onMouseLeave={() => {
            if (!isMobile) {
              setVideoPlaying(false)
            }
          }}
        >
          <div className="featured-case-studies__item--image">
            {indexBlock.image && (
              <Link to={indexBlock.link} aria-label={indexBlock.title}>
                <Image
                  image={indexBlock.image}
                  className={'featured-case-studies__image'}
                />
              </Link>
            )}
            {(cookiesAccepted || !videoIsEmbedCode) && indexBlock.video && (
              <ReactPlayer
                url={indexBlock.video}
                muted={true}
                playsinline={true}
                playing={videoPlaying}
                autoPlay={false}
                loop={true}
                className="featured-case-studies__item--video"
                light={false}
                config={{
                  vimeo: {
                    playerOptions: {
                      responsive: true,
                    },
                  },
                }}
              />
            )}
          </div>
          <div className="featured-case-studies__item--header">
            <div className="featured-case-studies__item--client">
              {indexBlock.client && (
                <Link to={indexBlock.link} aria-label={indexBlock.client}>
                  <h6>{indexBlock.client}</h6>
                </Link>
              )}
            </div>
            <div className="featured-case-studies__item--title">
              {indexBlock.title && (
                <Link to={indexBlock.link} aria-label={indexBlock.title}>
                  <h6>{indexBlock.title}</h6>
                </Link>
              )}
            </div>
          </div>
          <div className="featured-case-studies__item--excerpt">
            {indexBlock.excerpt && (
              <Link to={indexBlock.link} aria-label={indexBlock.title}>
                <p>{indexBlock.excerpt}</p>
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default CaseStudyItem
