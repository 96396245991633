import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import Twitter from './icons/twitter'
import Linkedin from './icons/linkedin'
import Instagram from './icons/instagram'
import { Link } from 'gatsby'
import { useIsInViewport, useMotionAllowed } from '@superrb/gatsby-addons/hooks'
import ReactPlayer from 'react-player'
import { CookiesContext } from '../context/cookies-context'
import checkVideoUrl from '../utils/checkVideoUrl'
import { VideoPopup } from '../components/video-popup'

interface props {
  image: Image
  title: string
  video: string
  teaser_video: string
  job_role: string
  text: RichText
  swap_title: boolean
  instagram: string
  linkedin: string
  twitter: string
}

export const CollectiveDetailTitle = ({
  image,
  title,
  video,
  teaser_video,
  job_role,
  text,
  swap_title,
  instagram,
  linkedin,
  twitter,
}: Props) => {
  const { isInViewport, setRef } = useIsInViewport(false)
  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const isMotionAllowed = useMotionAllowed()
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [mousePos, setMousePos] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })

  return (
    <section
      className="collective-detail-title light-theme"
      data-header-modifier={'light'}
    >
      <div className="collective-detail-title__container container">
        <div
          className="collective-detail-title__inner"
          ref={setRef}
          data-popup={100}
        >
          <div
            className={`collective-detail-title__col collective-detail-title__col--image animate-on-scroll ${
              isInViewport ? 'animated' : ''
            } ${swap_title ? 'collective-detail-title__col--reverse-0' : ''}`}
            onClick={(e) => {
              setPopupOpen(true)
            }}
          >
            {(cookiesAccepted || !videoIsEmbedCode) && teaser_video ? (
              <ReactPlayer
                url={teaser_video}
                muted={true}
                playsinline={true}
                playing={isMotionAllowed}
                autoPlay={isMotionAllowed}
                loop={true}
                className="collective-detail-title__video"
                light={false}
                config={{
                  vimeo: {
                    playerOptions: {
                      responsive: true,
                    },
                  },
                }}
              />
            ) : image ? (
              <Image image={image} />
            ) : (
              ''
            )}
          </div>
          <div
            className={`collective-detail-title__col collective-detail-title__col--text two-column-content__col--first animate-on-scroll ${
              isInViewport ? 'animated' : ''
            } ${swap_title ? 'collective-detail-title__col--reverse-1' : ''}`}
          >
            <div className="collective-detail-title__text-wrap">
              {title ? (
                <h1 className="collective-detail-title__title">{title}</h1>
              ) : (
                ''
              )}
              {job_role ? (
                <p className="collective-detail-title__subtitle">{job_role}</p>
              ) : (
                ''
              )}
              {text ? (
                <div className="collective-detail-title__text">
                  <RichText field={text.richText} />
                </div>
              ) : (
                ''
              )}
              <div className="collective-detail-title__social">
                {instagram && (
                  <Link to={instagram.url} target={instagram.target}>
                    <Instagram />
                  </Link>
                )}
                {linkedin && (
                  <Link to={linkedin.url} target={linkedin.target}>
                    <Linkedin />
                  </Link>
                )}
                {twitter && (
                  <Link to={twitter.url} target={twitter.target}>
                    <Twitter />
                  </Link>
                )}
              </div>
            </div>
          </div>
          {(cookiesAccepted || !checkVideoUrl(video)) && video ? (
            <VideoPopup
              video={video}
              isOpen={popupOpen}
              setIsOpen={setPopupOpen}
              popupid={100}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  )
}
