import { SliceComponentProps, SliceComponentType } from '@prismicio/react'
import { FunctionComponent } from 'react'
import { FullWidthImage } from './full-width-image'
import { ImageGallery } from './image-gallery'
import { ImageHighlight } from './image-highlight'
import { Quote } from './quote'
import { Text } from './text'
import { TitleBlock } from './title-block'
import { PlayReel } from './play-reel'
import { MediaGallery } from './media-gallery'
import { ProjectOverview } from './project-overview'
import { Ticker } from './ticker'
import { AboutUsOverview } from './about-us-overview'
import { CareersListing } from './careers-listing'
import { FeaturedCaseStudies } from './featured-case-studies'
import { VideoBanner } from './video-banner'
import { LatestNews } from './latest-news'
import { HeroVideo } from './hero-video'
import { FullWidthVideo } from './full-width-video'
import { CenterTitle } from './center-title'
import { FeaturedVideo } from './featured-video'
import { CenterQuote } from './center-quote'
import { TwoColumnContent } from './two-column-content'
import { SelectedWorks } from './selected-works'
import { CareersHeader } from './careers-header'
import { TwoColumnContentLargeImage } from './two-column-content-large-image'
import { ScrollingTextFixedImage } from './scrolling-text-fixed-image'
import { ImageSwiper } from './image-swiper'
import { TwoColumnText } from './two-column-text'
import { ClientLogos } from './client_logos'
import { CreativeCapabilities } from './creative-capabilities'
import { CollectiveSwiper } from './collective-swiper'
import { ArticleBody } from './article-body'
import { Spacer } from './spacer'
import { MeetTheTeam } from './meet-the-team'
import { SocialEmbed } from './social-embed'

export const components: Record<string, SliceComponentType<any, any>> = {
  full_width_image: FullWidthImage,
  image_gallery: ImageGallery,
  image_highlight: ImageHighlight,
  quote: Quote,
  text: Text,
  title_block: TitleBlock,
  play_reel: PlayReel as FunctionComponent<SliceComponentProps<any, any>>,
  media_gallery: MediaGallery as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  ticker: Ticker as FunctionComponent<SliceComponentProps<any, any>>,
  project_overview: ProjectOverview as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  about_us_overview: AboutUsOverview as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  featured_case_studies: FeaturedCaseStudies as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  careers_listing: CareersListing as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  video_banner: VideoBanner as FunctionComponent<SliceComponentProps<any, any>>,
  latest_news: LatestNews as FunctionComponent<SliceComponentProps<any, any>>,
  hero_video: HeroVideo as FunctionComponent<SliceComponentProps<any, any>>,
  'full-width_video': FullWidthVideo as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  center_title: CenterTitle as FunctionComponent<SliceComponentProps<any, any>>,
  featured_video: FeaturedVideo as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  center_quote: CenterQuote as FunctionComponent<SliceComponentProps<any, any>>,
  two_column_content: TwoColumnContent as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  selected_works: SelectedWorks as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  careers_header: CareersHeader as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  two_column_content_large_image:
    TwoColumnContentLargeImage as FunctionComponent<
      SliceComponentProps<any, any>
    >,
  scrolling_text_fixed_image: ScrollingTextFixedImage as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  image_swiper: ImageSwiper as FunctionComponent<SliceComponentProps<any, any>>,
  two_column_text: TwoColumnText as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  client_logos: ClientLogos as FunctionComponent<SliceComponentProps<any, any>>,
  creative_capabilities: CreativeCapabilities as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  collective_swiper: CollectiveSwiper as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  article_body: ArticleBody as FunctionComponent<SliceComponentProps<any, any>>,
  spacer: Spacer as FunctionComponent<SliceComponentProps<any, any>>,
  meet_the_team: MeetTheTeam as FunctionComponent<
    SliceComponentProps<any, any>
  >,
  social_embed: SocialEmbed as FunctionComponent<SliceComponentProps<any, any>>,
}
