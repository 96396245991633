import React, { SVGProps } from 'react'

const PlayIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      className="play-icon"
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26.5" cy="26.5" r="26" fill="#F8F8F8" stroke="#030303" />
      <circle
        cx="26.5"
        cy="26.5"
        r="26"
        fill="#FDCF50"
        stroke="#FDCF50"
        className="play-icon__circle"
      />
      <path d="M35 27.5L22.25 34.8612L22.25 20.1388L35 27.5Z" fill="#030303" />
    </svg>
  )
}

export default PlayIcon
