import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import {useIsMobile, useMotionAllowed} from '@superrb/gatsby-addons/hooks'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import PlayCursor from '../components/icons/play-cursor'
import Sound from '../components/icons/sound'
import { CookiesContext } from '../context/cookies-context'
import { Image } from '@superrb/gatsby-addons/components'
import checkVideoUrl from '../utils/checkVideoUrl'
import useImageSources from '@superrb/gatsby-addons/src/hooks/use-image-sources'

export const VideoBanner = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    image: slice.primary.desktop_placeholder_video_image,
    mobile_image: slice.primary.mobile_placeholder_video_image,
    video: slice.primary.desktop_vimeo_video.url,
    mobile_video: slice.primary.mobile_vimeo_video.url,
    border_radius_top: slice.primary.border_radius_top,
    border_radius_bottom: slice.primary.border_radius_bottom,
    disable_cursor: slice.primary.disable_sound_cursor
  }

  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [videoMuted, setVideoMuted] = useState(true)
  const [videoTime, setVideoTime] = useState('00:00')

  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const [videoMobileIsEmbedCode, setVideoMobileIsEmbedCode] = useState(true)

  const isMobile = useIsMobile(true)
  const isMotionAllowed = useMotionAllowed()

  useEffect(() => {
    if (!isMotionAllowed) {
      setVideoPlaying(false)
    }
    setVideoIsEmbedCode(checkVideoUrl(data.video))
    setVideoMobileIsEmbedCode(checkVideoUrl(data.mobile_video))

  }, [isMotionAllowed])

  const handleVideoPlay = useCallback(
    (event) => {
      setVideoPlaying(true)
    },
    [setVideoPlaying],
  )

  const handleVideoClose = useCallback(
    (event) => {
      setVideoPlaying(false)
    },
    [setVideoPlaying],
  )

  const toHHMMSS = (time) => {
    var sec_num = parseInt(time, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor((sec_num - hours * 3600) / 60)
    var seconds = sec_num - hours * 3600 - minutes * 60

    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    return minutes + ':' + seconds
  }

  const { src: imageSrc } = useImageSources(
    isMobile ? data.mobile_image : data.image,
  )

  return (
    <section
      className={`video-banner ${!videoMuted && 'video-banner--playing'} 
      ${!data.border_radius_top ? 'video-banner--no-radius-top' : ''} 
      ${!data.border_radius_bottom ? 'video-banner--no-radius-bottom' : ''} 
      ${slice?.primary?.full_viewport ? 'video-banner--full-viewport' : ''}`}
      aria-label="Play Reel"
      data-header-modifier={slice?.primary?.full_viewport ? 'transparent' : 'dark'}
    >
      {data.video && data.mobile_video ? (
        <div className="video-banner__container container">
          <div
            className="video-banner__inner"
            onMouseMove={(e) => {
              if(!document.querySelector('.video-banner__cursor')) {
                return
              }
              let container = e.target.getBoundingClientRect()
              const x = e.clientX - (container.left - 18)
              const y = e.clientY - (container.top - 48)
              document.querySelector('.video-banner__cursor').style.cssText =
                'top:' + y + 'px;left:' + x + 'px;'
            }}
            onClick={() => {
              if (!videoMuted) {
                setVideoMuted(true)
                if (!isMotionAllowed) {
                  setVideoPlaying(false)
                }
              } else {
                setVideoMuted(false)
                if (!isMotionAllowed) {
                  setVideoPlaying(true)
                }
              }
            }}
          >
            {cookiesAccepted || !videoIsEmbedCode || !videoMobileIsEmbedCode ? (
              <ReactPlayer
                url={isMobile ? data.mobile_video : data.video}
                muted={videoMuted}
                playsinline={true}
                playing={videoPlaying}
                autoPlay={true}
                loop={true}
                className="video-player__player"
                light={false}
                config={{
                  vimeo: {
                    playerOptions: {
                      responsive: true,
                    },
                  },
                  file: {
                    attributes: {
                      poster: imageSrc,
                    },
                  },
                }}
                onProgress={(e) => {
                  let time = Math.floor(e.playedSeconds)
                  setVideoTime(toHHMMSS(time))
                }}
              />
            ) : (
              <Image
                image={isMobile ? data.mobile_image : data.image}
                className={'video-banner__placeholder'}
              />
            )}
            {!data.disable_cursor ?
              <div className="video-banner__cursor">
                <PlayCursor />
                <Sound />
              </div>
            : ''}
            {/*}
            <div className="video-banner__footer">
              <div className="video-banner__footer-logo">
                <svg
                  className="video-banner__footer--icon"
                  width="47"
                  height="47"
                  viewBox="0 0 47 47"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.5017 1.85705C35.4365 1.85705 45.143 11.5668 45.143 23.4983C45.143 35.4299 35.4332 45.1396 23.5017 45.1396C11.5701 45.1396 1.86037 35.4332 1.86037 23.5017C1.86037 11.5701 11.5668 1.85705 23.5017 1.85705ZM23.5017 0C10.5222 0 0 10.5222 0 23.5017C0 36.4811 10.5222 47.0033 23.5017 47.0033C36.4811 47.0033 47.0033 36.4811 47.0033 23.5017C47.0033 10.5222 36.4778 0 23.5017 0Z"
                    fill="#F8F8F8"
                  />
                  <path
                    d="M18.5703 14.4153L23.0472 27.5606H23.1002L27.3283 14.4153H33.2444V29.3181C33.2444 31.9644 34.2956 32.7537 35.496 33.5462C32.412 34.0072 29.3313 33.4965 29.3313 30.5352C29.3313 27.5738 29.2351 19.9599 29.2351 19.9599H29.1821C29.1821 19.9599 27.5041 24.8148 26.1577 28.7013C26.045 29.0263 25.9521 29.348 25.8427 29.6497C25.2126 31.4073 26.2307 32.7703 27.0929 33.5462C23.8066 34.2625 21.1337 32.9792 20.0062 29.6862C19.8968 29.3712 19.7608 29.0694 19.6481 28.7477C18.305 24.901 16.6436 20.1191 16.6436 20.1191H16.5906C16.5906 20.1191 16.5906 28.1177 16.5906 29.3181C16.5906 31.4604 17.8938 32.6078 19.28 33.5462C16.3319 34.2824 13.5298 32.5149 12.7273 29.5403C12.6609 29.2087 12.6543 28.8671 12.6543 28.5156V14.4186H18.5703V14.4153Z"
                    fill="#F8F8F8"
                  />
                </svg>
                <p className="video-banner__footer--text">Mayda.showreel</p>
              </div>
              <div className="video-banner__footer-timestamp">
                <p>{videoTime}</p>
              </div>
            </div>
            {*/}
          </div>
        </div>
      ) : (
        ''
      )}
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyVideoBanner on PrismicHomepageDataBodyVideoBanner {
    primary {
      desktop_vimeo_video {
        ...Link
      }
      mobile_vimeo_video {
        ...Link
      }
      border_radius_top
      border_radius_bottom
      disable_sound_cursor
      full_viewport
      desktop_placeholder_video_image {
        ...Image
      }
      mobile_placeholder_video_image {
        ...Image
      }
    }
  }

  fragment WorkDetailPageDataBodyVideoBanner on PrismicWorkDetailPageDataBodyVideoBanner {
    primary {
      desktop_vimeo_video {
        ...Link
      }
      mobile_vimeo_video {
        ...Link
      }
      border_radius_top
      border_radius_bottom
      disable_sound_cursor
      desktop_placeholder_video_image {
        ...Image
      }
      mobile_placeholder_video_image {
        ...Image
      }
    }
  }

  fragment StudioPageDataBodyVideoBanner on PrismicStudioPageDataBodyVideoBanner {
    primary {
      desktop_vimeo_video {
        ...Link
      }
      mobile_vimeo_video {
        ...Link
      }
      border_radius_top
      border_radius_bottom
      disable_sound_cursor
      desktop_placeholder_video_image {
        ...Image
      }
      mobile_placeholder_video_image {
        ...Image
      }
    }
  }

  fragment CareersPageDataBodyVideoBanner on PrismicCareersPageDataBodyVideoBanner {
    primary {
      desktop_vimeo_video {
        ...Link
      }
      mobile_vimeo_video {
        ...Link
      }
      border_radius_top
      border_radius_bottom
      disable_sound_cursor
      desktop_placeholder_video_image {
        ...Image
      }
      mobile_placeholder_video_image {
        ...Image
      }
    }
  }
`
