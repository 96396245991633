import {Page, Image, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, Link, PageProps } from 'gatsby'
import React, {useContext, useEffect, useState} from 'react'
import { TeamMember, TeamMembers } from '../types/pages/collective-page'
import CollectivePageType from '../types/pages/collective-page'
import { PageTitle } from '../components/page-title'
import { CollectiveItem } from '../components/collective-item'
import { gsap } from 'gsap'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import ArticleFilters from "../components/article-filters";
import useArticleFilter from "../hooks/use-article-filter";

const CollectivePage = ({
  data,
}: PageProps<{ prismicCollectivePage: CollectivePageType }, PageStub>) => {
  const page = data.prismicCollectivePage
  if (!page) {
    return null
  }

  const {
    data: { page_title, page_description },
  } = page

  const [availableTags, setAvailableTags] = useState([])
  const team_members: TeamMembers = page.data.team_members
  const collectiveDetailPages = data.collectiveDetailPages.nodes
  const collectiveIds = []

  let once = true
  useEffect(() => {
    if (!once) {
      return
    }

    const uniqueTags = Array.from(
        new Set(data.collectiveDetailPagesTags.nodes.flatMap((tag) => tag.tags)),
    )
    setAvailableTags(uniqueTags)

    gsap.from('.collective__inner', {
      duration: 0.8,
      delay: 2,
      opacity: 0,
      y: 150,
      ease: 'power3',
    })
    once = false
  }, [data, setAvailableTags])

  const filteredArticles = useArticleFilter(collectiveDetailPages, availableTags)
  filteredArticles.forEach((item) => {
    collectiveIds.push(item.uid)
  })
  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <PageTitle
          title={page_title}
          text={page_description}
          light_theme={true}
        />
        <section className="collective light-theme" data-header-modifier={'light'}>
          <div className="collective__container container">
            <div className="collective__inner">
              <ArticleFilters tags={availableTags} />
              <nav className="collective__nav">
                {team_members && team_members.map((item: TeamMember, index) => {
                  if(!collectiveIds.includes(item.team_member_link.uid)) {
                    return <></>
                  }
                  const team_images =
                      item.team_member_link.document.data.extra_profile_images
                  return (
                      <CollectiveItem
                          name={item.label}
                          job_role={item.job_role}
                          link={item.team_member_link}
                          image={item.image}
                          key={`collective-listing-item-${index}`}
                          video={item.team_member_link.document.data.listing_video}
                      />
                  )
                })}
              </nav>
            </div>
          </div>
        </section>
      </Page>
    </>
  )
}

export const query = graphql`
  query CollectivePageQuery($id: String) {
    prismicCollectivePage(id: { eq: $id }) {
      ...CollectivePage
    }
    collectiveDetailPages: allPrismicCollectiveDetailPage {
      nodes {
        ...CollectiveDetailPage
      }
    }
    collectiveDetailPagesTags: allPrismicCollectiveDetailPage {
      nodes {
        tags
      }
    }
  }
`

export default withPrismicPreview(CollectivePage)
