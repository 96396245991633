import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image } from '@superrb/gatsby-addons/components'
import Flickity from 'react-flickity-component'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import {Swiper, SwiperSlide} from "swiper/react"
import {Autoplay} from "swiper"
import 'swiper/css'
import 'swiper/css/autoplay'

export const ImageSwiper = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
  }

  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section className={`image-swiper`} aria-label="Image Swiper" data-header-modifier={'dark'}>
      <div className="image-swiper__container container">
        <div className="image-swiper__inner" ref={setRef}>
          <div
            className={`image-swiper__text animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            {data.title ? <h2>{data.title}</h2> : ''}
          </div>
          {slice.items ? (
            <>
              <Swiper
                  modules={[Autoplay]}
                  speed={4000}
                  spaceBetween={24}
                  slidesPerView={1.4}
                  loop={true}
                  loopedSlides={3}
                  autoplay={{
                    delay: 0
                  }}
                  onTouchStart={(swiper) => {
                    swiper.params.speed = 650
                    swiper.el.classList.add('swiper--no-autoplay')
                  }}
                  breakpoints={{
                    720: {
                      slidesPerView: 3
                    }
                  }}
              >
                {slice.items.map((slide, index) => (
                    <SwiperSlide key={`image-swiper-slide-${index}`}>
                      <div className="image-swiper__slide">
                        <Image image={slide.image} />
                      </div>
                    </SwiperSlide>
                ))}
              </Swiper>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CareersPageDataBodyImageSwiper on PrismicCareersPageDataBodyImageSwiper {
    primary {
      title
    }
    items {
      image {
        ...Image
      }
    }
  }
`
