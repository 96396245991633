import React from 'react'

const PlayCursor = () => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      className="equalizer"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="2.12915"
        width="2"
        height="22"
        fill="#F8F8F8"
        style={{ animationDelay: '0.2s' }}
      />
      <rect x="6" y="2.12915" width="2" height="14.1935" fill="#F8F8F8" />
      <rect
        x="12"
        y="2.12915"
        width="2"
        height="7.80645"
        fill="#F8F8F8"
        style={{ animationDelay: '0.4s' }}
      />
      <rect
        x="18"
        y="2.12915"
        width="2"
        height="17.7419"
        fill="#F8F8F8"
        style={{ animationDelay: '0.3s' }}
      />
      <rect
        x="24"
        y="2.12915"
        width="2"
        height="14.9032"
        fill="#F8F8F8"
        style={{ animationDelay: '0.1s' }}
      />
    </svg>
  )
}

export default PlayCursor
