import {Page, RichText, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, PageProps } from 'gatsby'
import React, {useContext, useEffect} from 'react'
import CareersDetailPageType from '../types/pages/careers-detail-page'
import CareersContactForm from '../components/careers-contact-form'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import {HeaderVariantContext} from "../context/header-variant-context-provider";
import {HeroHeader} from "../components/hero-header";
import {useIsInViewport} from "@superrb/gatsby-addons/hooks";

const CareersDetailPage = ({
  data,
}: PageProps<
  { prismicCareersDetailPage: CareersDetailPageType },
  PageStub
>) => {
  const page = data?.page || data?.prismicCareersDetailPage
  if (!page) {
    return null
  }

  const {
    data: { page_title, career_text, header_image, additional_text },
  } = page

  const { isInViewport, setRef } = useIsInViewport(false)
  const { variant, setHeaderVariant } = useContext(HeaderVariantContext)

  useEffect(() => {
    setHeaderVariant('light')

    if(isInViewport) {
      setHeaderVariant('light')
    }
  }, [setHeaderVariant])

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <HeroHeader title={page_title} text={additional_text} image={header_image} />
        <section className="careers-detail light-theme" data-header-modifier={'light'}>
          <div className="careers-detail__container container">
            <div className="careers-detail__inner" ref={setRef}>
              {career_text && <RichText field={career_text.richText} />}
            </div>
          </div>
        </section>
        <section className="careers-detail" data-header-modifier={'dark'}>
          <div className="careers-detail__container container">
            <div className="form__border">
              <div className="careers-detail__inner">
                <h5>Apply for this role</h5>
                <CareersContactForm />
              </div>
            </div>
          </div>
        </section>
      </Page>
    </>
  )
}

export const query = graphql`
  query CareersDetailPageQuery($id: String) {
    prismicCareersDetailPage(id: { eq: $id }) {
      ...CareersDetailPage
    }
  }
`

export default withPrismicPreview(CareersDetailPage)
