import React, {useContext, useEffect} from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql, Link } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import { CenterCta } from '../components/center-cta'
import {useIsInViewport, useIsMobile} from '@superrb/gatsby-addons/hooks'
import Linkedin from "../components/icons/linkedin";
import Twitter from "../components/icons/twitter";
import Instagram from "../components/icons/instagram";
import { Image } from '@superrb/gatsby-addons/components'

export const MeetTheTeam = ({ slice }: SliceComponentProps<any, any>) => {
    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        while (currentIndex != 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    const meetTheTeamItem = (item) => {
        let id = ''
        let el
        if(item.target.getAttribute('data-id')) {
            id = item.target.getAttribute('data-id')
            el = item.target
        }
        else {
            id = item.target.closest('[data-id]').getAttribute('data-id')
            el = item.target.closest('[data-id]')
        }

        if(document.querySelector('.meet-the-team__slide--active')) {
            document.querySelector('.meet-the-team__slide--active').classList.remove('meet-the-team__slide--active')
            document.querySelector('.meet-the-team__slide[data-id="' + id + '"]').classList.add('meet-the-team__slide--active')
        }

        if(document.querySelector('.meet-the-team__item--active')) {
            document.querySelector('.meet-the-team__item--active').classList.remove('meet-the-team__item--active')
        }

        el.classList.add('meet-the-team__item--active')
    }

    const isMobile = useIsMobile(false, '63.75em')

    if(slice.items) {
        shuffle(slice.items)
    }

    return (
        <>
            <section className={`meet-the-team`} aria-label="Meet the team" data-header-modifier={'dark'}>
                <div className="meet-the-team__container container">
                    <div className="meet-the-team__inner">
                        <div
                            className={`meet-the-team__header`}
                        >
                            {slice.primary?.title ? (
                                <h2 className="collective-swiper__title">{slice.primary.title}</h2>
                            ) : (
                                ''
                            )}
                            {slice.primary?.sub_text ? (
                                <p className="collective-swiper__text">{slice.primary.sub_text}</p>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="meet-the-team__listings">
                            <div className={'meet-the-team__desktop-col'}>
                                <div className="meet-the-team__sticky">
                                    {slice.items &&
                                        slice.items.map((item, index) => {
                                            return (
                                                <div data-id={index} className={`meet-the-team__slide ${index === 0 ? 'meet-the-team__slide--active' : ''}`}>
                                                    {item.image ?
                                                        <Image image={item.image}/>
                                                    : ''}
                                                    {item.description ?
                                                        <p>{item.description}</p>
                                                    : ''}
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                            <nav className={'meet-the-team__nav'}>
                                <ul className="meet-the-team__list">
                                    {slice.items &&
                                        slice.items.map((item, index) => {
                                            return (
                                                <li className={`meet-the-team__item ${index === 0 && !isMobile ? 'meet-the-team__item--active' : ''}`} data-id={index} key={index} onMouseEnter={meetTheTeamItem}>
                                                    {item.portfolio_link ?
                                                        <Link
                                                            to={linkResolver(item.portfolio_link)}
                                                            className="meet-the-team__row-link"
                                                            aria-label={'View Portfolio'}
                                                        ></Link>
                                                    : ''}
                                                    <span className="meet-the-team__item-name">
                                                        {item.name}
                                                    </span>
                                                    <span className="meet-the-team__item-title">
                                                        {item.job_title}
                                                    </span>
                                                    {item.portfolio_link && !isMobile ?
                                                        <span className="meet-the-team__item-portfolio">
                                                            <Link
                                                                to={linkResolver(item.portfolio_link)}
                                                                className="meet-the-team__link"
                                                            >
                                                                View Portfolio
                                                            </Link>
                                                        </span>
                                                    : ''}
                                                    {!isMobile ?
                                                        <span className={'meet-the-team__socials'}>
                                                            {item.linkedin ?
                                                                <span className="meet-the-team__item-icon">
                                                                    <Link
                                                                        to={linkResolver(item.linkedin)}
                                                                        className="meet-the-team__link"
                                                                    >
                                                                        <Linkedin/>
                                                                    </Link>
                                                                </span>
                                                                : ''}
                                                                    {item.twitter ?
                                                                        <span className="meet-the-team__item-icon">
                                                                    <Link
                                                                        to={linkResolver(item.twitter)}
                                                                        className="meet-the-team__link"
                                                                    >
                                                                        <Twitter/>
                                                                    </Link>
                                                                </span>
                                                                        : ''}
                                                                    {item.instagram ?
                                                                        <span className="meet-the-team__item-icon">
                                                                    <Link
                                                                        to={linkResolver(item.instagram)}
                                                                        className="meet-the-team__link"
                                                                    >
                                                                        <Instagram/>
                                                                    </Link>
                                                                </span>
                                                            : ''}
                                                        </span>
                                                    : ''}
                                                    {isMobile ?
                                                        <div className={'meet-the-team__accordion'}>
                                                            {item.image ?
                                                                <Image image={item.image}/>
                                                                : ''}
                                                            {item.description ?
                                                                <p>{item.description}</p>
                                                                : ''}
                                                            <div className="meet-the-team__accordion-footer">
                                                                {item.portfolio_link ?
                                                                    <span className="meet-the-team__item-portfolio">
                                                                    <Link
                                                                        to={linkResolver(item.portfolio_link)}
                                                                        className="meet-the-team__link"
                                                                        target={item.portfolio_link.target}
                                                                    >
                                                                        View Portfolio
                                                                    </Link>
                                                                </span>
                                                                    : ''}
                                                                <span className={'meet-the-team__socials'}>
                                                            {item.linkedin ?
                                                                <span className="meet-the-team__item-icon">
                                                                    <Link
                                                                        to={linkResolver(item.linkedin)}
                                                                        className="meet-the-team__link"
                                                                        target={'_blank'}
                                                                    >
                                                                        <Linkedin/>
                                                                    </Link>
                                                                </span>
                                                                : ''}
                                                                    {item.twitter ?
                                                                        <span className="meet-the-team__item-icon">
                                                                    <Link
                                                                        to={linkResolver(item.twitter)}
                                                                        className="meet-the-team__link"
                                                                        target={'_blank'}
                                                                    >
                                                                        <Twitter/>
                                                                    </Link>
                                                                </span>
                                                                        : ''}
                                                                    {item.instagram ?
                                                                        <span className="meet-the-team__item-icon">
                                                                    <Link
                                                                        to={linkResolver(item.instagram)}
                                                                        className="meet-the-team__link"
                                                                        target={'_blank'}
                                                                    >
                                                                        <Instagram/>
                                                                    </Link>
                                                                </span>
                                                                        : ''}
                                                        </span>
                                                            </div>
                                                        </div>
                                                    : ''}
                                                </li>
                                            )
                                        })}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <CenterCta
                title="LETS WORK TOGETHER"
                text=""
            />
        </>
    )
}
export const query = graphql`
  fragment StudioPageDataBodyMeetTheTeam on PrismicStudioPageDataBodyMeetTheTeam {
    primary {
      title
      sub_text
    }
    items {
      image {
        ...Image
      }
      name
      job_title
      description
      portfolio_link {
        ...Link
      }
      linkedin {
        ...Link
      }
      instagram {
        ...Link
      }
      twitter {
        ...Link
      }
    }
  }
`
