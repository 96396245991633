import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import { RichText as RichTextType } from '@superrb/gatsby-addons/types'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import { HeaderVariantContext } from '../context/header-variant-context-provider'

interface Props extends Record<string, any> {
  description: RichTextType
}

export const TwoColumnContent = ({
  slice,
  context,
}: SliceComponentProps<any, any>) => {
  const data = {
    layout: slice.primary.layout,
    title: slice.primary.heading,
    description: slice.primary.description,
    image: slice.primary.image,
  }

  const { isInViewport, setRef } = useIsInViewport(false)
  const { variant, setHeaderVariant } = useContext(HeaderVariantContext)

  useEffect(() => {
    if (isInViewport) {
      setHeaderVariant('light')
    }
  }, [isInViewport, setHeaderVariant])

  return (
    <section
      className="two-column-content light-theme"
      data-header-modifier={context.darkTheme ? 'dark' : 'light'}
    >
      <div className="two-column-content__container container">
        <div className="two-column-content__inner" ref={setRef}>
          <div
            className={`two-column-content__col two-column-content__col--image ${
              data.layout === 'right' ? 'two-column-content__col--first' : ''
            } animate-on-scroll ${isInViewport ? 'animated' : ''}`}
          >
            {data.image ? <Image image={data.image} /> : ''}
          </div>
          <div
            className={`two-column-content__col two-column-content__col--text ${
              data.layout === 'left' ? 'two-column-content__col--first' : ''
            } animate-on-scroll ${isInViewport ? 'animated' : ''}`}
          >
            <div className="two-column-content__text-wrap">
              {data.title ? (
                <h2 className="two-column-content__title">{data.title}</h2>
              ) : (
                ''
              )}
              {data.description ? (
                <div
                  className="two-column-content__text"
                  style={{
                    fontSize: `${
                      slice.primary.description_font_size || '14'
                    }px`,
                  }}
                >
                  <RichText field={data.description.richText} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment WorkDetailPageDataBodyTwoColumnContent on PrismicWorkDetailPageDataBodyTwoColumnContent {
    primary {
      layout
      heading
      description {
        ...RichText
      }
      description_font_size
      image {
        ...Image
      }
    }
  }
`
