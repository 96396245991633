import React, { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { ScrollIndicator } from '../components/scroll-indicator'
import { useImageSources, useMotionAllowed } from '@superrb/gatsby-addons/hooks'
import { CookiesContext } from '../context/cookies-context'
import { Image } from '@superrb/gatsby-addons/components'
import checkVideoUrl from '../utils/checkVideoUrl'
import { VideoPopup } from '../components/video-popup'
import PlayIcon from '../components/icons/play-icon'

export const HeroVideo = ({
  slice,
  context,
}: SliceComponentProps<any, any>) => {
  const data = {
    video: slice.primary?.hero_video,
    videoUrl: slice.primary?.hero_video?.url,
    teaserVideo: slice.primary?.teaser_video,
    teaserVideoUrl: slice.primary?.teaser_video?.url,
    gradient: slice.primary?.gradient_start_opacity,
    image: slice.primary?.fallback_image,
  }

  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const [teaserVideoIsEmbedCode, setTeaserVideoIsEmbedCode] = useState(true)
  const isMotionAllowed = useMotionAllowed()
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [mousePos, setMousePos] = useState<{ x: string; y: string }>({
    x: '50%',
    y: '50%',
  })

  useEffect(() => {
    if (data.videoUrl) {
      setVideoIsEmbedCode(checkVideoUrl(data.videoUrl))
    }
    if (data.teaserVideoUrl) {
      setTeaserVideoIsEmbedCode(checkVideoUrl(data.teaserVideoUrl))
    }
  }, [data.videoUrl, data.teaserVideoUrl])

  const { src: imageSrc } = useImageSources(data.image)

  return (
    <>
      <section
        className={`hero-video`}
        aria-label="Play Reel"
        data-header-modifier={'transparent'}
      >
        <div className="hero-video__container">
          <div
            className={`hero-video__inner ${
              data.video?.url
                ? 'hero-video__inner--no-cursor'
                : 'hero-video__inner--no-video'
            }`}
            data-popup={0}
            onMouseMove={(e) => {
              const { left, top } = e.currentTarget.getBoundingClientRect()
              const x = e.clientX - (left + 20)
              const y = e.clientY - (top + 20)
              setMousePos({ x: `${x}px`, y: `${y}px` })
            }}
          >
            <div
              className="hero-video__video-wrap"
              onClick={(e) => {
                setPopupOpen(true)
              }}
            >
              {(cookiesAccepted || !teaserVideoIsEmbedCode) &&
              data.teaserVideo?.url ? (
                <ReactPlayer
                  url={data.teaserVideo.url}
                  muted={true}
                  playsinline={true}
                  playing={isMotionAllowed}
                  autoPlay={isMotionAllowed}
                  loop={true}
                  className="hero-video__video"
                  light={false}
                  config={{
                    vimeo: {
                      playerOptions: {
                        responsive: true,
                      },
                    },
                    file: {
                      attributes: {
                        poster: imageSrc,
                      },
                    },
                  }}
                />
              ) : (
                <Image
                  image={data.image}
                  className={'hero-video__placeholder'}
                />
              )}
              <div
                className="hero-video__overlay"
                style={{
                  backgroundImage:
                    'linear-gradient(rgba(3,3,3,0), rgba(3,3,3,' +
                    data.gradient +
                    '))',
                }}
              ></div>
            </div>
            <div className="hero-video__text">
              {context.title ? (
                <h1 className="hero-video__title">{context.title}</h1>
              ) : (
                ''
              )}
              {context.client ? (
                <h2 className="hero-video__subtitle">{context.client}</h2>
              ) : (
                ''
              )}
            </div>
            <div className="hero-video__scroll">
              <ScrollIndicator />
            </div>
            {data.video?.url ? (
              <PlayIcon
                style={{ top: `${mousePos.y}`, left: `${mousePos.x}` }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
      {(cookiesAccepted || !videoIsEmbedCode) && data.video?.url ? (
        <VideoPopup
          video={data.video?.url}
          isOpen={popupOpen}
          setIsOpen={setPopupOpen}
          popupid={0}
        />
      ) : (
        ''
      )}
    </>
  )
}

export const query = graphql`
  fragment WorkDetailPageDataBodyHeroVideo on PrismicWorkDetailPageDataBodyHeroVideo {
    primary {
      hero_video {
        ...Link
      }
      teaser_video {
        ...Link
      }
      gradient_start_opacity
      fallback_image {
        ...Image
      }
    }
  }
`
