import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import { RichText as RichTextType } from '@superrb/gatsby-addons/types'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

interface Props extends Record<string, any> {
  left_title: string
  right_title: string
  left_subtitle: string
  right_subtitle: string
  left_text: RichTextType
  right_text: RichTextType
}

export const TwoColumnText = ({ slice, context }: SliceComponentProps<any, any>) => {
  const data = {
    l_title: slice.primary.left_title,
    r_title: slice.primary.right_title,
    l_subtitle: slice.primary.left_subtitle,
    r_subtitle: slice.primary.right_subtitle,
    l_text: slice.primary.left_text,
    r_text: slice.primary.right_text,
  }

  const LeftTitleSplit = data.l_title.split('\n')
  const RightTitleSplit = data.r_title.split('\n')
  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section className="two-column-text light-theme" data-header-modifier={context.darkTheme ? 'dark' : 'light'}>
      <div className="two-column-text__container container">
        <div className="two-column-text__inner" ref={setRef}>
          <div
            className={`two-column-text__col two-column-text__col--ltext animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            <div className="two-column-text__text-wrap">
              {data.l_title ? (
                <h2
                  className={`two-column-text__title ${
                    LeftTitleSplit.length === 2
                      ? 'two-column-text__title--break'
                      : ''
                  }`}
                >
                  {data.l_title}
                </h2>
              ) : (
                ''
              )}
              {data.l_subtitle ? (
                <h3 className="two-column-text__sub-title">
                  {data.l_subtitle}
                </h3>
              ) : (
                ''
              )}
              {data.l_text ? (
                <div className="two-column-text__text">
                  <RichText field={data.l_text.richText} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div
            className={`two-column-text__col two-column-text__col--rtext two-column-text__col--border animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            <div className="two-column-text__text-wrap">
              {data.r_title ? (
                <h2
                  className={`two-column-text__title ${
                    RightTitleSplit.length === 2
                      ? 'two-column-text__title--break'
                      : ''
                  }`}
                >
                  {data.r_title}
                </h2>
              ) : (
                ''
              )}
              {data.r_subtitle ? (
                <h3 className="two-column-text__sub-title">
                  {data.r_subtitle}
                </h3>
              ) : (
                ''
              )}
              {data.r_text ? (
                <div className="two-column-text__text">
                  <RichText field={data.r_text.richText} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CareersPageDataBodyTwoColumnText on PrismicCareersPageDataBodyTwoColumnText {
    primary {
      left_title
      right_title
      left_subtitle
      right_subtitle
      left_text {
        ...RichText
      }
      right_text {
        ...RichText
      }
    }
  }
`
