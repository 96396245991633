import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import {useIsMobile} from "@superrb/gatsby-addons/hooks";
import CollectiveDropdown, {CollectiveDropdownLink} from "../types/collective-dropdown";
import { CollectiveDropdownContext } from '../context/collective-dropdown'
import {linkResolver} from "../utils/linkResolver";
import ReactPlayer from "react-player/lazy";
import ButtonArrow from './icons/button-arrow'
import {NavContext} from "@superrb/gatsby-addons/context";

const CollectiveHeaderDropdown = () => {
    const data = useStaticQuery(graphql`
    query CollectiveDropdownQuery {
      dropdown: prismicCollectiveDropdown {
        ...CollectiveDropdown
      }
    }
  `)

    const isMobile = useIsMobile(true)
    const { closeNav } = useContext(NavContext)
    const { collectiveDropdownOpen, closeCollectiveDropdown } = useContext(CollectiveDropdownContext)
    const [videoPlaying, setVideoPlaying] = useState(false)

    const dropdownFilter = (el) => {
        if(!el.target.getAttribute('data-menu')) {
            return
        }

        const id = el.target.getAttribute('data-menu')
        if(document.querySelector('.collective__dropdown .collective__menu-list.collective__menu-list--active')) {
            document.querySelector('.collective__dropdown .collective__menu-list.collective__menu-list--active').classList.remove('collective__menu-list--active')
            document.querySelector('.collective__dropdown .collective__filters-button.collective__filters-button--active').classList.remove('collective__filters-button--active')
        }

        if(document.querySelector('.collective__dropdown .collective__menu-list[data-menu="' + id + '"]')) {
            document.querySelector('.collective__dropdown .collective__menu-list[data-menu="' + id + '"]').classList.add('collective__menu-list--active')
            document.querySelector('.collective__dropdown .collective__filters-button[data-menu="' + id + '"]').classList.add('collective__filters-button--active')
        }
    }

    const backButton = useCallback(() => {
        event.preventDefault()
        closeCollectiveDropdown()
    }, [closeCollectiveDropdown])

    const handleClick = useCallback(() => {
        if (
            document &&
            document?.activeElement instanceof Element &&
            'blur' in document?.activeElement
        ) {
            ;(document.activeElement as HTMLElement)?.blur()
        }

        closeNav()
        closeCollectiveDropdown()
    }, [closeNav, closeCollectiveDropdown])

    /** @type {CollectiveDropdown} header */
    const dropdown: CollectiveDropdown = data?.dropdown
    if (!dropdown) {
        return null
    }
    return (
        <div className="collective__dropdown" aria-hidden={!collectiveDropdownOpen}>
            {isMobile ?
                <div className="collective__back-btn">
                    <button className="collective__filters-button collective__filters-button--close" onClick={backButton}>
                        <ButtonArrow/>
                        Go back
                    </button>
                </div>
            : ''}
            {
              /*
              <div className="collective__filters">
                <button className="collective__filters-button collective__filters-button--active" data-menu={0} onClick={dropdownFilter}>COLLECTIVE</button>
                <button className="collective__filters-button" data-menu={1} onClick={dropdownFilter}>DIRECTORS</button>
              </div>
               */
            }
            <div className="collective__menu-wrapper">
                <div className="collective__menu-list collective__menu-list--active" data-menu={0}>
                    {dropdown.data.collective_dropdown_items.map(
                        (item: CollectiveDropdownLink, index: number) => {
                            if(item.member_type) {
                                return
                            }
                            return (
                                <>
                                    {item.collective_member_link?.url ?
                                        <>
                                            <Link
                                                to={item.collective_member_link.url}
                                                className="collective__menu-item"
                                                onClick={handleClick}
                                                onMouseEnter={() => {setVideoPlaying(true)}}
                                                onMouseLeave={() => {setVideoPlaying(false)}}
                                            >
                                                {item.collective_member_link?.document?.data?.page_title ?
                                                    <h4 className={'collective__menu-item-title'} >{item.collective_member_link.document.data.page_title}</h4>
                                                    : ''}
                                            </Link>
                                            {item.video_link?.url && !isMobile ?
                                                <ReactPlayer
                                                    url={item.video_link.url}
                                                    muted={true}
                                                    playsinline={true}
                                                    playing={videoPlaying}
                                                    autoPlay={false}
                                                    loop={true}
                                                    className="directors__video"
                                                    light={false}
                                                    config={{
                                                        vimeo: {
                                                            playerOptions: {
                                                                responsive: true,
                                                            },
                                                        },
                                                    }}
                                                />
                                                : ''}
                                        </>
                                        : ''}
                                </>
                            )
                        },
                    )}
                </div>
                <div className="collective__menu-list" data-menu={1}>
                    {dropdown.data.collective_dropdown_items.map(
                        (item: CollectiveDropdownLink, index: number) => {
                            if(!item.member_type) {
                                return
                            }
                            return (
                                <>
                                    {item.collective_member_link?.url ?
                                        <>
                                            <Link
                                                to={item.collective_member_link.url}
                                                className="collective__menu-item"
                                            >
                                                {item.collective_member_link?.document?.data?.page_title ?
                                                    <h4 className={'collective__menu-item-title'}>{item.collective_member_link.document.data.page_title}</h4>
                                                    : ''}
                                            </Link>
                                            {item.video_link?.url ?
                                                <ReactPlayer
                                                    url={item.video_link.url}
                                                    muted={true}
                                                    playsinline={true}
                                                    playing={videoPlaying}
                                                    autoPlay={false}
                                                    loop={true}
                                                    className="directors__video"
                                                    light={false}
                                                    config={{
                                                        vimeo: {
                                                            playerOptions: {
                                                                responsive: true,
                                                            },
                                                        },
                                                    }}
                                                />
                                                : ''}
                                        </>
                                        : ''}
                                </>
                            )
                        },
                    )}
                </div>
            </div>
        </div>
    )
}

export default CollectiveHeaderDropdown
