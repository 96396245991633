import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { LiveNodeList } from 'live-node-list'
import { ScrollIndicator } from '../components/scroll-indicator'

export const TitleBlock = ({}) => {
  useEffect(() => {
    if (
      !document.querySelector(
        '.title-block .title-block__word .title-block__letter',
      )
    ) {
      const words = new LiveNodeList('.title-block .title-block__word')
      words.items.forEach(function (item) {
        let wordBin = item.innerText.split(' ')
        item.innerHTML = ''
        for (var i = 0; i < wordBin.length; i++) {
          item.innerHTML +=
            '<span className="title-block__word-wrap">' + wordBin[i] + '</span>'
        }

        const words = item.querySelectorAll('.title-block__word-wrap')
        for (var x = 0; x < words.length; x++) {
          const letters = words[x].innerHTML.split('')
          words[x].innerHTML = ''
          for (var y = 0; y < letters.length; y++) {
            words[x].innerHTML +=
              '<span className="title-block__letter">' + letters[y] + '</span>'
          }
        }
      })
    }

    function titleAnimate(first) {
      const active = document.querySelector(
        '.title-block .title-block__word--active',
      )

      if (!active) {
        return
      }

      if (!first) {
        if (active.nextSibling) {
          active.nextSibling.classList.add('title-block__word--active')
          active.classList.remove('title-block__word--active')
        } else {
          document
            .querySelector('.title-block .title-block__word')
            .classList.add('title-block__word--active')
          active.classList.remove('title-block__word--active')
        }
      }

      let timeline = gsap.timeline({ repeat: 0, delay: 0, repeatDelay: 0 })
      timeline.to(
        '.title-block .title-block__word--active .title-block__letter',
        {
          y: '0%',
          duration: 1,
          delay: 0,
          stagger: 0.05,
          ease: 'expo',
        },
      )
      timeline.to(
        '.title-block .title-block__word--active .title-block__letter',
        {
          y: '100%',
          duration: 1,
          delay: 1.5,
          stagger: 0.05,
          ease: 'expo',
        },
      )
      timeline.to(
        '.title-block .title-block__word--active .title-block__letter',
        {
          y: '-100%',
          duration: 0,
          delay: 0,
        },
      )
    }
    titleAnimate(true)

    const titleInterval = setInterval(function () {
      titleAnimate(false)
    }, 3200)

    return () => {
      clearInterval(titleInterval)
    }
  }, [])
  return (
    <section className={`title-block`} aria-label={''}>
      <div className="title-block__container container">
        <div className="title-block__inner">
          <h1 className="title-block__title">
            we are mayda, a creative collective formed from the worlds of&nbsp;
            <span className="title-block__word-wrap">
              <span className="title-block__placeholder">ADVERTISING1...</span>
              <span className="title-block__word title-block__word--active">
                advertising...
              </span>
              <span className="title-block__word">technology...</span>
              <span className="title-block__word">film...</span>
              <span className="title-block__word">and gaming.</span>
            </span>
          </h1>
        </div>
        <div className="title-block__accent">
          <ScrollIndicator />
        </div>
      </div>
    </section>
  )
}
