import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

export const CenterQuote = ({ slice, context }: SliceComponentProps<any, any>) => {
  const data = {
    quote: slice.primary.quote,
    name: slice.primary.name,
  }

  let name = data.name.split('/*/')
  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section className="center-quote light-theme" data-header-modifier={context.darkTheme ? 'dark' : 'light'}>
      <div className="center-quote__container container">
        <div className="center-quote__inner" ref={setRef}>
          <div
            className={`center-quote__col animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            {data.quote ? (
              <h2 className="center-quote__title">"{data.quote}"</h2>
            ) : (
              ''
            )}
            {data.name ? (
              <p className="center-quote__name">
                {data.name.includes('/*/') ? (
                  <>
                    {name[0]}
                    <svg
                      width="8"
                      height="7"
                      viewBox="0 0 8 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.94304 0C3.94304 1.88259 5.54144 3.4181 7.54615 3.50058C5.5553 3.59708 3.97323 5.12687 3.97323 7C3.97323 5.11741 2.37483 3.5819 0.370117 3.49942C2.36096 3.40321 3.94304 1.87314 3.94304 0Z"
                        fill="#030303"
                      />
                    </svg>
                    {name[1]}
                  </>
                ) : (
                  data.name
                )}
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment WorkDetailPageDataBodyCenterQuote on PrismicWorkDetailPageDataBodyCenterQuote {
    primary {
      quote
      name
    }
  }

  fragment BlogDetailPageDataBodyCenterQuote on PrismicBlogDetailPageDataBodyCenterQuote {
    primary {
      quote
      name
    }
  }
`
