import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql, Link } from 'gatsby'
import { Image, RichText } from '@superrb/gatsby-addons/components'
import CollectiveArrow from '../components/icons/collective-arrow'
import PlayIcon from '../components/icons/play-icon'
import { VideoPopup } from '../components/video-popup'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { CookiesContext } from '../context/cookies-context'
import {
  useIsInViewport,
  useIsMobile,
  useMotionAllowed,
} from '@superrb/gatsby-addons/hooks'
import checkVideoUrl from '../utils/checkVideoUrl'
import ReactPlayer from 'react-player'
import useImageSources from '@superrb/gatsby-addons/src/hooks/use-image-sources'
import PlayCursor from '../components/icons/play-cursor'
import Sound from '../components/icons/sound'

export const SelectedWorksItem = ({ workItem, index, count }) => {
  const isMotionAllowed = useMotionAllowed()
  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const [mousePos, setMousePos] = useState<{ x: string; y: string }>({
    x: '50%',
    y: '50%',
  })

  if (!isMotionAllowed) {
    setVideoPlaying(false)
  }

  return (
    <>
      <div
        data-popup={index}
        className={`selected-works__col video-popup__container ${
          count === 0 ? 'parallax-item-0' : 'parallax-item-1'
        }`}
        key={`selected-works-item-${index}`}
      >
        {workItem.video_image ? (
          <div
            className={`selected-works__col-image ${
              cookiesAccepted && workItem.teaser_video_link?.url
                ? 'selected-works__col-image--hover'
                : ''
            }`}
            onMouseMove={(e) => {
              const { left, top } = e.currentTarget.getBoundingClientRect()
              const x = e.clientX - (left + 20)
              const y = e.clientY - (top + 20)
              setMousePos({ x: `${x}px`, y: `${y}px` })
            }}
            onClick={(e) => {
              setPopupOpen(true)
            }}
          >
            {(cookiesAccepted || !checkVideoUrl(workItem.video_link?.url)) &&
              workItem.video_link?.url && (
                <PlayIcon
                  style={{
                    top: `${mousePos.y}`,
                    left: `${mousePos.x}`,
                  }}
                />
              )}
            <Image
              image={workItem.video_image}
              className={`selected-works__image`}
              lazyLoad={false}
            />
            {workItem.teaser_video_link?.url &&
            (cookiesAccepted ||
              !checkVideoUrl(workItem.teaser_video_link?.url)) ? (
              <ReactPlayer
                url={workItem.teaser_video_link?.url}
                muted={true}
                playsinline={true}
                playing={videoPlaying}
                autoPlay={videoPlaying}
                loop={true}
                className="selected-works__video"
                light={false}
                controls={false}
                config={{
                  vimeo: {
                    playerOptions: {
                      responsive: true,
                    },
                  },
                  file: {
                    attributes: {
                      poster: useImageSources(workItem.video_image)?.src,
                    },
                  },
                }}
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        <div className="selected-works__col-text">
          <div className="selected-works__col-text--client">
            {workItem.client_name ? <p>{workItem.client_name}</p> : ''}
            {workItem.work_link?.url ? (
              <Link to={workItem.work_link?.url}>
                View project
                <CollectiveArrow />
              </Link>
            ) : (
              ''
            )}
          </div>
          <div className="selected-works__col-text--name">
            {workItem.title ? <p>{workItem.title}</p> : ''}
          </div>
        </div>
      </div>
      {(cookiesAccepted || !checkVideoUrl(workItem.video_link?.url)) &&
      workItem.video_link?.url ? (
        <VideoPopup
          video={workItem.video_link?.url}
          isOpen={popupOpen}
          setIsOpen={setPopupOpen}
          popupid={index}
        />
      ) : (
        ''
      )}
    </>
  )
}

export const SelectedWorks = ({ slice }: SliceComponentProps<any, any>) => {
  let once = true

  const isMotionAllowed = useMotionAllowed()

  useEffect(() => {
    if (!once) {
      return
    }

    gsap.registerPlugin(ScrollTrigger)
    gsap.to('.parallax-item-0', {
      scrollTrigger: {
        trigger: '.selected-works',
        scrub: true,
        start: 'top center',
        end: 'max',
      },
      duration: 4,
      y: -50,
    })

    gsap.to('.parallax-item-1', {
      scrollTrigger: {
        trigger: '.selected-works',
        scrub: true,
        start: 'top center',
        end: 'max',
      },
      duration: 4,
      y: -200,
    })
  }, [once, isMotionAllowed])

  let count = 1
  return (
    <section className="selected-works" data-header-modifier={'dark'}>
      <div className="selected-works__container container">
        <div className={`selected-works__inner`}>
          <div className="selected-works__header">
            <h2 className="selected-works__title">
              Selected <span>Works</span>
            </h2>
          </div>
          {slice.items &&
            slice.items.map((workItem, index) => {
              if (count === 0) {
                count = 1
              } else {
                count = 0
              }

              return (
                <SelectedWorksItem
                  workItem={workItem}
                  index={index}
                  count={count}
                />
              )
            })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CollectiveDetailPageDataBodySelectedWorks on PrismicCollectiveDetailPageDataBodySelectedWorks {
    items {
      teaser_video_link {
        ...Link
      }
      video_link {
        ...Link
      }
      title
      client_name
      work_link {
        ...Link
      }
      video_image {
        ...Image
      }
    }
  }
`
