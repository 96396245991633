import { graphql, PageProps } from 'gatsby'
import React from 'react'
import HomepageType from '../types/pages/homepage'
import { Page, Seo } from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices'
import { StructuredData } from '@superrb/gatsby-addons-prismic/components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const Index = ({
  data,
  pageContext,
}: PageProps<{ prismicHomepage: HomepageType }, PageStub>) => {
  /** @type {Homepage} page */
  const page = data.prismicHomepage
  if (!page) {
    return null
  }

  const {
    data: { page_title },
  } = page

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        {page_title && <h1>{page_title}</h1>}
        <SliceZone
          slices={page.data.body}
          components={components}
          context={pageContext}
        />
        <StructuredData.Organisation />
      </Page>
    </>
  )
}

export const query = graphql`
  query HomepageQuery($id: String) {
    prismicHomepage(id: { eq: $id }) {
      ...Homepage
    }
  }
`

export default withPrismicPreview(Index)
