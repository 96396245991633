import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import { SiteConfig } from '@superrb/gatsby-addons-prismic/types'
import MainFooter from '../types/main-footer'
import { CookieBanner } from './cookie-banner'
import { TickerText } from './ticker-text'
import Instagram from './icons/instagram'
import Twitter from './icons/twitter'
import Linkedin from './icons/linkedin'
import Spotify from './icons/spotify'
import Newsletter from './newsletter'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query MainFooterQuery {
      footer: prismicMainFooter {
        ...MainFooter
      }
      config: prismicSiteConfig {
        ...SiteConfig
      }
    }
  `)

  /** @type {MainFooter} footer */
  const footer: MainFooter = data.footer

  /** @type {SiteConfig} siteConfig */
  const siteConfig: SiteConfig = data?.config?.data

  if (!footer || !siteConfig) {
    return null
  }

  // useEffect(() => {
  //   let height = document
  //     .querySelector('.footer__fixed')
  //     .getBoundingClientRect().height
  //   document.querySelector('body').style.paddingBottom = height + 'px'
  // }, [])

  const link = {
    url: 'https://www.instagram.com/the.mayda.creative.co/',
    target: '_blank'
  }

  return (
    <>
      <TickerText text={'GET INSPIRED @ THE.MAYDA.CREATIVE.CO'} link={link} />
      <footer className={`footer`}>
        <div className="footer__inner footer__fixed">
          <div className="footer__info">
            <div className="footer__back-to-top">
              <Link to={'#content'}>Back to top</Link>
            </div>
            <div className="footer__social-icons">
              <ul>
                {siteConfig.twitter_username && (
                  <li>
                    <a
                      href={siteConfig.twitter_username}
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <Twitter />
                    </a>
                  </li>
                )}
                {siteConfig.linkedin_username && (
                  <li>
                    <a
                      href={siteConfig.linkedin_username}
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <Linkedin />
                    </a>
                  </li>
                )}
                {siteConfig.instagram_username && (
                  <li>
                    <a
                      href={siteConfig.instagram_username}
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <Instagram />
                    </a>
                  </li>
                )}
                {siteConfig.spotify_username && (
                  <li>
                    <a
                      href={siteConfig.spotify_username}
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <Spotify />
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className="footer__info-text">
              <p className="footer__info-text--desktop">
                <strong>Sign up for the latest work, news and insights:</strong>
              </p>
              <Newsletter />
            </div>
            <div className="footer__animation"></div>
          </div>

          <div className="footer__bottom">
            {siteConfig.company_name && (
              <span className="footer__copyright footer__legal-nav-link--small">
                &copy; {siteConfig.company_name}
              </span>
            )}
            <nav className="footer__legal-nav">
              <ul className="footer__legal-nav-list">
                {data.footer.data.legal_navigation_items.map((item, index) => (
                  <li className="footer__legal-nav-item" key={index}>
                    <Link
                      to={linkResolver(item.link)}
                      className="footer__legal-nav-link footer__legal-nav-link--small"
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
                <li className="footer__legal-nav-item">
                  <Link
                    to={'https://www.superrb.com'}
                    className="footer__legal-nav-link footer__legal-nav-link--small"
                    rel={'nofollow noopener'}
                    target={'_blank'}
                    title={'Website by Superrb'}
                  >
                    Website by Superrb
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </footer>
      <CookieBanner />
    </>
  )
}

export default Footer
