import { TranslationContext } from '@superrb/gatsby-addons/context'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import ButtonRounded from '../components/buttons/button-rounded'
import { linkResolver } from '../utils/linkResolver'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'

interface props {
  title: string
  text: string
}

export const CenterCta = ({ title, text }: Props) => {
  const { language } = useContext(TranslationContext)
  const { isInViewport, setRef } = useIsInViewport(false)

  return (
    <section className="center-cta" data-header-modifier={'dark'}>
      <div className="center-cta__container container">
        <div className="center-cta__inner" ref={setRef}>
          <div
            className={`center-cta__col animate-on-scroll ${
              isInViewport ? 'animated' : ''
            }`}
          >
            {title ? <h2 className="center-cta__title">{title}</h2> : ''}
            {text ? <p>{text}</p> : ''}
            <ButtonRounded
              text={'Get in touch'}
              text_a11y={'Go to the contact page'}
              link={linkResolver({
                type: 'contact_page',
                uid: 'contact-us',
                lang: language,
              })}
              style={'white'}
              arrow={true}
              type={'button'}
              styles={[]}
              className={''}
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
