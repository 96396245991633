import React, { useCallback, useContext, useEffect, useState } from 'react'
import TickerIcon from '../components/icons/ticker-icon'
import { Link } from 'gatsby'

interface props {
  text: string
  link: string
}

export const TickerText = ({ text, link }: Props) => {
  if (!text) {
    return <></>
  }

  return (
    <section
      className={`ticker ${link ? 'ticker--hover' : ''}`}
      aria-label="Ticker"
    >
      <div className="ticker__container container">
        <div className="ticker__inner">
          <div className="ticker__line">
            {link ? (
              <Link
                to={link.url}
                aria-label={text}
                target={link.target}
                className="ticker__link"
              />
            ) : (
              ''
            )}
            <span className="ticker__text">
              {text}
              <TickerIcon />
              <span className="ticker__duplicate" data-name={text}>
                <TickerIcon />
              </span>
              <span
                className="ticker__duplicate ticker__duplicate--2"
                data-name={text}
              >
                <TickerIcon />
              </span>
              <span
                className="ticker__duplicate ticker__duplicate--3"
                data-name={text}
              >
                <TickerIcon />
              </span>
              <span
                className="ticker__duplicate ticker__duplicate--4"
                data-name={text}
              >
                <TickerIcon />
              </span>
              <span
                className="ticker__duplicate ticker__duplicate--5"
                data-name={text}
              >
                <TickerIcon />
              </span>
            </span>
          </div>
        </div>
      </div>
    </section>
  )
}
