import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { TickerText } from '../components/ticker-text'

export const Ticker = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    text: slice.primary.text,
    link: slice.primary.link,
  }

  return (
    <section className="ticker-slice">
      <TickerText text={data.text} link={data.link} />
    </section>
  )
}

export const query = graphql`
  fragment WorkDetailPageDataBodyTicker on PrismicWorkDetailPageDataBodyTicker {
    primary {
      text
      link {
        ...Link
      }
    }
  }
`
