import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import {useIsMobile, useMotionAllowed} from '@superrb/gatsby-addons/hooks'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { CookiesContext } from '../context/cookies-context'
import { Image } from '@superrb/gatsby-addons/components'
import checkVideoUrl from '../utils/checkVideoUrl'
import { ScrollIndicator } from '../components/scroll-indicator'
import useImageSources from '@superrb/gatsby-addons/src/hooks/use-image-sources'

export const CareersHeader = ({ slice }: SliceComponentProps<any, any>) => {
  const data = {
    title: slice.primary.title,
    sub_text: slice.primary.sub_text,
    mobile_image: slice.primary.mobile_fallback_image,
    desktop_image: slice.primary.desktop_fallback_image,
    video: slice.primary.desktop_video.url,
    mobile_video: slice.primary.mobile_video.url,
  }

  const { cookiesAccepted } = useContext(CookiesContext)
  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const [videoMobileIsEmbedCode, setVideoMobileIsEmbedCode] = useState(true)

  const isMobile = useIsMobile(true)
  const isMotionAllowed = useMotionAllowed()

  useEffect(() => {
    setVideoIsEmbedCode(checkVideoUrl(data.video))
    setVideoMobileIsEmbedCode(checkVideoUrl(data.mobile_video))
  }, [data.video, data.mobile_video])

  const { src: imageSrc } = useImageSources(
    isMobile ? data.mobile_image : data.desktop_image,
  )

  return (
    <section className={`careers-header`} aria-label="Video Header" data-header-modifier={'transparent'}>
      <div className="careers-header__container container">
        <div className="careers-header__video">
          {(cookiesAccepted ||
            (!videoIsEmbedCode && !videoMobileIsEmbedCode)) &&
          data.video &&
          data.mobile_video ? (
            <ReactPlayer
              url={isMobile ? data.mobile_video : data.video}
              muted={true}
              playsinline={isMotionAllowed}
              playing={isMotionAllowed}
              autoPlay={true}
              loop={true}
              className="careers-header__player"
              light={false}
              config={{
                vimeo: {
                  playerOptions: {
                    responsive: true,
                  },
                },
                file: {
                  attributes: {
                    poster: imageSrc,
                  },
                },
              }}
            />
          ) : (
            <Image
              image={isMobile ? data.mobile_image : data.desktop_image}
              className={'careers-header__placeholder'}
            />
          )}
        </div>
        <div className="careers-header__inner">
          <div className="careers-header__text">
            {data.title ? <h1>{data.title}</h1> : ''}
            {data.sub_text ? (
              <p className="careers-header__sub-text">{data.sub_text}</p>
            ) : (
              ''
            )}
          </div>
          <div className="careers-header__scroll">
            <ScrollIndicator />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment CareersPageDataBodyCareersHeader on PrismicCareersPageDataBodyCareersHeader {
    primary {
      title
      sub_text
      desktop_video {
        ...Link
      }
      mobile_video {
        ...Link
      }
      desktop_fallback_image {
        ...Image
      }
      mobile_fallback_image {
        ...Image
      }
    }
  }

  fragment StudioPageDataBodyCareersHeader on PrismicStudioPageDataBodyCareersHeader {
    primary {
      title
      sub_text
      desktop_video {
        ...Link
      }
      mobile_video {
        ...Link
      }
      desktop_fallback_image {
        ...Image
      }
      mobile_fallback_image {
        ...Image
      }
    }
  }
`
