import {Image, Page, Seo} from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import BlogDetailPageType from '../types/pages/blog-detail-page'
import { components } from '../slices'
import { SliceZone } from '@prismicio/react'
import { FeaturedArticlesSection } from '../components/featured-articles'
import useArticleFilter from '../hooks/use-article-filter'
import { useIsInViewport } from '@superrb/gatsby-addons/hooks'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { ScrollIndicator } from '../components/scroll-indicator'

const BlogDetailPage = ({
  data,
  pageContext,
}: PageProps<{ prismicBlogDetailPage: BlogDetailPageType }, PageStub>) => {
  const page = data?.page || data?.prismicBlogDetailPage
  if (!page) {
    return null
  }

  const {
    data: { page_title, listing_article_type, date, listing_image },
  } = page

  const blogDetailPages = data.blogDetailPages.nodes
  const filteredArticles = useArticleFilter(blogDetailPages, page.tags)
  const { isInViewport, setRef } = useIsInViewport(false)

  const dateFormat = (date) => {
    let newDate = date.split('-')
    return newDate[2] + '.' + newDate[1] + '.' + newDate[0]
  }

  return (
    <>
      <Page page={page}>
        <Seo data={page.data} />
        <section className="blog-detail-header light-theme" data-header-modifier={'light'}>
          <div className="blog-detail-header__container container">
            <div className="blog-detail-header__inner" ref={setRef}>
              <div
                className={`blog-detail-header__col blog-detail-header__col--text animate-on-scroll ${
                  isInViewport ? 'animated' : ''
                }`}
              >
                {page_title && (
                  <div className="blog-detail-header__title">
                    <h1>{page_title}</h1>
                    <div className="blog-detail-header__scroll">
                      <ScrollIndicator />
                    </div>
                  </div>
                )}
                <div className="blog-detail-header__footer">
                  {date ? (
                    <p className="news-article__item--date">
                      {dateFormat(date)}
                    </p>
                  ) : (
                    ''
                  )}
                  {date && listing_article_type ? (
                    <svg
                      width="7"
                      height="7"
                      viewBox="0 0 7 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.48528 0C3.48528 1.88259 5.04447 3.4181 7 3.50058C5.05798 3.59708 3.51472 5.12687 3.51472 7C3.51472 5.11741 1.95554 3.5819 0 3.49942C1.94201 3.40321 3.48528 1.87314 3.48528 0Z"
                        fill="#000"
                      />
                    </svg>
                  ) : (
                    ''
                  )}
                  {listing_article_type ? (
                    <p className="news-article__item--tag">
                      {listing_article_type}
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div
                className={`blog-detail-header__col blog-detail-header__col--image animate-on-scroll ${
                  isInViewport ? 'animated' : ''
                }`}
              >
                {listing_image && (
                  <Image
                    image={listing_image}
                    className={'news-article__image'}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="article-copy" data-header-modifier={'light'}>
          <SliceZone
            slices={page.data.body}
            components={components}
            context={pageContext}
          />
        </section>
        <FeaturedArticlesSection
          items={filteredArticles}
          article_tags={page.tags}
          exclude={page.id}
        />
      </Page>
    </>
  )
}

export const query = graphql`
  query BlogDetailPageQuery($id: String) {
    prismicBlogDetailPage(id: { eq: $id }) {
      ...BlogDetailPage
    }
    blogDetailPages: allPrismicBlogDetailPage(sort: { data: { date: DESC } }) {
      nodes {
        ...BlogDetailPage
      }
    }
    blogDetailPagesTags: allPrismicBlogDetailPage {
      nodes {
        tags
      }
    }
  }
`

export default withPrismicPreview(BlogDetailPage)
