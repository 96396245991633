import React, { useEffect } from 'react'
import { gsap } from 'gsap'

export const ScrollIndicator = ({}) => {
  useEffect(() => {}, [])
  return (
    <div className="scroll-indicator">
      <div className="scroll-indicator__text">
        <span>
          Scroll
          <br /> to explore
        </span>
      </div>
      <div className="scroll-indicator__line"></div>
    </div>
  )
}
