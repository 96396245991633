import { useContext, useEffect, useState } from 'react'
import { FilterContext } from '../context/filter-context-provider'
import WorkDetailPageType from '../types/pages/work-detail-page'

type Article = WorkDetailPageType

const extractKey = (article: Article, sortField: string) => {
  let value

  if (sortField in article) {
    value = article[sortField]
  }

  if (sortField in article.data) {
    value = article.data[sortField]
  }

  if (value === undefined) {
    throw new Error(`Unknown sort field "${sortField}"`)
  }

  try {
    return new Date(value).setHours(0, 0, 0, 0)
  } catch {
    throw new Error(`Sort value ${value} is not a valid date`)
  }
}

const useArticleFilter = (articles: Article[], availableTags: []) => {
  const {
    selectedTags = [],
    setAvailableTags,
    isFiltered,
    setIsFiltered,
  } = useContext(FilterContext)
  // const [isFiltered, setIsFiltered] = useState(false)

  useEffect(() => {
    setAvailableTags(availableTags)
  }, [availableTags])

  const [filteredArticles, setFilteredArticles] = useState<Article[]>([])

  //Filter the results based of current tags
  useEffect(() => {
    const filtered = articles.filter((article) => {
      if (selectedTags.length === 0) {
        setIsFiltered(false)
        return true
      } else {
        setIsFiltered(true)
      }

      return (
        article.tags.filter((tag) => selectedTags.includes(tag)).length ===
        selectedTags.length
      )
    })

    setFilteredArticles(filtered)
  }, [selectedTags, setFilteredArticles, setAvailableTags, articles])

  if (isFiltered) {
    return filteredArticles
  } else {
    return articles
  }
}

export default useArticleFilter
