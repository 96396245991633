import useLockBodyScroll from '@superrb/gatsby-addons/src/hooks/use-lock-body-scroll'
import React, {
    createContext,
    PropsWithChildren,
    ReactNode,
    useCallback,
    useState,
} from 'react'

export const CollectiveDropdownContext = createContext({
    collectiveDropdownOpen: false,
    toggleCollectiveDropdown: () => {},
    openCollectiveDropdown: () => {},
    closeCollectiveDropdown: () => {},
})

export const CollectiveDropdownContextProvider = ({
                                       children,
                                   }: PropsWithChildren<ReactNode>) => {
    const [collectiveDropdownOpen, setCollectiveDropdownOpen] = useState<boolean>(false)
    useLockBodyScroll(collectiveDropdownOpen)

    const toggleCollectiveDropdown = useCallback(() => {
        setCollectiveDropdownOpen(collectiveDropdownOpen => !collectiveDropdownOpen)
    }, [setCollectiveDropdownOpen])

    const openCollectiveDropdown = useCallback(() => {
        setCollectiveDropdownOpen(true)
    }, [setCollectiveDropdownOpen])

    const closeCollectiveDropdown = useCallback(() => {
        setCollectiveDropdownOpen(false)
        document.activeElement?.blur()
    }, [setCollectiveDropdownOpen])

    return (
        <CollectiveDropdownContext.Provider value={{ collectiveDropdownOpen, toggleCollectiveDropdown, openCollectiveDropdown, closeCollectiveDropdown }}>
            {children}
        </CollectiveDropdownContext.Provider>
    )
}

export default CollectiveDropdownContextProvider
