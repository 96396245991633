import React, { useCallback, useContext, useEffect, useState } from 'react'
import { SliceComponentProps } from '@prismicio/react'
import { graphql } from 'gatsby'
import { RichText, Image } from '@superrb/gatsby-addons/components'
import ButtonRounded from '../components/buttons/button-rounded'
import { useIsMobile, useIsInViewport, useMotionAllowed } from '@superrb/gatsby-addons/hooks'
import { gsap } from 'gsap'
import { LiveNodeList } from 'live-node-list'
import { CookiesContext } from '../context/cookies-context'
import checkVideoUrl from '../utils/checkVideoUrl'
import ReactPlayer from 'react-player/lazy'

export const AboutUsOverview = ({ slice }: SliceComponentProps<any, any>) => {
  const { cookiesAccepted } = useContext(CookiesContext)
  const isMotionAllowed = useMotionAllowed()
  const isMobile = useIsMobile(true, '63.75em')
  const [videoIsEmbedCode, setVideoIsEmbedCode] = useState(true)
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [videoThumbnail, setVideoThumbnail] = useState(true)

  const data = {
    text: slice.primary.text,
    link: slice.primary.link,
    image: slice.primary.image,
    video: slice.primary.video
  }

  const { isInViewport, setRef } = useIsInViewport(false)

  useEffect(() => {
    if (!isMotionAllowed) {
      setVideoPlaying(false)
    }
    setVideoIsEmbedCode(checkVideoUrl(data.video?.url))

    if (
      !document.querySelector(
        '.about-us-overview .title-block__word .title-block__letter',
      )
    ) {
      const words = new LiveNodeList('.about-us-overview .title-block__word')
      words.items.forEach(function (item) {
        let wordBin = item.innerText.split(' ')
        item.innerHTML = ''
        for (var i = 0; i < wordBin.length; i++) {
          item.innerHTML += '<span class="title-block__word-wrap">' + wordBin[i] + '</span>'
        }

        const words = item.querySelectorAll('.title-block__word-wrap')
        for (var x = 0; x < words.length; x++) {
          const letters = words[x].innerText.split('')
          words[x].innerHTML = ''
          for (var y = 0; y < letters.length; y++) {
            words[x].innerHTML += '<span class="title-block__letter">' + letters[y] + '</span>'
          }
        }
      })
    }

    function titleAnimate(first) {
      const active = document.querySelector(
        '.about-us-overview .title-block__word--active',
      )

      if (!active) {
        return
      }

      if (!first) {
        if (active.nextSibling) {
          active.nextSibling.classList.add('title-block__word--active')
          active.classList.remove('title-block__word--active')
        } else {
          document
            .querySelector('.about-us-overview .title-block__word')
            .classList.add('title-block__word--active')
          active.classList.remove('title-block__word--active')
        }
      }

      let timeline = gsap.timeline({ repeat: 0, delay: 0, repeatDelay: 0 })
      gsap.ticker.lagSmoothing(0)
      timeline.to(
        '.about-us-overview .title-block__word--active .title-block__letter',
        {
          y: '0%',
          duration: 1,
          delay: 0,
          stagger: 0.02,
          ease: 'expo',
        },
      )
      timeline.to(
        '.about-us-overview .title-block__word--active .title-block__letter',
        {
          y: '200%',
          duration: 1,
          delay: 1.5,
          stagger: 0.02,
          ease: 'expo',
        },
      )
      timeline.to(
        '.about-us-overview .title-block__word--active .title-block__letter',
        {
          y: '-200%',
          duration: 0,
          delay: 0,
        },
      )
    }
    titleAnimate(true)

    const aboutUsOverviewInterval = setInterval(function () {
      titleAnimate(false)
    }, 3000)

    return () => {
      clearInterval(aboutUsOverviewInterval)
    }
  }, [isMobile])

  return (
    <section
      className={`about-us-overview light-theme`}
      aria-label="About Us Overview"
      data-header-modifier={'light'}
    >
      <div className="about-us-overview__container container">
        <div className="about-us-overview__inner" ref={setRef}>
          <div className="about-us-overview__heading">
            <h1 className="about-us-overview__title">
              Navigating
              <span className="title-block__word-wrap">
                <span className="title-block__placeholder">
                  ENTERTAINMENT&nbsp;& PARTNERSHIPS
                </span>
                <span className="title-block__word title-block__word--active">
                  Future Stories
                </span>
                <span className="title-block__word">Branded Content</span>
                <span className="title-block__word">Creative Technology</span>
                <span className="title-block__word">
                  Entertainment&nbsp;& IP
                </span>
                <span className="title-block__word">Creative Partnerships</span>
                <span className="title-block__word">Cultural Impact</span>
                <span className="title-block__word">Immersive Experiences</span>
                <span className="title-block__word">Metaverse&nbsp;& Web3</span>
              </span>
            </h1>
          </div>
          <div className="about-us-overview__text">
            {data.text ? (
              <div
                className={`about-us-overview__text--richtext animate-on-scroll ${
                  isInViewport ? 'animated' : ''
                }`}
              >
                <RichText field={data.text.richText} />
              </div>
            ) : (
              ''
            )}
            {data.link.url ? (
              <ButtonRounded
                text="Learn More"
                text_a11y={'Go to the about us page'}
                link={data.link.url}
                style={'black'}
                arrow={true}
                type={'button'}
                styles={[]}
                className={`animate-on-scroll ${
                  isInViewport ? 'animated' : ''
                }`}
                onClick={() => {}}
              />
            ) : (
              ''
            )}
          </div>
          <div className="about-us-overview__image">
            <div className="about-us-overview__image--picture">
              {!isMobile && data.image ? (
                <Image
                  image={data.image}
                  className={`animate-on-scroll ${
                    isInViewport ? 'animated' : ''
                  } ${data.video?.url ? 'about-us-overview__thumbnail' : ''}`}
                  aria-hidden={!videoThumbnail}
                />
              ) : (
                ''
              )}
              {!isMobile && data.video?.url && (cookiesAccepted || !videoIsEmbedCode || !videoMobileIsEmbedCode) ? (
                <ReactPlayer
                  url={data.video.url}
                  muted={true}
                  playsinline={true}
                  playing={videoPlaying}
                  autoPlay={true}
                  loop={true}
                  className="about-us-overview__player"
                  light={false}
                  width={'100%'}
                  onPlay={() => {
                    setVideoThumbnail(false)
                  }}
                />
              ) : (
                ''
              )}

            </div>
            {/*}
            Old about us logo swiper.
            {slice.items ? (
              <div className={'about-us-overview__swiper'}>
                <div className={'about-us-overview__swiper-inner'}>
                  {slice.items.map((logo, index) => (
                    <div
                      className="about-us-overview__item"
                      key={`about-us-logo-${index}`}
                    >
                      <Image
                        image={logo.logo}
                        className={'about-us-overview__logo '}
                      />
                    </div>
                  ))}
                </div>
                <div className={'about-us-overview__swiper-inner'}>
                  {slice.items.map((logo, index) => (
                    <div
                      className="about-us-overview__item"
                      key={`about-us-logo2-${index}`}
                    >
                      <Image
                        image={logo.logo}
                        className={'about-us-overview__logo '}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
            {*/}
          </div>
        </div>
      </div>
    </section>
  )
}
export const query = graphql`
  fragment HomepageDataBodyAboutUsOverview on PrismicHomepageDataBodyAboutUsOverview {
    primary {
      text {
        ...RichText
      }
      link {
        ...Link
      }
      image {
        ...Image
      }
      video {
        ...Link
      }
    }
    items {
      logo {
        ...Image
      }
    }
  }
`
